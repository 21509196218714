import { DemoSection } from "system/demo.js";
import { SliderWithStopPoints } from "../system/sliderwithstoppoints";
import { PollPicker, PollResult } from "../component/poll";
import { BarChartVisualization, HorizBarVisualization, Selector } from "../feature/question/CommentSliderFeature";
import { ColoredBallPill } from "../component/colorpill";
import { useState } from "react";
import { Datastore } from "util/datastore.js";
import { RatingSelector } from "../component/ratingselector";
import { StructureDemo } from "util/instance.js";
import { CLICK } from "system/demo.js";
import { View } from "react-native";

const config = {
     commentSliderLabels: ['Strongly no', 'No with reservations',
        "It's complicated", "Yes with reservations",
        "Strongly yes"],
    commentAllowEmpty: true,
    commentSliderVisualization: BarChartVisualization
}

export function CommentSliderComponentsDemo() {
    const [value, setValue] = useState(null)
    const [comment, setComment] = useState({text: '', slider: null});
    const optionLabels = ['Strongly no', 'No with reservations',
        "It's complicated", "Yes with reservations",
        "Strongly yes"]
    const optionColors = ['red', 'orange', 'yellow', 'green', 'blue'];
    const ratingCounts = {
        1: 5,
        2: 3,
        3: 7,
        4: 2,
        5: 8
    }
    const commentsWithSlider = [
        {key: 1, from: 'a', text: 'I love this movie!', slider: 5},
        {key: 2, from: 'b', text: 'It sucks', slider: 1},
        {key: 3, from: 'c', text: 'The cinematics are awesome, but no plot', slider: 3},
        {key: 4, from: 'd', text: 'How did this get made', slider: 1},
    ]
 
    return <View>
        <DemoSection text='Slider With Stops'>
            <SliderWithStopPoints title='No Default' 
                numberOfStops={5}
                value={value}
                onChangeValue={setValue}
                optionColors={optionColors}
                optionLabels={optionLabels} />
            <SliderWithStopPoints title='Translucent Default' 
                numberOfStops={5}
                value={value ?? 3}
                isDefault={!value}
                onChangeValue={setValue}
                optionColors={optionColors}
                optionLabels={optionLabels} />
            <SliderWithStopPoints title='Default to Center' 
                numberOfStops={5}
                value={value ?? 3}
                onChangeValue={setValue}
                optionColors={optionColors}
                optionLabels={optionLabels} />
        </DemoSection>
        <DemoSection text='Poll Picker'>
            <PollPicker value={value} optionLabels={optionLabels} 
                optionColors={optionColors} placeholder='Are cats cute?' 
                onChangeValue={setValue} />
        </DemoSection>
        <DemoSection text='Selector'>
            <Datastore config={{
                    commentSliderLabels: ['Strongly no', 'No with reservations', "It's complicated", "Yes with reservations", "Strongly yes"],
                    commentSliderVisualization: BarChartVisualization,
                    commentSliderPrompt: 'Slide to respond:',
                    commentSliderSelector: RatingSelector}} >
                <Selector comment={comment} setComment={setComment} />
            </Datastore>
        </DemoSection>
        <DemoSection text='Bar Chart Visualization'>
            <Datastore config={config}>
                <BarChartVisualization ratingCounts={ratingCounts} maxCount={8} responseCount={20} voteCount={5} />
            </Datastore>
        </DemoSection>
        <DemoSection text='Horiz Bar Visualization'>
            <Datastore config={config}>
                <HorizBarVisualization commentsWithSlider={commentsWithSlider} />
            </Datastore>
        </DemoSection>
        <DemoSection text='Poll Result'>
            <PollResult optionLabels={optionLabels} myVote='Strongly No' 
                votes={["It's complicated", 'Strongly yes', 'Strongly yes', 'No with reservations']} />
        </DemoSection>
        <DemoSection text='Colored Ball Pill'>
            <ColoredBallPill text='Yes' color='green' />
            <ColoredBallPill text='No' color='red' />
            <ColoredBallPill text='Maybe' color='yellow' />
        </DemoSection>
    </View>
}

const comments = [
    {key: 1, from: 'a', slider: 1, text: 'Godzilla sucks'},
    {key: 2, from: 'b', slider: 5, text: 'Godilla rules'},
    {key: 3, from: 'c', slider: 3, text: 'Godzilla does not exist'},
]

const globals = {
    name: 'Is Godzilla Good?',
    questionAuthor: 'a',
    questionTime: Date.now(),
}

function PollPickerDemo() {
    const [value, setValue] = useState(3)
    return <PollPicker value={value} optionLabels={['Strongly no', 'No with reservations', "It's complicated", "Yes with reservations", "Strongly yes"]} optionColors={['red', 'orange', 'yellow', 'green', 'blue']} placeholder='Are cats cute?' onChangeValue={setValue} />
}

export function commentSliderStorySets() {return [
    {
        label: 'Poll Picker',
        content: <PollPickerDemo />,
        stories: [
            {
                label: 'Strongly no', actions: [
                    CLICK('Change'),
                    CLICK('Strongly no')
                ]
            }
        ]
    }
]}

export function CommentSliderFullDemo() {
    const collections = {comment: comments}
    return <StructureDemo testState={{globals, collections}} structureKey='question' 
    features={{
        commentslider: true,
        reactiontypes: false,
        commentslider_enable_visualization: true,
        commentslider_horiz_visualization: true,
    }} />
}