import { getDeviceInfo } from "../platform-specific/deviceinfo";
import { useDatastore } from "./datastore";
import { firebaseNewKey, getFirebaseUser, onFbUserChanged } from "./firebase";
import { callServerApiAsync } from "system/servercall";
import { useEffect, useState } from "react";

const hourMillis = 60*60*1000;


onFbUserChanged((userId) => {
    if (userId) {
        console.log('User changed', userId.uid);
        setSessionUserAsync(userId.uid);
    }
});

window.addEventListener('error', event => {
    if (global_in_error_handler) {
        return; // avoid infinite error loop if this fails
    }
    global_in_error_handler = true;
    const error = event.error;
    console.log('Caught an error', {error});
    logEventAsync(null, 'error', {message: error.message, stack: error.stack});
    global_in_error_handler = false;
})

window.addEventListener('unhandledrejection', async event => {
    if (global_in_error_handler) {
        return; // avoid infinite error loop if this fails
    }
    global_in_error_handler = true;
    const error = event.reason;
    console.log('Caught an unhandled promise rejection', {error});
    await logEventAsync(null, 'error', {message: error.message, stack: error.stack});
    global_in_error_handler = false;
})

var global_last_event = null;
var global_in_error_handler = false;

export async function setSessionUserAsync(userId, datastore) {
    if (globalSessionKey && userId) {
        await callServerApiAsync({component: 'eventlog', funcname: 'setSessionUser', params: {
            sessionKey: globalSessionKey, userId, eventKey: global_last_event
        }});
    }
}

let globalSessionKey = null;
let globalSessionTime = null;
let globalDeviceInfo = null;

export async function logEventAsync(datastore, eventType, params) {
    let isNewSession = false;
    const preFirebaseUser = getFirebaseUser();
    const siloKey = datastore?.getSiloKey();
    
    if (!globalSessionKey || !globalSessionTime || Date.now() - globalSessionTime > hourMillis){
        globalSessionKey = firebaseNewKey();
        globalSessionTime = Date.now();
        globalDeviceInfo = getDeviceInfo();
        isNewSession = true;      
        console.log('New session', globalSessionKey, globalDeviceInfo);
    }

    // IMPORTANT: We can't use datastore.callServerAsync here because datastore will
    // be null if we are called from an unhandled promise rejection
    const result = await callServerApiAsync({component: 'eventlog', funcname: 'logEvent', params: {
        eventType, sessionKey: globalSessionKey, isNewSession, params, deviceInfo: globalDeviceInfo, siloKey
    }});
    global_last_event = result?.eventKey ?? null;

    const postFirebaseUser = getFirebaseUser();

    if (postFirebaseUser && !preFirebaseUser) {
        setSessionUserAsync(postFirebaseUser.uid);
        return;
    }

}

export async function useLogEvent(eventKey, params, skip=false) {
    const datastore = useDatastore();
    useEffect(() => {
        if (!skip) {
            logEventAsync(datastore, eventKey, params);
        }
    }, [eventKey, JSON.stringify(params), skip]);
}
