
export const spacings = {
    xxs: 4,
    xs: 8,
    sm: 12,
    md: 16,
    lg: 20,
    xl: 24,
    xl2: 32,
    xl3: 40,
    xl4: 80,
}
