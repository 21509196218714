export const DeleteCommentsInModDashboardFeature = {
    name: "Delete Comments in Mod Dashboard",
    key: "deletecommentsinmoddashboard",
    dev: true,
    config: {
        commentDeleteTriggers: [handleDeletedComment]
    }
};

async function handleDeletedComment({datastore, comment, commentKey}) {
    await datastore.callServerAsync('moderationZdf', 'handleDeletedObject', {
        type: 'comment', key: commentKey
    });
}