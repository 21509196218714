import { Narrow } from "component/basics.js"
import { CLICK, DemoSection } from "system/demo.js"
import { VideoVotingQuestionDialog } from "../../../contrib/zdf/structure/videovoting";
import { Datastore } from "util/datastore.js"
import { videoVotingDemoData } from "./videovotingdemo.util";

const question = videoVotingDemoData.templateIndividual.questions[0];
    const otherVotes = [
        {
            from: "b",
            questionKey: question.key,
            optionKey: question.options[0].key
        },
        {
            from: "c",
            questionKey: question.key,
            optionKey: question.options[0].key
        },
        {
            from: "d",
            questionKey: question.key,
            optionKey: question.options[0].key,
            anonymous: true
        }
    ]

    const myVotes = [
        {
            from: "a",
            questionKey: question.key,
            optionKey: question.options[1].key
        }
    ]

export function VideoVotingQuestionDialogDemo() {
    // TODO: How can we make the voting options interactable? Clicking an option results in an error.
    return (
        <Narrow>
            <DemoSection text="Question Dialog">
                <Datastore 
                    testState={{
                        collections: {votes: [...otherVotes]},
                        sessionData: {
                            'videoVotingSessionState': {
                                anonymous: false
                            }
                        }
                    }}
                    >
                    <VideoVotingQuestionDialog
                        question={question}
                        onFinishQuestionVoting={null}
                    />
                </Datastore>
            </DemoSection>
            <DemoSection text="Question Dialog (with secondary header)">
                <Datastore
                    testState={{
                        collections: {votes: [...otherVotes]},
                        sessionData: {
                            'videoVotingSessionState': {
                                anonymous: false
                            }
                        }                        
                    }}>
                    <VideoVotingQuestionDialog
                        question={question}
                        onFinishQuestionVoting={null}
                        headerText={"What do you think about it now?"}
                    />
                </Datastore>
            </DemoSection>
            <DemoSection text="Question Dialog (already answered and with secondary header)">
                <Datastore
                    testState={{
                        collections: {votes: [...otherVotes, ...myVotes]},
                        sessionData: {
                            videoVotingSessionState: {
                                anonymous: false
                            }
                        }
                    }}>
                    <VideoVotingQuestionDialog
                        question={question}
                        onFinishQuestionVoting={null}
                        headerText={"What do you think about it now?"}
                    />
                </Datastore>
            </DemoSection>
        </Narrow>
    );
}



export function videovotingQuestionDialogStorySets() {
    return [
        {
            label: "Voting Actions",
            collections: { votes: [...otherVotes] },
            sessionData: {
                videoVotingSessionState: {
                    anonymous: false,
                },
            },
            content: (
                <VideoVotingQuestionDialog
                    question={question}
                    onFinishQuestionVoting={null}
                    sessionData={{
                        videoVotingSessionState: {
                            anonymous: false,
                        },
                    }}
                />
            ),
            stories: [
                { label: "Vote for option", actions: [CLICK(question.options[1].text)] },
                {
                    label: "Vote for option and change your mind",
                    actions: [CLICK(question.options[1].text), CLICK(question.options[0].text)],
                },
                {
                    label: "Vote for option and remove your vote",
                    actions: [CLICK(question.options[1].text), CLICK(question.options[1].text)],
                },
            ],
        },
    ];
}
