export const ui_machine_translations_french = {
    // NP Platform
    'Member': 'Membre',
    'Guest': 'Invité',
    '{count} {noun}': '{count} {noun}',
    '{count} {noun} {isAre} here': '{count} {noun} {isAre} ici',
    'Hello {name}': 'Bonjour {name}',
    'comment': 'commentaire',
    'comments': 'commentaires',
    'person': 'personne',
    'people': 'personnes',
    'is': 'est',
    'are': 'sont',
    'Like': "J'aime",
    'Unlike': "Je n'aime pas",
    'Reply': 'Réponse',
    'Collapse': 'Réduire',
    'No comments yet. Start the conversation!': 'Aucun commentaire pour l\'instant. Lancez la conversation!',
    'Share your thoughts': 'Exprimez-vous',
    'Join the conversation by submitting a comment. Be sure to follow our [community guidelines](https://example.com).': 'Rejoignez la conversation en soumettant un commentaire. Assurez-vous de suivre nos [règles de la communauté](https://example.com).',
    'Log in': 'Se connecter',
    'Comments': 'Commentaires',
    'Share your thoughts...': 'Exprimez-vous...',
    'Continue with Google': 'Continuer avec Google',
    'Log in to comment': 'Connectez-vous pour commenter',
    'Public Comment': 'Commentaire public',
    'Upvote': 'Vote positif',
    'Edit': 'Modifier',
    'Update': 'Mettre à jour',
    'Cancel': 'Annuler',
    '{time}': '{time}',
    '{time} • Edited': '{time} • Modifié',
    ' • {time}': ' • {time}',
    ' • {time} • Edited': ' • {time} • Modifié',
    '{minutes} minutes ago': 'Il y a {minutes} minutes',
    '1 minute ago': 'Il y a 1 minute',
    '{hours} hours ago': 'Il y a {hours} heures',
    '1 hour ago': 'Il y a 1 heure',
    'Just now': 'À l\'instant',
    '1 day ago': 'Il y a 1 jour',
    '{days} days ago': 'Il y a {days} jours',
    'now': 'maintenant',
    '{minutes}m': '{minutes}m',
    '{hours}h': '{hours}h',
    '{days}d': '{days}j',
    '{years}y': '{years}a',
    'Reply to {authorName}...': 'Répondre à {authorName}...',
    'reply': 'réponse',
    'replies': 'réponses',
    'reaction': 'réaction',
    'reactions': 'réactions',
    'Profile': 'Profil',
    'Account': 'Compte',
    'Log out': 'Se déconnecter',
    'Read more': 'Lire la suite',
    'Once you log in, you can use a different display name if you prefer to not reveal your real name': 'Une fois que vous vous connectez, vous pouvez utiliser un autre nom d\'affichage si vous le souhaitez',
    'Set your visibility after log in': 'Sélectionnez votre visibilité',
    "Once you log in, enter a display name": "Une fois connecté(e), saisissez un nom d'affichage.",
    "Add your display name": "Ajouter votre nom d'affichage",

    // Help
    'Start the conversation': 'Lancez la conversation',
    'Be the first to share your thoughts': 'Soyez le premier à partager vos réflexions',
    'Same question, new article?': 'Même question, nouvel article?',
    'Update your previous response with fresh insights': 'Mettez à jour votre réponse avec votre nouvelle perspective.',


    // Feature module names
    'Upvote Comments': 'Vote positif des commentaires',
    'Comment Length Limit': 'Limite de longueur des commentaires',
    'Demo Moderation': 'Modération de démonstration',
    'Basic Teaser': 'Teaser de base',
    'Demo Filter': 'Filtre de démonstration',

    // Profile
    'Profile name': 'Nom du profil',
    'Your profile photo': 'Votre photo de profil',
    'A pseudonym': 'Un pseudonyme',
    'Enter a pseudonym': 'Entrez un pseudonyme',
    'This pseudonym may violate our community guidelines.': 'Ce pseudonyme peut violer nos règles de la communauté.',
    'This pseudonym is already in use. Please choose a different one.': 'Ce pseudonyme est déjà utilisé. Veuillez en choisir un autre.',
    'Pseudonyms can contain only lower case letters and numbers.': 'Les pseudonymes ne peuvent contenir que des lettres minuscules et des chiffres.',
    'You can change your pseudonym at most once a week': 'Vous ne pouvez changer votre pseudonyme qu\'une fois par semaine au maximum',
    'Pseudonyms are not allowed to look like real names.': 'Les pseudonymes ne sont pas autorisés à ressembler à des noms réels.',
    "Let's get your profile set up": 'Créons votre profil',
    'How do you want to appear to others in your posts and replies?': 'Comment souhaitez-vous apparaître aux autres dans vos publications et réponses?',
    'Save': 'Enregistrer',
    'Edit {tLabel}': 'Modifier {tLabel}',
    'photo and name': 'photo et nom',
    'Please Wait...': 'Veuillez patienter...',
    'Continue': 'Continuer',
    'Saving...' : 'Enregistrement en cours...',
    'Finish' : 'Terminer',

    'Join the Conversation': 'Rejoignez la conversation',
    "Join the conversation": "Rejoignez la conversation",
    "Join the conversation to enter": "Rejoignez la conversation pour entrer",
    "Join the conversation to answer": "Rejoignez la conversation pour repondre",
    "Join the discussion" : "Participez à la conversation",
    'question': 'question',
    'There {isAre} {count} {noun} being discussed': 'Il y a {count} {noun} en discussion',
    '🚀 Read More': '🚀 Lire la suite',
    'Related questions': 'Questions connexes',
    'Ask a question related to this topic, or join the conversation by submitting an answer. Be sure to follow our [community guidelines](https://www.google.com).': 'Posez une question liée à ce sujet ou participez à la conversation en soumettant une réponse. Assurez-vous de suivre nos [règles de la communauté](https://www.google.com).',
    'Ask a question': 'Poser une question',
    'answer': 'réponse',
    'answers': 'réponses',
    'Your answer is posted': 'Votre réponse est publiée',
    'story': 'histoire',
    'Community Guidelines': 'Lignes directrices de la communauté',
    'Loading...': 'Chargement...',
    'Filter by opinion': 'Filtrer par opinion',
    'Posting...' : 'Publication en cours...',
    'Loading questions...': 'Chargement des questions...',
    'questions': 'questions',
    'Upvoted ({count})': 'Voté positif ({count})',
    'Log in to answer': 'Connectez-vous pour répondre',
    'No more comments': 'Pas plus de commentaires',
    'No more responses': 'Pas plus de réponses',
    "Let's talk about..." : "Participez à la conversation...",
    "Let's talk about" : "Participez à la conversation...",


    // Moderation
    '🚧 **Moderation under construction:** We are still tuning auto-moderator triggering.': '🚧 **Modération en construction:** Nous sommes toujours en train de régler le déclenchement de l\'auto-modérateur.',
    'Consider rewording your post': 'Envisagez de reformuler votre message',
    'Your post violates our [Community Guidelines](https://google.com).': 'Votre message viole nos [Règles de la communauté](https://google.com).',
    'You are free to express any opinion, however you must do so in a respectful way that avoids personal attacks, assumes good intent, and backs up contentious claims with sources.': 'Vous êtes libre d\'exprimer n\'importe quelle opinion, cependant vous devez le faire de manière respectueuse, en évitant les attaques personnelles, en supposant une bonne intention et en étayant les affirmations controversées par des sources.',
    'Sometimes our AI moderator triggers incorrectly. If that happened then first try adjusting your wording to be more obviously respectful. If you are still unable to get the moderator to accept a respectfully expressed opinion then please let us know.': 'Parfois, notre modérateur AI se déclenche de manière incorrecte. Si cela s\'est produit, essayez d\'abord d\'ajuster vos mots pour qu\'ils soient plus évidemment respectueux. Si vous êtes toujours incapable de faire accepter un avis exprimé de manière respectueuse, veuillez nous le faire savoir.',
    'To provide tranparency, we offer an anonymized [public database](https://google.com) of all moderation decisions.': 'Pour assurer la transparence, nous proposons une [base de données publique](https://google.com) anonymisée de toutes les décisions de modération.',
    'Discuss with Human Moderator': 'Discuter avec le modérateur humain',
    'Updating...': 'Mise à jour...',
    'Your response has been sent to a moderator for review': 'Votre réponse a été envoyée à un modérateur pour examen',
    'You will be notified when the moderator makes a decision. In the meantime, you can look at the [transparency dashboard]({dashboardUrl}) to see where your post is in our moderation queue.': 'Vous serez informé lorsque le modérateur prendra une décision. En attendant, vous pouvez consulter le [tableau de bord de transparence]({dashboardUrl}) pour voir où se trouve votre message dans notre file d\'attente de modération.',
    'I understand': 'Je comprends',
    'We strive for a kind and constructive discussion space where everyone feels welcome to contribute their perspectives and lived experiences to the conversation. This discussion space values responses that are on-topic and relevant to the subject matter, including meaningful debate.':'Nous souhaitons créer un espace de discussion agréable et constructif où chacun est à l’aise d’apporter son point de vue et son expérience à la conversation. Ce lieu valorise les échanges pertinents, y compris des débats, qui portent sur le sujet de discussion.',
    'To foster a welcoming and respectful environment, this discussion space uses AI-aided moderation to enforce the following community guidelines:': 'Afin de favoriser un environnement accueillant et respectueux, cet espace utilise la modération assistée par l’intelligence artificielle pour faire respecter les lignes directrices suivantes :',
    'No uncivil, hateful, or illegal content':'Aucun contenu irrespectueux, haineux ou illégal',
    'No comments that call for or celebrate harm': "Aucun commentaire appelant ou incitant à la violence",
    'No insults, mockery, threats, or personal attacks': "Pas d'insultes, de moqueries, de menaces, ou d'attaques personnelles",
    'Refrain from hostile comments that attach negative labels to, or assume bad intent of specific groups of people':"S'abstenir de commentaires hostiles qui attribuent des étiquettes négatives à des groupes spécifiques de personnes ou qui leur prêtent de mauvaises intentions",
    'Back up contentious claims with sources': "Pas de contenu ou d’argumentaire basé sur de fausses affirmations ou de fausses nouvelles; les affirmations litigieuses doivent être étayées par des sources crédibles",
    'No spam or commercial content': "Votre contenu doit être de nature personnelle, et non de nature commerciale, et ne doit pas inclure de messages non sollicités (spam).",
    "Your post may have violated the following Community Guidelines:": "Votre message peut avoir violé les lignes directrices suivantes :",
    "Need help?": "Besoin d'aide ?",
    "Thank you!": "Merci !",
    'Your response has been sent to a moderator for review.': 'Votre réponse a été envoyée au modérateur pour la révisée.',
    "📫 What happens next": "Que se passe-t-il ensuite",
    '✨ We use AI for moderation': '✨ Nous utilisons l\'IA pour la modération',
    'AI helps us to manage moderation, but sometimes it triggers incorrectly. If you are unable to get our AI to accept a respectfully expressed opinion, please send it to our moderators for review.': 'L\'IA nous aide à gerer la modération, mais certainement, il se fait mal. Si vous ne pouvez pas obtenir l\'IA à accepter une opinion en consciences, envoyez-la au modérateur pour la révisée.',
    "Message to moderator...": "Message au modérateur...",
    'Please explain why we should publish your post.': 'Veuillez expliquer pourquoi nous devons publier votre message.',
    'Done': 'Fait',
    'Respect the privacy of others and do not include personal information in your contributions': "Respectez la vie privée des autres en n'incluant dans votre contenu aucun renseignement personnel (comme un numéro de téléphone ou une adresse de courriel) ni conversation privée",
    "Your response is being reviewed by AI to ensure it follows our Community Guidelines": "Votre réponse est en cours de modération par notre outil d’intelligence artificielle pour s'assurer qu'elle respecte les lignes directrices de la communauté.",
    "Reviewing your perspective with AI to ensure it follows our Community Guidelines": "En cours de modération de votre perspective par notre outil d’intelligence artificielle pour s'assurer qu'elle respecte les lignes directrices de la communauté.",
    'Perspectives must be relevant to the subject matter': 'Perspectives doivent porter sur le sujet de discussion',
    'Perspectives must foster productive discourse': 'Perspectives doivent favoriser un dialogue productif',
    'You may have violated the following Community Guidelines:': 'Vous avez violé les lignes directrices suivantes :',
    "⚠️ Check your perspective": "⚠️ Vérifiez votre perspective",
    "⚠️ Check your response": "⚠️ Vérifiez votre réponse",
    "AI review found that your perspective may be incompatible with the following ": "L'examen par l'IA a révélé que votre perspective pourrait être incompatible avec ce qui suit ",
    "AI review found that your response may be incompatible with the following ": "L'examen par l'IA a révélé que votre réponse pourrait être incompatible avec ce qui suit ",
    "AI review found that your {thing} may be incompatible with the following [Community Guidelines]($onPress)": "L'examen par l'IA a révélé que votre {thing} pourrait être incompatible avec les lignes directrices suivantes [Community Guidelines]($onPress)",
    "perspective": "perspective",
    "response": "réponse",
    "Consider rewording your perspective:": "Envisagez de reformuler votre perspective :",
    "Consider rewording your response:": "Envisagez de reformuler votre réponse :",
    "Did AI get it wrong?": "L'IA s'est-elle trompée ?",
    "Send to a moderator for review": "Envoyer à un modérateur pour examen",
    "Express your ideas with civility": "Exprimez vos idées avec courtoisie",
    "No violent content or calls for harm": "Pas de contenu violent ou d'incitation à la violence",
    "Avoid sarcasm and dismissive responses": "Évitez le sarcasme et les réponses méprisantes",
    "Avoid derogatory labels": "Évitez les étiquettes dénigrantes",
    "Provide sources for factual claims": "Fournissez des sources pour les affirmations factuelles",
    "No spam or commercial promotion": "Pas de spam ou de promotion commerciale",
    "Respect the privacy of others, no personal information sharing": "Respectez la vie privée d'autrui, ne partagez pas d'informations personnelles",
    "No off-topic posts": "Pas de messages hors sujet",
    "No illegal content": "Pas de contenu illégal",
    "Focus on ideas, rather than character judgments": "Concentrez-vous sur les idées, plutôt que sur les jugements de caractère",
    "Explain disagreements constructively": "Expliquez les désaccords de manière constructive",
    "Engage Respectfully": "Engagez-vous avec respect",
    "Support Your Claims": "Soutenez vos affirmations",
    "No Harmful Content": "Pas de contenu nuisible",
    "No Disruptive Behavior": "Pas de comportement perturbateur",
    "To foster a welcoming and respectful environment, this discussion space uses AI-aided moderation to support these community guidelines:": "Pour favoriser un environnement accueillant et respectueux, ce discussion space utilise l'intelligence artificielle pour soutenir ces lignes directrices de la communauté :",

    // Comment Slider
    'Strongly no': 'Non catégorique',
    'No with reservations': 'Plutôt non',
    "It's complicated": "C'est complexe",
    'Yes with reservations': "Plutôt oui",
    'Strongly yes': "Oui catégorique",

    'Strongly disagree': 'Fortement en désaccord',
    'Somewhat disagree': 'Plutôt en désaccord',
    'Somewhat agree': 'Plutôt d\'accord',
    'Strongly agree': 'Fortement d\'accord',

    'Clear Filter': 'Effacer le filtre',
    'Your current public response': 'Votre réponse publique actuelle',
    'Showing {responseCount} public responses and {voteCount} anonymous votes': 'Affichage de {responseCount} réponses publiques et {voteCount} votes anonymes',
    'Tap to respond': 'Appuyez pour répondre',
    'Slide to respond:': 'Faites glisser pour répondre:',
    'Where do you stand?': 'Où en êtes-vous ?',
    'Explain your response (optional)': 'Expliquez votre réponse (facultatif)',
    'Public response': 'Réponse publique',
    'Anonymous vote': 'Vote anonyme',
    'Agree/Disagree': 'D\'accord/Pas d\'accord',
    'Explain your response': 'Expliquez votre réponse',
    'Share your thoughts... (optional)': 'Partagez vos pensées... (facultatif)',

    // Question Screen
    'responses': 'réponses',
    'response': 'réponse',
    'Update your response': 'Mettre à jour votre réponse',
    'Only visible to you': 'Visible uniquement par vous',
    '🔍 Under Review': '🔍 En cours de révision',
    'Share': 'Partager',
    'Your current response': 'Votre réponse actuelle',
    'There are no responses yet. Start the conversation!': 'Il n\'y a pas encore de réponses. Commencez la conversation!',

    // Single Question
    'Choose a Question (Admin)': 'Choisir une question (Admin)',
    'Choose a question': 'Choisir une question',
    'Ask a question...' : 'Poser une question...',
    'Choose': 'Choisir',
    'Chosen': 'Choisi',
    'Choose a different question (admin)': 'Choisir une autre question (admin)',
    'Write your answer...': 'Écrivez votre réponse...',
    'no answers yet': 'aucune réponse pour l\'instant',

    // Multi question
    'Choose Questions for this Article': 'Choisir des questions pour cet article',
    'Edit questions (admin)': 'Modifier les questions (admin)',
    'Related Conversations': 'Conversations connexes',
    'Add Question': 'Ajouter une question',
    'Editorial Question': 'Question éditoriale',
    'Adding...' : 'Ajout...',
    'Related Conversation': 'Conversation connexe',
    'Write the first response': 'Écrivez la première réponse',
    'Join': 'Rejoindre',

    // Feature Module Names
    'Comment Slider': 'Curseur de commentaire',
    'Article Questions': 'Questions d\'article',
    'Pre-Moderation': 'Pré-modération',
    'Question Byline': 'Ligne de question',
    'Reaction Types': 'Types de réaction',
    'Narwhal Reactions': 'Réactions Narwhal',

    // feature poll
    'Select one option': 'Sélectionner une option',
    'Your response': 'Votre réponse :',
    'Submit my vote': 'Soumettre',
    'Change my vote': 'Modifier ma réponse',
    'Other': 'Autre',
    'You': 'Vous',
    'Write in a topic...': 'Rédiger un sujet...',

    // Topic admin form
    'Topic': 'Sujet',
    'Topics': 'Sujets',
    'Search a topic...': 'Rechercher un sujet...',
    'URL for photo to appear in the teaser': 'URL de la photo pour apparaître dans le teaser',
    'Create a topic': 'Créer un sujet',
    'Topic name': 'Titre du sujet',
    'Starts at': 'Date de début',
    'Ends at': 'Date de fin',
    '(YYYY-MM-DD format)': '(Format AAAA-MM-JJ)',
    'Conversation starter': "Question aux utilisateurs",
    'Is active': 'Actif',
    'Active': 'Actif',
    'Add topic': 'Créer le sujet',
    'Topic poll': 'Sondage',
    'Upcoming topic poll': 'Sondage pour le prochain sujet du moment',
    'Poll question': 'Question du sondage',
    'Open answer label': 'Titre du choix de réponse ouvert',
    'Open answer placeholder': 'Placeholder du choix de réponse ouvert',
    'Remove option': 'Retirer un choix de réponse',
    'Add option': 'Ajouter un choix de réponse',
    'Option...': 'Choix de réponse...',
    'Edit topics (admin)': 'Modifier les sujets (admin)',


    // Moderation
    '**Avoid uncivil or disrespectful language**. You can express any opinion, however you must do so in a respectful way.': '**Évitez le langage incivil ou irrespectueux**. Vous pouvez exprimer n\'importe quelle opinion, mais vous devez le faire de manière respectueuse.',
    '**Avoid calls for harm or celebration of harm**. You can advocate for actions that would have negative consequences for some people, but harm should not be the primary goal.': '**Évitez les appels au mal ou la célébration du mal**. Vous pouvez plaider en faveur d\'actions qui auraient des conséquences négatives pour certaines personnes, mais le mal ne devrait pas être l\'objectif principal.',
    '**Do not imply that someone has bad intent**. Assume they have good intentions and critique their ideas and their actions instead.': '**Ne laissez pas entendre que quelqu\'un a de mauvaises intentions**. Supposez qu\'ils ont de bonnes intentions et critiquez plutôt leurs idées et leurs actions.',
    '**Avoid attacking the character of others**. Critique their ideas and their actions instead.': '**Évitez d\'attaquer le caractère des autres**. Critiquez plutôt leurs idées et leurs actions.',
    '**Avoid snark, sarcasm, and mockery**': '**Évitez le sarcasme, la moquerie et la dérision**',
    '**Controversial claims must provide a source URL**': '**Les affirmations controversées doivent fournir une URL source**',
    '**Be sure to write your response in a way that those who disagree with will be willing to listen to**': '**Assurez-vous d\'écrire votre réponse de manière à ce que ceux qui ne sont pas d\'accord soient prêts à écouter**',

    '⚠️ Take another look': '⚠️ Revoir',
    'Your post may violate our [Community Guidelines](https://example.com).': 'Votre message peut violer nos [Règles de la communauté](https://example.com).',
    '**Sometimes our AI triggers incorrectly**': '**Parfois, notre AI se déclenche de manière incorrecte**',
    'If that happened then first try adjusting your wording to be more obviously respectful. If you are still unable to get the AI to accept a respectfully expressed opinion then please send it to our human moderator.': 'Si cela s\'est produit, essayez d\'abord d\'ajuster vos mots pour qu\'ils soient plus évidemment respectueux. Si vous êtes toujours incapable de faire accepter un avis exprimé de manière respectueuse, veuillez le transmettre à notre modérateur humain.',
    'To provide transparency, we offer an anonymized [public database]({dashboardUrl}) of all moderation decisions.': 'Pour assurer la transparence, nous proposons une [base de données publique]({dashboardUrl}) anonymisée de toutes les décisions de modération',
    'Send to moderator': 'Envoyer au modérateur',
    'Message to moderator*': 'Message au modérateur*',
    'Please explain your response (required)': 'Veuillez expliquer votre réponse (obligatoire)',
    'Rejected': 'Rejeté',
    'Approved': 'Approuvé',
    'Awaiting Decision': 'En attente de décision',
    'Moderation Queue': 'File d’attente de modération',
    'Moderator Explanation': 'Explication du modérateur',
    'Explain your decision...': 'Expliquez votre décision...',
    'Approve': 'Approuver',
    'Reject': 'Rejeter',
    '**Message to moderator**: {appealText}': 'Message au modérateur : {appealText}',
    'Moderation Transparency Dashboard': 'Tableau de bord de transparence de la modération',

    // Fonctionnalité Blacklist
    'Blacklisted Terms': 'Termes de la liste noire',
    'Blacklist is empty.': 'La liste noire est vide.',
    'Blacklist': 'Liste noire' ,
    'Select': 'Sélectionner',
    'Deselect': 'Désélectionner',
    'Please enter at least one valid term.': 'Veuillez entrer au moins un terme valide.',
    'The following term(s) already exist in the blacklist.': 'Les termes suivants existent déjà dans la liste noire.',
    'Adding term(s)...': 'Ajout des termes...',
    'Added term(s) successfully!': 'Termes ajoutés avec succès !',
    'Deleting term(s)...': 'Suppression des termes...',
    'Deleted term(s) successfully!': 'Termes supprimés avec succès !',
    'Term(s) are already in blacklist': 'Le(s) terme(s) figure(nt) déjà dans la liste noire',
    'Enter new terms, separated by new lines': 'Entrez de nouveaux termes, séparés par des nouvelles lignes',
    'Add to blacklist': 'Ajouter à la liste noire',
    'Hold Shift and double-click to select or deselect multiple terms.': 'Maintenez Maj et double-cliquez pour sélectionner ou désélectionner plusieurs termes.',
    'Delete selected': 'Supprimer les termes sélectionnés',
    'Delete terms?': 'Supprimer les termes ?',
    'Do you really want to delete the selected term(s)? This action cannot be undone.': 'Voulez-vous vraiment supprimer les termes sélectionnés ? Cette action est irréversible.',
    'Delete': 'Supprimer',
    'Back': 'Retour',
    'Close': 'Fermer',

    // Reactions
    'Thank you': 'Merci',
    'New to me': 'Nouveau pour moi',
    'Respect': 'Respect',
    'Disagree': 'Pas d\'accord',
    'Best': 'Meilleur',
    'Provocative': 'Provocateur',
    'Clarifying': 'Éclairant',
    'Relatable': 'Relatable',
    'Helpful': 'Utile',
    'Controversial': 'Controversé',
    'Interesting': 'Intéressant',

    // Representing Perspectives
    'Perspective Editor (Admin)': 'Éditeur de perspectives (Admin)',
    'Perspective List': 'Liste des perspectives',
    'What perspectives are important to this conversation? (newline separated)': 'Quelles perspectives sont importantes pour cette conversation? (séparées par des sauts de ligne)',
    "Add your perspective": "Ajoutez votre perspective",
    "Perspectives": "Perspectives",
    "We haven't heard from these perspectives yet": "Nous n'avons pas encore entendu ces perspectives",
    'Add a response': 'Ajouter une réponse',
    'How do you relate to this issue?': 'Comment vous rapportez-vous à ce problème?',
    'Sort by perspective': 'Trier par perspective',
    'Apply': 'Appliquer',
    'Show more responses': 'Afficher plus de réponses',
    'Edit perspectives (Admin)': 'Modifier les perspectives (Admin)',
    'What perspectives do you want to see first?': 'Quelles perspectives voulez-vous voir en premier?',
    "We'd particularly like to hear from:": "Nous aimerions particulièrement avoir l'opinion de :",
    "Add your own": "Ajouter votre propre",
    "Write in your own": "Ecrivez dans votre propre",
    'Write in a perspective': 'Ecrivez dans une perspective',

    // Conversation History
    'Conversation history': 'Historique de la conversation',
    'Visible to others': 'Visible aux autres',
    'Your conversation history is always visible to admins and moderators.': 'Votre historique de conversation est toujours visible par les administrateurs et les modérateurs.',
    'Visible only to admins': 'Visible uniquement par les administrateurs',
    'This user has not made their conversation history public.': 'Cet utilisateur n\'a pas rendu son historique de conversation public.',

    // Other
    'No main screen defined.': 'Aucun écran principal défini.',

    // ToM
    'Live': 'En cours',
    'Ended': 'Terminé',
    'Follow': 'Suivre',
    'Following': 'Suivi',
    'Post': 'Publier',
    'Topic of the moment': 'Sujet du moment',
    'participant': 'participant(e)',
    'participants': 'participants',
    'Ask us a question...': 'Réagir ou poser une question...',
    'Your question will be considered for future content pieces. Please note, we may not be able to answer all questions.': 'Vos contributions pourraient inspirer de futurs contenus. Il est possible que nous ne puissions pas répondre à chacune d’elles.',
    'Popular questions': 'Les plus populaires',
    'View all questions': 'Tout afficher',
    'Be the first to share your thoughts.': 'Ouvrez le bal!\nSoyez la première personne à s’exprimer sur le sujet.',
    'See all': 'Toutes les contributions',
    'Discuss this topic with other citizens': 'On en parle aussi...',
    'Response': 'réponse',
    'Responses': 'réponses',
    'Need more information to make up your mind?': 'Envie de creuser le sujet?',
    'Previous': 'Précédent',
    'Next': 'Suivant',
    'Previous topics covered': 'Des sujets qui ont fait jaser',
    'No more questions': 'C’est tout pour le moment.',
    'All questions': 'Toutes les contributions',
    'Filters': 'Filtres',
    'Filter': 'Filtrer',
    'Sort by': 'Filtrer par',
    'Most recent': 'Plus récentes',
    'Oldest': 'Plus anciennes',
    'Most popular': 'Plus populaires',
    'Pin': 'Épingler',
    'Feature': 'Mettre en avant',
    'Featured': 'En avant',
    // AMA
    'Upcoming': 'À venir',

    // Conversation Helper
    "Help history": "Historique de l'aide",
    "Conversation Help": "Aide à la conversation",
    "Hello everyone!": "Bonjour à tous !",
    "Open dashboard (admin)": "Ouvrir le tableau de bord (admin)",
    "Conversation Helper": "Aide à la conversation",
    "Add-ons": "Extensions",
    "Reactions": "Réactions",
    "Help History": "Historique de l'aide",
    "Conversation Summary": "Résumé de la discussion",
    "Private Comment Feedback": "Réaction privée au commentaire",
    "Timing of Analysis": "Moment de l'analyse",
    "Automatic analysis": "Analyse automatique",
    "Analyze after every...": "Analyser après chaque...",
    "1 comment": "Commentaire",
    "3 comments": "3ème commentaire",
    "5 comments": "5e commentaire",
    "10 comments": "10e commentaire",
    "Only manual analysis": "Analyse manuelle seulement",
    "Update guidance if there are no problems": "Actualise la note s'il n'y a pas de problème",
    "Update based on comments...": "Actualiser sur la base des commentaires...",
    "Never": "Jamais",
    "Every comment": "Après chaque commentaire",
    "Every 3 comments": "Tous les 3 commentaires",
    "Every 5 comments": "Tous les 5 commentaires",
    "Every 10 comments": "Tous les 10 commentaires",
    "Update based on time...": "Actualiser sur la base du temps...",
    "Immediately": "Immédiatement",
    "Every 10 min": "Toutes les 10 minutes",
    "Every 30 min": "Toutes les 30 minutes",
    "Every hour": "Chaque heure",
    "Scope of Analysis": "Étendue de l'analyse",
    "Analyze...": "Analyser...",
    "All comments": "Tous les commentaires",
    "Most recent x% of comments": "Derniers x% des commentaires",
    "New comments since last analysis": "Nouveaux commentaires depuis la dernière analyse",
    "Use percentage...": "Choisir le pourcentage...",
    "Most recent 10%": "Derniers 10%",
    "Most recent 25%": "Derniers 25%",
    "Most recent 50%": "Derniers 50%",
    "Apply percentage...": "Utiliser des pourcentages...",
    "Always": "Toujours",
    "After 5 comments": "Après 5 commentaires",
    "After 10 comments": "Après 10 commentaires",
    "Auto-Moderator Personality": "Personnalité de l'auto-modérateur",
    "Use personality...": "Utilise la personnalité...",
    "Personality A": "Personnalité A",
    "Personality B": "Personnalité B",
    "Personality C": "Personnalité C",
    "Conversation Helper Dashboard": "Tableau de bord de l'aide à la conversation",
    "Related Thread": "Conversation associée",
    "Current Guidance": "Note d'aide actuelle",
    "This guidance message is visible to everyone.": "Cette note est visible par tous.",
    "This message was generated because...": "Cette note a été générée parce que...",
    "Generate new guidance": "Générer une nouvelle note",
    "Conversation Analysis": "Analyse de la conversation",
    "Analyze conversation": "Analyser la conversation",
    "Result: ": "Résultat: ",
    "Problems detected!": "Problèmes détectés !",
    "No problems detected.": "Aucun problème n'a été détecté.",
    "Explanation": "Explication",
    "Conversation Stats": "Statistiques de conversation",
    "- Last conversation analysis: **{time}**": "- Dernière analyse de l'entretien: **{time}**",
    "- Last conversation analysis: **None**": "- Dernière analyse de l'entretien: **Aucune**",
    "- **{count} check(s)** for problems performed": "- **{count} analyse(s) de conversation** réalisée(s)",
    "- **{count} guidance message(s)** generated": "- **{count} note(s)** générée",
    "- **{count} new comment(s)** since last conversation analysis": "- **{count} nouveau(x) commentaire(s)** depuis la dernière analyse",

    // VVT
    'Enable voting': 'Autoriser le vote',
    'Vote anonymously': 'Vote anonyme',
    'Start Video': 'Lancer la vidéo',
    'This video contains audience questions that you can participate in. You can vote anonymously, skip individual questions, or disable this feature. In the ' :
    'Cette vidéo contient des questions de spectateurs auxquelles tu peux participer. Tu peux désactiver cette fonction, voter de manière anonyme et ignorer certaines questions. À propos de la ',
    'settings': 'Préférences',
    ' you can deactivate it for all videos.': ' tu peux désactiver cette fonction de manière générale.',
    "You have to be logged in to use this feature.\nThe video will play as usual and you won't see any votings.\n**Click here to log in**." :
    "Vous devez être connecté pour utiliser cette fonction.\nLa vidéo sera lue comme d'habitude et vous ne verrez pas les votes.\n**Cliquez ici pour vous connecter**.",

    // Video Voting - Voting flow
    'Discuss with others': "Echange avec d'autres",
    'Take a look at the voting in the overview': "Consulter l'aperçu du vote",
    "What do you think about it now?": "Qu'en penses-tu maintenant ?",

    // Video Voting - Sidebar
    'Video Voting': 'Vote par vidéo',
    'Voting Results': 'Résultats des votes',
    'All responses': 'Tous les commentaires',
    'Votings': 'Votes',
    "Time:": "Temps:",
    'END': 'Fin',
    "No voting data available.": "Aucune donnée de vote disponible",
    'Go to video': 'Voir la vidéo',

    // Video Voting - User profile
    "Settings": "Préférences",
    "Enable voting for all videos": "Activer le vote pour toutes les vidéos",

    // Video Voting - Administrative text
    'Manage Video Voting (Admin)': 'Gérer le vote vidéo (Admin)',
    "Please create default question threads first.": "Veuillez d'abord créer les fils de questions standard.",
    "Create Default Questions": "Créer des questions standard",
    "Create a Video Voting for this Article": "Crée un vote vidéo pour cet article",
    "This article does not have a video voting yet.": "Cet article n'a pas encore fait l'objet d'un vote vidéo.",
    "Individual Questions": "Questions individuelles",
    "Connected Questions": "Questions sur l'évolution",
    "Endometriosis (German)": "Endométriose (en allemand)",
    "Endometriosis (English)": "Endométriose (en anglais)",
    "Open relationships (German)": "Relations ouvertes (en allemand)",
    "Open relationships (English)": "Relations ouvertes (en anglais))",
    "Create Video Voting": "Création d'un vote vidéo",
    "Delete Video Voting": "Supprimer le vote vidéo",
    "The video voting template is missing": "Le modèle de vote vidéo manque",
    "Video voting is not enabled for this video.": "Le vote vidéo n'est pas activé pour cette vidéo.",
    'Video voting is enabled for this video.' : 'Le vote vidéo est activé pour cette vidéo.',
    'Video voting is enabled for this video. To participate, please start the video.': 'Le vote vidéo est activé pour cette vidéo. Pour participer, tu dois être connecté.',

    // Context Entry Points
    "You are joining from:": "Tu rejoins depuis :",
    "Joining from:": "Rejoins depuis :",
    "Read article": "Lire l'article",
    "Joined from": "Rejoint depuis",

    // Reading List / Topic
    "Reading List": "Liste de lecture",
    "Learn more about this topic": "En savoir plus sur ce sujet",
    "Discussion topic": "Sujet de discussion",
    "Show more": "Afficher plus",
    'Manage related articles': 'Gérer les articles connexes',
    'Edit related conversations' : 'Modifier les conversations connexes',

    // Moderation Dashboard
    'Message to moderator:': 'Message au modérateur :',
    "Today, ": "Aujourd'hui, ",
    "Yesterday, ": "Hier, ",
    
    // Missing translations
    'Sending...': 'Envoi...',
    '🚫 Rejected by Human Moderator': '🚫 Rejeté par un modérateur humain',
    '🚧 Temporarily Closed': '🚧 Fermé temporairement',
    'This conversation has been temporarily closed. Please check back soon for it to re-open.': 'Cette conversation a été temporairement fermée. Veuillez revenir bientôt pour sa réouverture.',
    'Delete this post?': 'Supprimer ce message ?',
    'This action can\'t be undone': 'Cette action est irréversible',
    'You do not have access to this feature': 'Vous n\'avez pas accès à cette fonctionnalité',
    'All posts will be manually moderated for civility': 'Tous les messages seront modérés manuellement pour courtoisie',
    'What happens next': 'Que se passe-t-il ensuite',
    'Your response will be reviewed by a moderator': 'Votre réponse sera examinée par un modérateur',
    'You should get a response within 24 hours.': 'Vous devriez recevoir une réponse sous 24 heures.',
    'If your response is approved, it will be posted.': 'Si votre réponse est approuvée, elle sera publiée.',
    'If your response is not approved, you will be notified and given the opportunity to edit and resubmit.': 'Si votre réponse n\'est pas approuvée, vous serez informé et aurez la possibilité de la modifier et de la soumettre à nouveau.',
    'Continue with Radio Canada': 'Continuer avec Radio Canada',
    'Continue with Radio Canada Internal': 'Continuer avec Radio Canada Interne',
    'Logging in...': 'Connexion...',
    'Beta': 'Bêta',
    'Your response is under review': 'Votre réponse est en cours de révision',
    'Your response was rejected by a moderator': 'Votre réponse a été rejetée par un modérateur',
    'Conversation History': 'Historique de la conversation',
    'Profile Deletion': 'Suppression de profil',
    'Delete data': 'Supprimer les données',
    'Are you sure you want to delete your profile?': 'Êtes-vous sûr de vouloir supprimer votre profil ?',
    'Deleting your profile cannot be undone. Any data and activity associated with your profile may take up to 72 hours to be removed.': 'La suppression de votre profil est irréversible. Toutes les données et activités associées à votre profil peuvent prendre jusqu\'à 72 heures pour être supprimées.',
    'Deleting profile data. Please wait...': 'Suppression des données du profil. Veuillez patienter...',
    'Report': 'Signaler',
    'PLAY': 'LIRE',
    'PREV': 'PRÉC',
    'FWRD': 'SUIV',
    'PAUSE': 'PAUS',
    'START': 'DÉM',
    'No question for this content': 'Aucune question pour ce contenu',
    'Open admin screen': 'Ouvrir l\'écran admin',
    'No admin screen defined. Use the feature selector to enable a feature.': 'Aucun écran admin défini. Utilisez le sélecteur de fonctionnalités pour activer une fonctionnalité.',
    'Change': 'Modifier',
    'Configure Teaser Features (admin)': 'Configurer les fonctionnalités d\'accroche (admin)',
    'Pinned': 'Épinglé',
    'Which community guideline does this violate?': 'Quelle directive communautaire cela enfreint-il ?',
    'Add': 'Ajouter',
    'Select topics for article (admin)': 'Sélectionner des sujets pour l\'article (admin)',
    'Choose Topics for this Article': 'Choisissez des sujets pour cet article',
    'Sarahs Leben mit dem Schmerz': 'La vie de Sarah avec la douleur',
    'Load video element': 'Charger l\'élément vidéo',
    'Individual questions': 'Questions individuelles',
    'Connected questions': 'Questions connectées',
    'Admin Dashboard': 'Tableau de bord admin',
    'Quick Links': 'Liens rapides',
    'Component Demo': 'Démo de composant',
    'Event Log': 'Journal des événements',
    'Only an admin can see this page': 'Seul un administrateur peut voir cette page',
    'Admin Users': 'Utilisateurs admin',
    'Loading admin users...': 'Chargement des utilisateurs admin...',
    'Add new admin users': 'Ajouter de nouveaux utilisateurs admin',
    'Emails of users to add': 'Emails des utilisateurs à ajouter',
    'Owner': 'Propriétaire',
    'Developer': 'Développeur',
    'Super-Moderator': 'Super-modérateur',
    'Moderator': 'Modérateur',
    'Editorial': 'Éditorial',
    'Analyst': 'Analyste',
    'Updating roles...': 'Mise à jour des rôles...',
    'Add Admins': 'Ajouter des administrateurs',
    'Adding Admins...': 'Ajout d\'administrateurs...',
    'Request Data': 'Demander des données',
    'Send an email to [privacy@newpublic.org](mailto:privacy@newpublic.org) to request a copy of your data associated with this discussion space. Once submitted, please allow up to 30 days for us to process your request.': 'Envoyez un e-mail à [privacy@newpublic.org](mailto:privacy@newpublic.org) pour demander une copie de vos données associées à cet espace de discussion. Une fois la demande soumise, veuillez prévoir jusqu\'à 30 jours pour que nous puissions traiter votre demande.',

    // Jan 13 String Review
    "Send an email to [privacy@newpublic.org](mailto:privacy@newpublic.org) to request a copy of your data associated with this discussion space. It may take up to 30 days to process your request.": "Envoyez un e-mail à [privacy@newpublic.org](mailto:privacy@newpublic.org) pour demander une copie de vos données associées à cet espace de discussion. Le traitement de votre demande peut prendre jusqu'à 30 jours.",
    "AI helps us moderate comments, but sometimes it can make a mistake. If AI is preventing you from sharing a respectful opinion, send your comment to a moderator for review.": "L'IA nous aide à modérer les commentaires, mais elle peut parfois se tromper. Si l'IA vous empêche de partager une opinion respectueuse, envoyez votre commentaire à un modérateur pour examen.",
    "This discussion is temporarily unavailable. Check back for updates.": "Cette discussion est temporairement indisponible. Revenez plus tard pour des mises à jour.",
    "🚧 Temporarily Unavailable": "🚧 Temporairement indisponible",
    "We strive for a kind and constructive discussion space where everyone feels welcome to share their perspectives and experiences. You can contribute to a discussion with responses that are on-topic and relevant to the subject matter, including meaningful debate.": "Nous nous efforçons de créer un espace de discussion aimable et constructif où tout le monde se sent bienvenu pour partager ses perspectives et expériences. Vous pouvez contribuer à une discussion avec des réponses qui sont pertinentes et en lien avec le sujet, y compris des débats constructifs.",
    "To maintain a welcoming and respectful environment, this space uses AI to help moderate comments. AI may flag your post for your additional review if it does not follow the community guidelines:": "Pour maintenir un environnement accueillant et respectueux, cet espace utilise l'IA pour aider à modérer les commentaires. L'IA peut signaler votre publication pour un examen supplémentaire si elle ne respecte pas les directives communautaires :",
    "AI review found that your perspective label may be incompatible with the following ": "L'examen par l'IA a déterminé que votre étiquette de perspective pourrait être incompatible avec ce qui suit ",
    "Choose a name and photo that appears when you post in discussions.": "Choisissez un nom et une photo qui apparaissent lorsque vous publiez dans des discussions.",
    "Set up your profile": "Configurez votre profil",
    "Custom names can only include lowercase letters and numbers.": "Les noms personnalisés peuvent uniquement inclure des lettres minuscules et des chiffres.",
    "Custom names can only be changed once per week.": "Les noms personnalisés ne peuvent être modifiés qu'une fois par semaine.",
    "This custom name is already in use.": "Ce nom personnalisé est déjà utilisé.",
    "Reviewing your perspective label with AI to ensure it follows our Community Guidelines": "Examen de votre étiquette de perspective avec l'IA pour s'assurer qu'elle respecte nos directives communautaires",
    "Are you sure you want to delete your profile data?": "Êtes-vous sûr de vouloir supprimer les données de votre profil ?",
    "Delete my data": "Supprimer mes données",
    "Request my data": "Demander mes données",
    "**Share details** (required)": "**Partagez des détails** (obligatoire)",
    "Message to Moderator...": "Message au modérateur...",
    "user123": "utilisateur123",
    "Add a custom name": "Ajouter un nom personnalisé",
    "Visible to everyone": "Visible par tous",
    "Visible to admins": "Visible par les administrateurs",
    "Discussion History": "Historique des discussions",
    "This user's history is private.": "L'historique de cet utilisateur est privé.",
    "Admins and Moderators can view your history.": "Les administrateurs et modérateurs peuvent consulter votre historique.",
    "What guideline does this violate? Select all that apply.": "Quelle directive cela viole-t-il ? Sélectionnez toutes les options applicables.",
    "Admin Only": "Administrateurs uniquement",
    "Add new Admins": "Ajouter de nouveaux administrateurs",
    "Loading Admins...": "Chargement des administrateurs...",
    "You will receive an email notification when your post is approved or rejected by moderators.": "Vous recevrez une notification par e-mail lorsque votre publication sera approuvée ou rejetée par les modérateurs.",
    "Posts are manually moderated to meet the ": "Les publications sont modérées manuellement pour respecter les ",
    "If requested, your account's discussion history and engagement in any discussion is deleted as part of the request. This action cannot be undone.": "Sur demande, l'historique des discussions et la participation de votre compte à toute discussion sont supprimés dans le cadre de la demande. Cette action est irréversible.",    
    "This custom name may violate our community guidelines.": "Ce nom personnalisé peut enfreindre nos Directives Communautaires.",
    "Custom names are not allowed to look like real names.": "Les noms personnalisés ne doivent pas ressembler à de vrais noms.",
    "perspective label": "étiquette de perspective",
    "This custom name is incompatible with our Community Guidelines.": "Ce nom personnalisé est incompatible avec nos Directives Communautaires.",

    //Moderation Dashboard
    "🔥 Heated": "🔥 Animé",
    "Reported by users ": "Signalé par des utilisateurs",
    "Linked stories ": "Histoires liées",
    "{numArticles} linked stories": "{numArticles} histoires liées",    
    "remove": "supprimer",
    "Provide any details to help us determine if your post follows our Community Guidelines.": "Fournissez tous les détails pour nous aider à déterminer si votre publication respecte nos Directives Communautaires.",
    "Awaiting decision": "En attente de décision",
    "Automatically approved": "Approuvé automatiquement",
    "Manually approved": "Approuvé manuellement",
    "Manually rejected": "Rejeté manuellement",
    "Origin:": "Origine :",
    "Comment information": "Informations sur le commentaire",
    "Posted": "Publié",
    "Status": "Statut",
    "Internal Notes": "Notes internes",
    "Add your notes...": "Ajoutez vos notes...",
    "AI flag": "Drapeau IA",
    "📌 Community guideline violation": "📌 Violation des Directives Communautaires",
    "• No violent content or calls for harm": "• Aucun contenu violent ou appel à la violence",
    "User information": "Informations utilisateur",
    "Member since ": "Membre depuis ",
    "Show less": "Afficher moins",
    "comments & replies": "commentaires et réponses",
    "reported comments": "commentaires signalés",
    "flagged comments": "commentaires signalés par l'IA",
    "rejected comments": "commentaires rejetés",
    "user warnings": "avertissements utilisateur",
    "No origin available": "Aucune origine disponible",
    "View": "Voir",
    "User report": "Rapport utilisateur",
    "Human rejected ": "Rejeté manuellement ",
    "• No spam or commercial content": "• Pas de spam ou de contenu commercial",
    "Rejected by": "Rejeté par",
    "Reported by": "Signalé par",
    "Reported by users": "Signalé par des utilisateurs",
    "You are reviewing": "Vous êtes en train de vérifier",
    "Back to selection": "Retour à la sélection",
    "Which community guideline does this comment violate?": "Quelle directive communautaire ce commentaire viole-t-il ?",
    "Want to do anything else?": "Voulez-vous faire autre chose ?",
    "Warn user": "Avertir l'utilisateur",
    "No thanks": "Non merci",
    "Newest": "Le plus récent",
    "Warnings": "Avertissements",
    "PSI Moderation": "Modération PSI",
    "No comments.": "Aucun commentaire.",
    "View in thread": "Voir dans le fil",
    "Linked stories": "Histoires liées",
    "Threads": "Fils",
    "Open Threads": "Fils ouverts",
    "Closed Threads": "Fils fermés",
    "Closed": "Fermé",
    "User settings": "Paramètres utilisateur",
    "Block user": "Bloquer l'utilisateur",
    "Block this user?": "Bloquer cet utilisateur ?",
    "Do you really want to block this user? You can unblock the user via the profile page.": "Voulez-vous vraiment bloquer cet utilisateur ? Vous pouvez le débloquer via la page de profil.",
    "Blocked": "Bloqué",
    "Unblock user": "Débloquer l'utilisateur",
    "Unblock this user?": "Débloquer cet utilisateur ?",
    "Do you really want to unblock this user?": "Voulez-vous vraiment débloquer cet utilisateur ?",
    "Open": "Ouvert",

    // AI-tag
    "To maintain a welcoming and respectful environment, this space uses AI to moderate comments. AI may flag your post for your additional review if it does not follow the community guidelines.": "Pour maintenir un environnement accueillant et respectueux, cet espace utilise l'IA pour modérer les commentaires. L'IA peut signaler votre publication pour un examen supplémentaire si elle ne respecte pas les Directives Communautaires.",
    "To maintain a welcoming and respectful environment, this space uses AI to moderate comments. AI may flag your post for your additional review if it does not follow the community guidelines. Learn more.": "Pour maintenir un environnement accueillant et respectueux, cet espace utilise l'IA pour modérer les commentaires. L'IA peut signaler votre publication pour un examen supplémentaire si elle ne respecte pas les Directives Communautaires. En savoir plus.",
    "AI-moderated": "Modéré par l'IA",

 }


