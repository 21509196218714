import { useKeyboardShortcut } from "platform-specific/keyboardShortcutHandler";
import React, { useEffect, useRef } from "react";   

const TAB_KEYCODE = 9;
const ESCAPE_KEYCODE = 27;

export function FocusLoopView({ children, onCloseLoop, index }) {
    useKeyboardShortcut({shortcutKey: ESCAPE_KEYCODE, callback: onCloseLoop, index});
    const firstFocusableEl = useRef();
    const lastFocusableEl = useRef();
    let el = null;

    function tabNavigationHandler(e) {
        if (e.keyCode === TAB_KEYCODE) {
            if (e.shiftKey && document.activeElement === firstFocusableEl.current) {
                e.preventDefault();
                lastFocusableEl.current.focus();
            } else if (!e.shiftKey && document.activeElement === lastFocusableEl.current) {
                e.preventDefault();
                firstFocusableEl.current.focus();
            }
        }
    };
    useKeyboardShortcut({shortcutKey: TAB_KEYCODE, callback: tabNavigationHandler});

    useEffect(() => {
        el = document.activeElement;
        firstFocusableEl.current.focus();
    }, []);

    return <>
        <button id="begin-focus-trap" ref={firstFocusableEl} style={{ opacity: 0 }} />
        {children}
        <button id="end-focus-trap" ref={lastFocusableEl} style={{ opacity: 0 }} />
    </>

}
