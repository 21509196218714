import { ModDashboardQueue, ModDashboardQueueGeneral, ModerationFilters } from "../../../component/zdf/moddashboard/moddashboardqueue";
import { getQueueSubsetCounts, useFilteredQueues, useFilteredQueuesOfUser } from "../../../component/zdf/moddashboard/moddashboardqueuehooks";
import { CLICK, POPUP } from "../../../system/demo";
import { modDashboardDemoData } from "./moddashboard.data";

const queueStories = [
    {
        label: "Enable all other filters",
        actions: [
            CLICK(ModerationFilters.AutomaticallyApproved.text),
            CLICK(ModerationFilters.HumanApproved.text),
            CLICK(ModerationFilters.Rejected.text)
        ],
    },
    {
        label: "Disable all filters",
        actions: [
            CLICK(ModerationFilters.AwaitingDecision.text),
        ],
    },
    {
        label: "Approve a comment",
        actions: [
            CLICK("card-" + modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai.key),
            CLICK("Approve")
        ],
    },
    {
        label: "Reject a comment",
        actions: [
            CLICK("card-" + modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai.key),
            CLICK("Reject"),
            CLICK("Other"),
            CLICK("Reject"),
            CLICK("No thanks"),
        ],
    },
    {
        label: "Open a comment and change filter",
        actions: [
            CLICK("card-" + modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai.key),
            CLICK(ModerationFilters.AutomaticallyApproved.text),

        ],
    },
    {
        label: "Open a comment and close it",
        actions: [
            CLICK("card-" + modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai.key),
            CLICK("Show less"),

        ],
    },
    {
        label: "Change sorting order",
        actions: [
            CLICK("sortingOrder"),
            POPUP(CLICK("Newest")),
        ],
    }
]

export function ModDashboardQueueStorySets () {

    const overviews = [{
        title: "All comments queue",
        content: <ModDashboardQueueGeneral key={"demoqueue-all"} />
    },
    {
        title: "Queue for a specific user",
        content: <ModDashboardQueue
            key={"demoqueue-user"}
            structureKey={"profile"}
            instanceKey={modDashboardDemoData.demoAuthors.b.key}
            useQueueCounts={getQueueSubsetCounts}
            useAllFilteredQueues={useFilteredQueuesOfUser}
            additionalFilters={ModerationFilters.Warned}
        />
    },
    {
        title: "Instance based queue",
        content: <ModDashboardQueue
            structureKey={"question"}
            instanceKey={modDashboardDemoData.modulePublicQuestion.thread1.key}
            useAllFilteredQueues={useFilteredQueues}
            useQueueCounts={getQueueSubsetCounts}
        />
    }
    ]

    return overviews.map((overview) =>
        ({
            label: overview.title,
            serverCall: modDashboardDemoData.serverCalls,
            collections: modDashboardDemoData.instanceData.thread1.collections,
            globals: modDashboardDemoData.instanceData.thread1.globals,
            sessionData: {
                ["moderation/sessionId"]: "mySessionID"
            },
            modulePublic: {
                question: modDashboardDemoData.modulePublicQuestion,
                moderation: {
                    queue: {
                        ...modDashboardDemoData.modTaskQueues.thread1,
                        ...modDashboardDemoData.modTaskQueues.thread2,
                    },
                    queue_filtered: {
                        awaiting_decision: {
                            ...modDashboardDemoData.modTaskSubQueues.thread1.awaiting_decision,
                            ...modDashboardDemoData.modTaskSubQueues.thread2.awaiting_decision,
                        },
                        human_approved: {
                            ...modDashboardDemoData.modTaskSubQueues.thread1.human_approved,
                            ...modDashboardDemoData.modTaskSubQueues.thread2.human_approved,
                        },
                        auto_approved: {
                            ...modDashboardDemoData.modTaskSubQueues.thread1.auto_approved,
                            ...modDashboardDemoData.modTaskSubQueues.thread2.auto_approved,
                        },
                        rejected: {
                            ...modDashboardDemoData.modTaskSubQueues.thread1.rejected,
                            ...modDashboardDemoData.modTaskSubQueues.thread2.rejected,
                        },
                    },
                    count_queue_filtered: {
                        ...modDashboardDemoData.globalQueueCounts
                    },
                    moderator: modDashboardDemoData.demoAuthors,
                }
            },
            content: overview.content,
            stories: queueStories,
        }))
}