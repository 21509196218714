import React, { createContext, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { useVideo } from "./videoPlayerStateContext";
import { PadBox } from "component/basics.js"
import { Heading, UtilityText } from "component/text.js"
import { hideVideo, pauseVideo, playVideo, usePlayedVideo } from "../../system/videotime";
import { View } from "react-native";
import { CTAButton } from "component/button.js"

/**
 * This component mirrors the video played on the main article site
 * The mirrored video can act act as replacement providing more direct control
 */
export function ArticleMainVideoCopy({hideOriginalVideo = false}) {

    // Use the video context
    const { videoRef, attachEventListeners } = useVideo()
    const [url, setUrl] =  useState(null)

    // Use the original video from the article
    const player = usePlayedVideo();

    // Wait until the video url is available from the article's main video
    useEffect(()=> {
        if(!url && player.url) {    
          if(hideOriginalVideo) {
            pauseVideo();
            hideVideo();
          } 
          setUrl(player.url);
          // Set up listeners for the video state context
          attachEventListeners();
        }
    },[player])

    if(!url && !player.url) {
        return <PadBox horiz={20} vert={20}>
            <UtilityText label={"No playing video detected. Please start the video first."}></UtilityText>
        </PadBox>
    }

    return <View>
        <video src={url} controls={true} ref={videoRef}> </video>
        {showOverlay ? 
            <View style={{position: "absolute", width: "100%", height: "100%", pointerEvents: "none"}}></View>:
            <></>
        }
    </View>
}

// Simple video component, which uses the video state context
export function SimpleVideo({src, controls}) {
    // Use the video context
    const { videoRef, attachEventListeners } = useVideo()

    useEffect(()=> {
        attachEventListeners();
    },[])

    return <View>
        <video src={src} controls={controls} ref={videoRef}> </video>
    </View>
}


/** This keeps the video paused, in case it somehow gets started again
TODO: Find a more elegant solution to prevent autoplays */
export function ForceVideoPauseComponent() {
    const { isPlaying, currentTime } = useVideo();
    useEffect(()=> {
        pauseVideo();
    },[isPlaying, currentTime])
}