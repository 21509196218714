import { Banner } from "component/banner.js"
import { HorizBox, Narrow, Pad } from "component/basics.js"
import { colorGreyPopupBackground } from "component/color.js"
import { DemoHeader, SpacedArray } from "system/demo.js"
import { Heading, UtilityText } from "component/text.js"
import { useVideo, VideoPlayerStateContextProvider } from "../../contrib/zdf/videoPlayerStateContext";
import { SimpleVideo } from "../../contrib/zdf/videoPlayer";
import { View } from "react-native-web";
import { CTAButton } from "component/button.js"

export function VideoPlayerStateContextProviderDemo() {
    return (
        <Narrow>
            <DemoHeader text="VideoPlayerStateContext" />

            <SpacedArray pad={10}>
                <Banner color={colorGreyPopupBackground}>
                    <SpacedArray pad={10}>
                        <Heading text="How to use VideoPlayerStateContext" />
                        <UtilityText
                            text={"useVideoFromArticle - enables the control of the main video on the article site"}
                        />
                    </SpacedArray>
                </Banner>

                <VideoPlayerStateContextProvider useVideoFromArticle={false}>
                    <SimpleVideo
                        src={"https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"}
                    />
                    <Pad size={10} />
                    <SimpleVideoControls />
                </VideoPlayerStateContextProvider>
            </SpacedArray>
        </Narrow>
    );
}

function SimpleVideoControls() {
    const video = useVideo();
    return (
        <View>
            <HorizBox>
                <CTAButton text="PLAY" onPress={video.playVideo}></CTAButton>
                <Pad size={10} />
                <CTAButton text="PAUSE" onPress={video.pauseVideo}></CTAButton>
            </HorizBox>
            <Pad size={10} />
            <UtilityText text={"Duration: " + video.duration}></UtilityText>
            <UtilityText text={"Current Time: " + video.currentTime}></UtilityText>
            <UtilityText text={"Is Playing?: " + video.isPlaying}></UtilityText>
            <UtilityText text={"Ended: " + video.ended}></UtilityText>
        </View>
    );
}