import { View } from "react-native-web";
import { Narrow } from "../../component/basics";
import { Tag } from "../../component/button";
import { ToolTip } from "../../component/zdf/tooltip";
import { DemoSection } from "../../system/demo";
import { UtilityText } from "../../component/text";
import { Tools } from "@carbon/icons-react";
import { StyleSheet } from "react-native";

export function ToolTipDemo() {
    return (
        <Narrow>
            <DemoSection text={"Plain Tooltip"}>
                <ToolTip label={"Tooltip"}>
                    <Tag text={"Hover over me"} />
                </ToolTip>
            </DemoSection>
            <DemoSection text={"Custom offset value"}>
                <ToolTip label={"Tooltip"} offset={50}>
                    <Tag text={"Hover over me"} />
                </ToolTip>
            </DemoSection>
            <DemoSection text={"Tooltip with custom widget"}>
                <ToolTip toolTipWidget={<CustomTooltipWidget/>}>
                    <Tag text={"Hover over me"} />
                </ToolTip>
            </DemoSection>
        </Narrow>
    );
}

const CustomTooltipWidgetStyle = StyleSheet.create({
    container: {
        width: 220,
        gap: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    textContent: {
        display: "flex",
        flexDirection: "column",
    },
});

function CustomTooltipWidget() {
    const s = CustomTooltipWidgetStyle;

    return (
        <View style={s.container}>
            <Tools />
            <View style={s.textContent}>
                <UtilityText weight="strong" text="I'm a custom tooltip widget!" />
                <UtilityText text="Fill me with custom content." />
            </View>
        </View>
    );
}