import { HelpBubble } from "component/help.js"
import { useGlobalParams } from "util/datastore.js"

export const SingleResponseHelpFeature = {
    name: 'Single Response Help',
    key: 'single_response_help',
    dev: true,
    config: {
        myAnswerBottomWidgets: [HelpIfDifferentUrl],
    }
}

export function HelpIfDifferentUrl({comment}) {
    const {entryUrl} = useGlobalParams();
    if (!comment.entryPoints && !comment.entryUrl) return null;
    const commentUrl = comment?.entryUrl ?? comment?.entryPoints[0]?.url;
    const entryPointForHere = entryUrl == commentUrl;
    if (entryPointForHere) return null;

    return <HelpBubble 
        padTop={12}
        titleLabel="Same question, new article?" 
        label='Update your previous response with fresh insights' 
        helpKey='single-response' condition={true} left pointer
    />
}
