import { StructureDemo } from "util/instance.js"
import { defaultPersonaList } from "util/testpersonas"
import { makeAssetUrl, urlToKey } from 'util/util.js'

import { ParamContext } from "util/params.js"

const entryPoint = [
    {
        image: makeAssetUrl("images/bubbles.png"),
        title: "L’intelligence artificielle et son incidence sur nos vies",
        url: makeAssetUrl("https://google.com"),
        isClosed: false,
    }
]

const mockModulePublic = {
    article: { articleInfo: {
        [urlToKey('https://google.com')]: {
            title: 'L’intelligence artificielle et son incidence sur nos vies',
            image: makeAssetUrl("images/bubbles.png"),
        },
    }},
    language: 'frenchCanadian',
}

const comments = [
    { key: 3, from: 'c', slider: 1, text: 'Why is Mothra the best?', perspectives: { Parent: { text: 'Parent' } }, entryPoints: entryPoint },
    { key: 4, from: 'd', slider: 2, text: 'Why is Mechagodzilla the best?', perspectives: { Etudiant: { text: 'Étudiant' } }, entryPoints: entryPoint },
    { key: 5, from: 'bb', slider: 3, text: 'Why is King Gidorah the best?', perspectives: { Etudiant: { text: 'Étudiant' } }, entryPoints: entryPoint },
    { key: 6, from: 'f', slider: 4, text: 'Why is Rodan the worst?', perspectives: { Politicient: { text: 'Politicien' } } },
    { key: 1, from: 'aa', slider: 5, text: 'Who is Godzilla?', perspectives: { Professeur: { text: 'Professeur' } } },
    { key: 10, from: 'g', slider: 1, replyTo: 1, text: 'He is a very large lizard', perspectives: { Professeur: { text: 'Professeur' } } },
    { key: 2, from: 'b', slider: 2, text: 'Why are Godzilla and Kong fightning?', perspectives: { Parent: { text: 'Parent' } }, entryPoints: entryPoint },
    { key: 20, from: 'h', slider: 3, replyTo: 2, text: 'Because it is cool', perspectives: { Parent: { text: 'Parent' } } },
    { key: 21, from: 'i', slider: 4, replyTo: 2, text: 'They are mortal enemies', perspectives: { Etudiant: { text: 'Étudiant' } }, entryPoints: entryPoint },
    { key: 22, from: 'b', slider: 5, replyTo: 21, text: 'That is not true, they do not fight', perspectives: { Politicien: { text: 'Politicien' } } },
];

const mockGlobalParams = {
    entryTitle: "L’intelligence artificielle et son incidence sur nos vies",
    entryImage: makeAssetUrl("images/bubbles.png"),
    entryUrl: makeAssetUrl("https://google.com"),
};

const reactions = [
    {
        from: "f",
        key: "-ODmHH4Ikd-i_i-Aewk3",
        reactTo: "-3",
        reactionType: "Disagree",
        time: 1733862957405
    }
];

const globals = {
    name: "L’intelligence artificielle et son incidence sur nos vies",
    perspectives: 'Étudiant\nProfesseur\nPoliticien\nParent',
}

const perspectiveDemoFeatures = {
    aimoderation: true,
    commentslider: false,
    commentslider_enable_visualization: true,
    commentslider_horiz_visualization: true,
    commentslider_poll_picker: false,
    commentslider_slider: true,
    commentslider_vert_visualization: false,
    commentslider_yes: true,
    community_guidelines: true,
    config_comment: false,
    context_entry_points: true,
    conversationhelper: false,
    conversationhelper_guidance_update_comment_interval_0: true,
    conversationhelper_history: true,
    conversationhelper_personality_a: true,
    conversationhelper_reactions: true,
    conversationhelper_scope_all_comments: true,
    conversationhelper_scope_threshold_1: true,
    conversationhelper_summary: true,
    conversationhelper_timing_comments: true,
    conversationhelper_timing_comments_1: false,
    conversationhelper_timing_comments_2: true,
    conversationhelper_top_x_percent_3: true,
    embedded: false,
    hide_rejected: true,
    jigsawmoderation: true,
    lengthlimit: false,
    moderationhelp: true,
    newpublicreactions: true,
    openlinksinnewtab: true,
    pin_comment: true,
    premoderation: false,
    privatecommentfeedback: true,
    reactiontypes: true,
    replynotifications: true,
    report_comment: true,
    representing_perspectives: true,
    representing_perspectives_card: false,
    representing_perspectives_speech_bubble: false,
    representing_perspectives_subtle_bubble: true,
    single_response_help: true,
    translationfeature: false,
    votingoverview: false,
}


const commentSliderDemoFeatures = {
    aimoderation: true,
    commentslider: true,
    commentslider_agree: true,
    commentslider_enable_visualization: true,
    commentslider_horiz_visualization: true,
    commentslider_poll_picker: false,
    commentslider_slider: true,
    commentslider_vert_visualization: false,
    commentslider_yes: false,
    community_guidelines: true,
    config_comment: false,
    context_entry_points: true,
    conversationhelper: false,
    conversationhelper_guidance_update_comment_interval_0: true,
    conversationhelper_history: true,
    conversationhelper_personality_a: true,
    conversationhelper_reactions: true,
    conversationhelper_scope_all_comments: true,
    conversationhelper_scope_threshold_1: true,
    conversationhelper_summary: true,
    conversationhelper_timing_comments: true,
    conversationhelper_timing_comments_1: false,
    conversationhelper_timing_comments_2: true,
    conversationhelper_top_x_percent_3: true,
    embedded: false,
    hide_rejected: true,
    jigsawmoderation: true,
    lengthlimit: false,
    moderationhelp: true,
    newpublicreactions: true,
    openlinksinnewtab: true,
    pin_comment: true,
    premoderation: false,
    privatecommentfeedback: true,
    reactiontypes: true,
    replynotifications: true,
    report_comment: true,
    representing_perspectives: false,
    representing_perspectives_card: false,
    representing_perspectives_speech_bubble: false,
    representing_perspectives_subtle_bubble: true,
    single_response_help: true,
    translationfeature: false,
    votingoverview: false
}


export function RCConversationThreadWithPerspectiveDemo() {
    const collections = {
        comment: comments,
        persona: [...defaultPersonaList, {
            key: 'aa',
            name: 'John Doe',
            hue: 250,
            isAffiliatedToPSM: true,
            title: 'Comedian',
        },
        {
            key: 'bb',
            name: 'Jane Doe',
            hue: 250,
            isAffiliatedToPSM: true,
            title: 'Journalist',
        }],
        reactions: reactions,
    }

    return < ParamContext.Provider value={{ globalParams: { entryUrl: 'https://foo.com', entryTitle: 'Hello' } }}>
        <StructureDemo params={mockGlobalParams} features={perspectiveDemoFeatures}
            testState={{globals, collections, modulePublic: mockModulePublic, roles: ['Editorial']}}
            structureKey='question' language='french_canadian'
        />
    </ParamContext.Provider>
}


export function RCConversationThreadWithCommentSliderDemo() {
    const collections = {
        comment: comments,
        persona: [...defaultPersonaList, {
            key: 'aa',
            name: 'John Doe',
            hue: 250,
            isAffiliatedToPSM: true,
            title: 'Comedian',
        },
        {
            key: 'bb',
            name: 'Jane Doe',
            hue: 250,
            isAffiliatedToPSM: true,
            title: 'Journalist',
        }],
        reactions: reactions,
    }

    return < ParamContext.Provider value={{ globalParams: { entryUrl: 'https://foo.com', entryTitle: 'Hello' } }}>
        <StructureDemo params={mockGlobalParams} features={commentSliderDemoFeatures}
            testState={{globals, collections, modulePublic: mockModulePublic, roles: ['Owner']}}
            structureKey='question' language='french_canadian'
        />
    </ParamContext.Provider>
}
