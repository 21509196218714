import { ModDashboardUserProfile, ModDashboardUserProfileInformation } from "../../../component/zdf/moddashboard/moddashboarduserprofile";
import { CLICK, DemoSection, POPUP } from "../../../system/demo";
import { Datastore } from "../../../util/datastore";
import { modDashboardDemoData } from "./moddashboard.data";


export function ModDashboardUserProfileDemo() {
    return <DemoSection text={"User profile information"}>
        <Datastore testState={{
           serverCall: modDashboardDemoData.serverCalls
        }}>
            <ModDashboardUserProfileInformation userId={modDashboardDemoData.demoAuthors.a.key} />
        </Datastore>
        
    </DemoSection>
}



export function ModDashboardUserProfileStorySets () {
    return [
        {
            label: "Blockable user",
            serverCall: modDashboardDemoData.serverCalls,
            modulePublic: {
                moderation: {
                    queue: modDashboardDemoData.modTaskQueues.thread1
                }
            },
            content: <ModDashboardUserProfileInformation userId={modDashboardDemoData.demoAuthors.c.key}/>,
            stories: [
                {
                    label: "Block",
                    actions: [
                        CLICK("Block user"),
                        POPUP(CLICK("Block user"))
                    ],
                }
            ],
        },
        {
            label: "Blocked user",
            serverCall: modDashboardDemoData.serverCalls,
            modulePublic: {
                moderation: {
                    queue: modDashboardDemoData.modTaskQueues.thread1
                }
            },
            content: <ModDashboardUserProfileInformation userId={modDashboardDemoData.demoAuthors.d.key}/>,
            stories: [
                {
                    label: "Unblock",
                    actions: [
                        CLICK("Unblock user"),
                        POPUP(CLICK("Unblock user"))
                    ],
                }
            ],
        }
    ]
}