import { PadBox } from "component/basics.js"
import { StyleSheet, View } from "react-native";
import { useEffect, useState } from "react";
import { Picker, } from "react-native-web";
import { useDatastore } from "util/datastore.js"
import { UtilityText } from "component/text.js"

export const TranslationFeature = {
    name: 'Translation',
    key: 'translationfeature',
    dev: true,
    config: {
        pageTopWidgets: [LanguageDropdown],
        commentBelowWidgets: [TranslateRender]
    }
}

export function TranslateRender({comment, sourceLang, targetLang}) {
    const [translation, setTranslation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const datastore = useDatastore();

    const targetLanguage = datastore.getSessionData('selectedLanguage')

    const getTranslation = async () => {
        setLoading(true);
        setError(null);
        try {
            const result = await datastore.callServerAsync('translate', 'translate', {
                commentText: comment.text,
                target_language: targetLanguage
            })
            setTranslation(result.data);
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getTranslation();
    }, [sourceLang, targetLang, targetLanguage]);

    if (loading) return <UtilityText label='Loading...' />
    if (error) return <UtilityText label='Error occurred: {message}' formatParams={{message: error.message}} />

    return (
        <div>
            {translation ? <UtilityText text={translation} /> : <UtilityText label='No translation available' />}
        </div>
    );
}

export function LanguageDropdown() {
    const datastore = useDatastore();
    const [selectedValue, setSelectedValue] = useState('');

    const handleChange = (itemValue) => {
        setSelectedValue(itemValue);
        datastore.setSessionData('selectedLanguage', itemValue);
    };

    return (
        <PadBox horiz={20} top={20}>
            <View style={styles.dropdown}>
                <Picker
                    selectedValue={selectedValue}
                    onValueChange={handleChange}
                    style={styles.picker}
                >
                    <Picker.Item label="Select a language" value="" />
                    <Picker.Item label="fr" value="fr"/>
                    <Picker.Item label="us" value="us"/>
                    <Picker.Item label="it" value="it"/>
                    <Picker.Item label="de" value="de"/>
                </Picker>
            </View>
        </PadBox>
    );
}

const styles = StyleSheet.create({
    picker: {
        height: '44px',
        width: '200px',
        alignSelf: 'flex-start',
        cursor: 'pointer',
        borderRadius: '0.5rem',
        backgroundColor: 'rgba(0, 0, 0, 0)',
        border: '1px solid rgb(217, 217, 217)',
        color: 'rgb(0, 0, 0)',
        display: 'inline',
        listStyle: 'none',
        margin: '0px',
        padding: '0px',
        position: 'relative',
        textAlign: 'start'
    },
});