import { AddComment, Chat, ChevronLeft, Settings } from "@carbon/icons-react"
import { StyleSheet } from "react-native"
import { View } from "react-native-web"
import React, { useEffect } from "react"
import { ModDashboardQueueGeneral } from "../../component/zdf/moddashboard/moddashboardqueue"
import { ModDashboardUserProfile } from "../../component/zdf/moddashboard/moddashboarduserprofile"
import { ModDashboardThreadsOverview } from "../../component/zdf/moddashboard/moddashboardthreads"
import { useDatastore, useInstanceKey, usePersonaKey, usePersonaObject, useSessionData, useStructureKey } from "../../util/datastore"
import { HorizBox, HoverView, LoadingScreen, Pad, PadBox, WindowTitle } from "../../component/basics"
import { colorGreyBorder, colorGreyHover, colorGreyPopupBackground, colorRed, colorSecondaryPress, colorTextGrey } from "../../component/color"
import { generateRandomKey } from "../../util/util"
import { gotoInstance } from "../../util/navigate"
import { Heading, UtilityText } from "../../component/text"
import { SpacedArray } from "../../system/demo"
import { FaceImage, ProfilePhoto } from "../../component/people"
import { DropDownSelector, TextButton } from "../../component/button"
import { firebaseSignOut } from "../../util/firebase"
import { useConfig } from "../../util/features"
import { useInstanceParams } from "../../util/params"
import { useIsAdmin } from "../../component/admin"
import { Banner } from "../../component/banner"

export const ModDashboardStructure = {
    key: 'moddashboard',
    name: 'Moderation Dashboard',
    screen: ModDashboardHomeScreen,
    subscreens: {
    },
    defaultConfig: {
        openLinksInNewTab: true,
        moderationPages: [
            {
                id: "page-threads",
                label: "Threads",
                icon: AddComment,
                component: ModDashboardThreadsOverview,
            },
            {
                id: "page-comments",
                label: "Comments",
                icon: Chat,
                component: ModDashboardQueueGeneral
            },
            {
                id: "page-settings",
                label: "Settings",
                icon: Settings,
                component: ModerationSettingsPage
            }
        ],
        defaultModerationPage: {
            id: "page-comments",
            label: "Comments",
            icon: Chat,
            component: ModDashboardQueueGeneral
        }
    }
}

export function ModDashboardHomeScreen() {

    const config = useConfig();
    const params = useInstanceParams();
    const personaKey = usePersonaKey();
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();

    const CurrentModerationPage = config.moderationPages.find((page) => (page.id === params.pageId))?.component ?? config.defaultModerationPage?.component;
    const isLoggedIn = personaKey !== undefined && personaKey !== null;

    useEffect(()=>{
        if (!isLoggedIn) {
            datastore.gotoInstance({ structureKey: 'login', instanceKey: 'one' });
        }
    },[isLoggedIn])

    if(!isLoggedIn || !isAdmin) {
        return <Banner><UtilityText label='Only an admin can see this page'/></Banner>
    }

    function onClick(event) {
        // Top level click handler
        // Closes current mod task as long the click source is not from a modTask
        if (!event.isFromModTask) {
            datastore.setSessionData(['modTask-selected-idx'], null);
        }
        return false
    }

    return (
        <>
            <WindowTitle title={"Moderation Dashboard"} />
            <View style={{ flexDirection: "row", flexGrow: 1, width: "100%" }} onClick={onClick}>
                <ModDashboardSidebar initialPageIndex={"page-comments"} />
                <View style={{ flexGrow: 1, flexShrink: 1 }}>
                    <ModerationHeader />
                    <View style={{ height: "calc(100vh - 80px - 56px)", overflow: "hidden", backgroundColor: colorGreyPopupBackground }}>
                        {/* User profile can be opened from anywhere is layed on top of the current first level content */}
                        {params?.userId && <View style={{ overflow: "auto", height: "100%", paddingHorizontal: 104 }}>
                            <ModDashboardUserProfile key={params?.userId } userId={params?.userId } />
                        </View>
                        }
                        {/* When user profile is open hide the previous content, to not reset their state */}
                        <View style={{ overflow: "auto", height: "100%", visibility: params?.userId  ? "hidden" : "visible", position: params?.userId  ? "absolute" : "static", paddingHorizontal: 104 }}>
                            {CurrentModerationPage ? <CurrentModerationPage /> : <LoadingScreen />}
                        </View>
                    </View>
                </View>
            </View>
        </>
    );
}

const modDashboardSidebarStyle = StyleSheet.create({
    sideBar: {
        display: "flex",
        flexDirection: "column",
        margin: "0px",
        width: "fit-content",
        padding: 40,
        borderRightWidth: 1,
        borderRightColor: colorGreyBorder,
        borderRadius: 1,
        borderStyle: "solid",
        justifyContent: "space-between"
    },
});

export function ModDashboardSidebar() {
    const datastore = useDatastore();

    const personaKey = usePersonaKey();
    const config = useConfig();
    const persona = usePersonaObject(personaKey);
    const isLoggedIn = personaKey !== undefined && personaKey !== null;
    const params = useInstanceParams();
    const instanceKey = useInstanceKey()
    const pageId = params?.pageId ?? config.defaultModerationPage.id;

    const switchPage = (pageId) => {
        gotoInstance({ structureKey: "moddashboard", instanceKey, params: { pageId } })
    };

    const s = modDashboardSidebarStyle;

    return (
        <View style={s.sideBar}>
            <View>
                <Heading label={"PSI Moderation"} level={2} />
                <Pad size={40} />
                <SpacedArray pad={16}>
                    {
                        config.moderationPages.map((page, i) => (
                            <ModDashboardSidebarNavigationButton
                                key={"tab-" + i}
                                icon={page.icon}
                                label={page.label}
                                onPress={() => { switchPage(page.id) }}
                                selected={pageId === page.id}
                            />
                        ))
                    }
                </SpacedArray>
            </View>
            <HorizBox center>
                <ProfilePhoto type="extraLarge" userId={personaKey} />
                <PadBox horiz={8} />
                <View>
                    <UtilityText type="large" label={persona.name ?? "No user logged in"} />
                    {isLoggedIn ?
                        <TextButton color={colorTextGrey} type="tiny" label={"Log out"} onPress={() => { 
                            datastore.gotoInstance({ structureKey: 'login', instanceKey: 'one' });
                            firebaseSignOut();
                        }} />
                        : <TextButton color={colorTextGrey} type="tiny" label={"Log in"} onPress={() => { datastore.gotoInstance({ structureKey: 'login', instanceKey: 'one' }); }} />
                    }
                </View>
            </HorizBox>
        </View>
    );
}

function ModerationSettingsPage() {
    return <UtilityText label="Settings" />
}

const ModDashboardSidebarNavigationButtonStyle = StyleSheet.create({
    tile: {
        width: "220px",
        height: "48px",
        borderRadius: 32,
        paddingHorizontal: 12,
        userSelect: "none",
        justifyContent: "center"
    },
    hover: {
        backgroundColor: colorGreyHover
    },
    selected: {
        backgroundColor: colorGreyPopupBackground
    },
    press: {
        backgroundColor: colorSecondaryPress
    },
    notificationBubble: {
        backgroundColor: colorRed,
        width: "8px",
        height: "8px",
        borderRadius: "50%",
    },
});

function ModDashboardSidebarNavigationButton({ icon, label, onPress, showNotification = false, selected = false }) {

    const s = ModDashboardSidebarNavigationButtonStyle

    return (
        <View>
            <HoverView
                style={[s.tile, selected ? s.selected : null]}
                hoverStyle={s.hover}
                pressedStyle={s.press}
                onPress={onPress}
            >
                <View>
                    <HorizBox center>
                        {React.createElement(icon, { width: 30, height: 30 })}
                        <Pad size={8} />
                        <UtilityText center label={label} />
                        <Pad size={8} />
                        <View style={showNotification ? s.notificationBubble : null}></View>
                    </HorizBox>
                </View>
            </HoverView>
        </View>
    );
}

function ModerationHeader() {
    const datastore = useDatastore();
    const params = useInstanceParams()
    const subPageOpen = params?.threadId || params?.userId

    const backLabel = params?.userId ? "Back to comments" : "Back to threads"
    const structureKey = useStructureKey()
    const instanceKey = useInstanceKey();

    const goBack = () => {
        if (params?.userId) {
            params.userId = null;
            gotoInstance({ structureKey, instanceKey, params })
        } else {
            params.threadId = null;
            gotoInstance({ structureKey, instanceKey, params })
        }
    }

    return <View style={{ height: 80, borderBottomWidth: 1, borderColor: colorGreyBorder, justifyContent: "center", paddingLeft: 100, paddingRight: 40 }}>
        <HorizBox spread center>
            {subPageOpen ? <TextButton leftIcon={ChevronLeft} label={backLabel} color={colorTextGrey} type="tiny" onPress={goBack} /> : <View></View>}
        </HorizBox>
    </View>
}