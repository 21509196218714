import { ui_machine_translations_french_canadian } from './machinetranslations/ui_machine_french_canadian';
import { ui_machine_translations_french_belgian } from './machinetranslations/ui_machine_french_belgian';
import { ui_machine_translations_german } from './machinetranslations/ui_machine_german';
import { ui_machine_translations_french } from './machinetranslations/ui_machine_french';
import { ui_human_translations_french_canadian } from './humantranslations/ui_human_french_canadian';
import { ui_human_translations_french_belgian }  from './humantranslations/ui_human_french_belgian';
import { ui_human_translations_german } from './humantranslations/ui_human_german';
import { ui_human_translations_french } from './humantranslations/ui_human_french';

export var allTranslations = {
    french_canadian: {
        ...ui_machine_translations_french_canadian,
        ...ui_human_translations_french_canadian,
    },
    french_belgian: {
        ...ui_machine_translations_french_belgian,
        ...ui_human_translations_french_belgian,
    },
    german: {
        ...ui_machine_translations_german,
        ...ui_human_translations_german,
    },
    french: {
        ...ui_machine_translations_french,
        ...ui_human_translations_french,
    }

}

export function addTestTranslations(translations) {
    Object.keys(translations).forEach(language => {
        allTranslations[language] = {...allTranslations[language], ...translations[language]};
    });
}
