import { useRef, useEffect } from "react";

let registeredKeyboardShortcuts = [];

export const useKeyboardShortcut = ({shortcutKey, callback, index = 0}) => {
    const idRef = useRef(null);

    useEffect(() => {
        const maxId = registeredKeyboardShortcuts.length > 0
            ? Math.max(...registeredKeyboardShortcuts.map(shortcut => shortcut.id))
            : 0;
        const id = maxId + 1;
        idRef.current = id;
        registeredKeyboardShortcuts.push({ id, key: shortcutKey, callback, index });

        return () => {
            registeredKeyboardShortcuts = registeredKeyboardShortcuts.filter(shortcut => shortcut.id !== id);
        };
    }, [shortcutKey, callback]);

    return idRef.current;
}

export const KeyboardShortcutHandler = () => {
    const keyboardHandler = (e) => {
        let filteredShortcutsForKey = registeredKeyboardShortcuts.filter(shortcut => shortcut.key === e.keyCode);
        if(filteredShortcutsForKey.length === 0) {
            return;
        }
        console.log(filteredShortcutsForKey);
        const maxIndex = Math.max(...filteredShortcutsForKey.map(shortcut => shortcut.index));
        filteredShortcutsForKey = filteredShortcutsForKey.filter(shortcut => shortcut.index === maxIndex);
        if (filteredShortcutsForKey.length > 0) {
            filteredShortcutsForKey.forEach(shortcut => shortcut.callback(e));
        }
    }
    useEffect(() => {
        window.addEventListener('keydown', keyboardHandler);
        return () => window.removeEventListener('keydown', keyboardHandler);
    }, []);

    return <></>;
}