import { DropDownSelector } from "component/button.js"
import { Heading } from "component/text.js"
import { View } from "react-native"
import { useCollection, useDatastore, useSessionData } from "util/datastore.js"

const sortValues = {
    recent: 'recent',
    oldest: 'oldest',
    popular: 'popular'
}

export const CommentsSortFeature = {
    name: 'Comment Sorting Feature',
    key: 'commentssort',
    dev: true,
    config: {
        commentRankers: [{name: 'Boost by upVote', ranker: rankSelectedComment}],
        pageTopLeftWidgets: [CommentSorterTitle],
        pageTopRightWidgets: [CommentSorter]
    }
}

function rankSelectedComment({datastore, comments}) {
    const dropDownValue = datastore.getSessionData(['commentSortValue', 'value']);
    const upVotes = useCollection('upvote');
    let topComments,  otherComments;

    switch (dropDownValue) {
        case sortValues.recent:
            return [...comments.sort((x, y) => x.time - y.time).reverse()];
        case sortValues.oldest:
            return [...comments.sort((x, y) => x.time - y.time)];
        case sortValues.popular:
            topComments = comments.filter((comment) => upVotes.some((upVote) => upVote.comment === comment.key));
            otherComments = comments.filter((comment) => !upVotes.some((upVote) => upVote.comment === comment.key));
            return [...topComments, ...otherComments];
        default:
            return comments;
    }
}

export function CommentSorterTitle() {
    return <Heading label="All questions" />
}

export function CommentSorter() {
    const dropDownValue = useSessionData(['commentSortValue', 'value']);
    const datastore = useDatastore();
    const comments = useCollection('comment');

    if (comments.length == 0) {
        return null;
    }

    function handleDropDown(value) {
        datastore.setSessionData(['commentSortValue', 'value'], value);
    }

    return <View style={{alignSelf: 'flex-end'}}>
        <DropDownSelector label='Sort by'
        value={dropDownValue ?? sortValues.recent} onChange={handleDropDown}
        options={[
            {key: sortValues.recent, label: 'Most recent'},
            {key: sortValues.oldest, label: 'Oldest'},
            {key: sortValues.popular, label: 'Most popular'}
    ]} />
    </View>
}