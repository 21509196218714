import { StyleSheet, View, Image } from "react-native";
import { useEffect } from "react";
import { PadBox } from "component/basics.js";
import { UtilityText, WebLinkTextButton } from "component/text.js";
import { colorTextGrey } from "component/color.js";
import { Close } from '@carbon/icons-react';
import { useDatastore, useGlobalParams, useModulePublicData } from "util/datastore.js";
import { urlToKey } from "util/util";
import { IconButton } from "component/button";

export const ContextEntryPointsFeature = {
    name: 'Context Entry Points',
    key: 'context_entry_points',
    config: {
        composerTopWidgets: [ComposerEntryPointCard],
        commentMiddleWidgets: [PostedCommentEntryPointCard],
    }
}

export function ComposerEntryPointCard({comment, setComment}) {
    const {entryUrl} = useGlobalParams();
    if (comment.hideEntryPoint || !entryUrl) {
        return null;
    }

    function onClose() {
        setComment({ ...comment, hideEntryPoint: true });
    }

    return <PadBox bottom={40}><ContextEntryPointCard entryUrl={entryUrl} onClose={onClose} /></PadBox>
}

export function PostedCommentEntryPointCard({comment}) {
    const entryUrl = comment.entryUrl || comment.entryPoints?.[0].url;
    if (!entryUrl || comment.hideEntryPoint) return null;

    return <PadBox top={16}>
        <ContextEntryPointCard entryUrl={entryUrl} />
    </PadBox>
}

var activeConstructors = {};

function useArticleInfo({entryUrl}) {
    const articleKey = urlToKey(entryUrl);
    const structureKey = 'article';
    const datastore = useDatastore();
    let articleInfo = useModulePublicData('article', ['articleInfo', articleKey]);
    useEffect(() => {
        if (!articleInfo && !activeConstructors[articleKey]) {
            datastore.callServerAsync('constructor', 'runConstructor', {
                structureKey, instanceKey: articleKey
            });
            activeConstructors[articleKey] = true;
        }
    }, [articleInfo])
    return articleInfo
}

function ContextEntryPointCard({entryUrl, onClose}) {
    const s = ContextEntryPointCardStyle;
    const articleInfo = useArticleInfo({entryUrl});

    return <View>
        <PadBox top={onClose ? 16 : 8} />
        <View style={s.outerBox}>
            <Image source={{ uri: articleInfo?.image }} style={s.image} />
            <PadBox right={8} />
            <View style={s.textContainer}>
                <UtilityText type='tiny' weight='regular' label='Joined from' color={colorTextGrey} />
                <WebLinkTextButton url={entryUrl} underline={false} type='small' weight='medium' text={articleInfo?.title} numberOfLines={1} ellipsizeMode='tail' />
            </View>
            <PadBox right={8}/>
            {onClose && <IconButton icon={Close} onPress={onClose} ariaLabel='Remove Article' />}
        </View>
    </View>
}
const ContextEntryPointCardStyle = StyleSheet.create({
    outerBox: {
        width: '100%',
        borderRadius: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 36,
    },
    image: {
        height: 36,
        width: 36,
        borderRadius: 8,
    },
    textContainer: {
        flex: 1
    }
});

