import { View } from "react-native";
import { RelatedConversationsAdmin } from "../../structure/cbcrc/topic/topic";
import { useCollection, useDatastore } from "util/datastore.js"
import { useIsAdmin } from "component/admin.js"
import { CTAButton, SubtleButton } from "component/button.js"
import { PadBox } from "component/basics.js"
import { colorGreyBorder } from "component/color.js"
import { EmbeddedInstance } from "util/instance.js"
import { Add, ChevronRight } from '@carbon/icons-react';

export const QuestionCardsFeature = {
    key: 'questioncards',
    name: 'Question Cards',
    dev: true,
    subscreens: {
        relatedConversationsAdmin: () => <RelatedConversationsAdmin />,
    },
    config: {
        topicContentWidgets: [QuestionCards]
    },
}

export function QuestionCards() {
    const relatedConversations = useCollection('relatedConversations', { filter: { deleted: false } });
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();

    return <PadBox horiz={20}>
        {relatedConversations.map(question => <EmbeddedQuestion key={question.key} question={question} />)}
        {isAdmin && <PadBox top={12}><CTAButton compact type='secondary' label='Edit related conversations' onPress={datastore.needsLogin(() => datastore.pushSubscreen('relatedConversationsAdmin', {}))} /></PadBox>}
    </PadBox>
}

function EmbeddedQuestion({ question }) {
    const s = EmbeddedQuestionStyle;
    const datastore = useDatastore();
    return <View style={s.box}>
        <EmbeddedInstance structureKey='question' 
            instanceKey={question.key} 
            features={{embedded: true}} 
        />
        <PadBox horiz={20} vert={20}>
            <SubtleButton label='Show more' icon={ChevronRight}
                onPress={() => datastore.gotoInstance({structureKey: 'question', instanceKey: question.key})}
            />
        </PadBox>
    </View>
}

const EmbeddedQuestionStyle = {
    box: {
        borderRadius: 12,
        borderColor: colorGreyBorder,
        borderWidth: 1,
        marginTop: 32,
        overflow: 'hidden'
    }
}
