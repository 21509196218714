import { ConversationScreen, Pad, PadBox } from "component/basics"
import { TextButton } from "component/button"
import { AddQuestionBox, useGlobalQuestions } from "component/question"
import { Heading } from "component/text"
import { useState } from "react"
import { View } from "react-native"
import { useDatastore } from "util/datastore"

export const QuestionListFeature = {
    name: 'Question List',
    key: 'questionlist',
    subscreens: {
        questionList: QuestionListScreen,
    },
    config: {
        quickLinks: [
            {label: 'Questions', screenKey: 'questionList'},
        ]
    },
}

export function QuestionListScreen() {
    const [search, setSearch] = useState('');

    return <ConversationScreen pad>
        <Pad size={40} />
        <Heading type='large' label='Questions' />
        <Pad />
        <AddQuestionBox onChange={setSearch} />
        <Pad />
        <QuestionList search={search} />
        <Pad />
    </ConversationScreen>
}

function QuestionList({search}) {
    const cutoffDate = new Date('2025-1-1');
    const questions = useGlobalQuestions();
    const recentQuestions = questions?.filter(question => question.time > cutoffDate);
    const filteredQuestions = recentQuestions?.filter(question => !search || question.question?.toLowerCase().includes(search.toLowerCase()));
    return <View>
        {filteredQuestions.map(question => <PadBox key={question.key} vert={10}><Question question={question} /></PadBox>)}
    </View>
}

function Question({question}) {
    const datastore = useDatastore();
    function onPress() {
        datastore.gotoInstance({structureKey: 'question', instanceKey: question.key});
    }
    return <TextButton label={question.question} onPress={onPress}/>
}
