
export function WebLinkBase({url, newTab, style, setHover=()=>{}, children}) {
    const target = newTab ? '_blank' : '_parent';
    return <a href={url} target={target} style={style} rel='noreferrer'
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
    >
        {children}
    </a>;
}
