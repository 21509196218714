import { useDatastore, useModulePublicData } from "util/datastore.js"
import { useIsAdmin } from "component/admin.js"
import { useEffect, useMemo, useState } from "react";

export function useModerationQueue({options = {limit: 1000}} = {}) {
    const modObjs = useModulePublicData('moderation', ['queue'], options);

    // Use useMemo to prevent unnecessary recalculations if modObjs hasn't changed
    return useMemo(() => {
        const keys = Object.keys(modObjs || {});
        return keys.map(key => ({key, ...modObjs[key]})).sort((a, b) => b.time - a.time);
    }, [modObjs]); 
}

export function useModerationAuthors() {
    const queue = useModerationQueue();

    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const [modAuthors, setModAuthors] = useState({}); 

    async function fetchModerationAuthors() {
        const result = await datastore.callServerAsync('moderation', 'getModItemAuthors');
        setModAuthors(result);
    }

    useEffect(() => {
        if (isAdmin) {
            fetchModerationAuthors();
        }
    }, [isAdmin, queue.length]);
    return modAuthors;
}