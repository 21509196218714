import { DerivedAnswer, EditProfileAnswers, ProfileAnswersFeature, ViewProfileAnswers } from "../feature/profile/ProfileAswersFeature"
import { CLICK, DemoSection } from "system/demo.js"
import { ProfileModuleHolder, WithEditableFields } from "structure/profile.js"
import { Datastore } from "util/datastore.js"
import { logEventAsync } from "util/eventlog.js"
import { View } from "react-native-web"

export const ProfileAnswersDemo = {
    key: 'demo_profileanswers',
    name: 'Profile Answers',
    config: {
        componentSections: [
            {label: 'Profile', key: 'profile', pages: [
                {
                    designUrl: 'https://www.figma.com/design/iP7DEaY8SVqQcEGN6Feipj/Basic-Profile-%2B-Privacy-Settings?node-id=475-5263&', 
                    label: 'Profile Answers', key: 'profileanswers', 
                    screen: ProfileAnswersDemoScreen,
                    storySets: profileAnswersStorySets
                }
            ]}
        ],
    }
}

const collections = {
    derived_answer: [
        {
            question: 'Is Godzilla nice?', text: 'Godzilla is very nice', 
            time: 2000,
            structureKey: 'question', instanceKey: 'q1'

        },            
        {
            question: 'What is your favorite animal?', 
            text: 'I like Komodo Dragons. They are very friendly, but sometimes they eat you.', 
            time: 3000,
            structureKey: 'question', instanceKey: 'q2'
        },            

    ]
}

function ProfileAnswersDemoScreen() {
    return <View>
        <DemoSection text='Derived Answer'>
            <Datastore instanceKey='a'>
            <DerivedAnswer answer={{
                instanceKey: 'a', question: 'What is your favorite color?', 
                text: 'My favorite color is Blue', time: 1000
            }} />
            <DerivedAnswer answer={{
                instanceKey: 'a', question: 'What is the rudest word you know?', 
                inReview: true, violates: true,
                text: 'Rude text that is in review', time: 2000
            }} />
            <DerivedAnswer answer={{
                instanceKey: 'a', question: 'What is your opinion of Godzilla?', 
                violates: true,
                text: 'Rude text that got rejected', time: 2000
            }} />
             </Datastore>
        </DemoSection>
        <DemoSection text='View Profile Answers'>
            <Datastore instanceKey='a' testState={{collections}} >
                <ViewProfileAnswers />    
            </Datastore>
            <Datastore instanceKey='b' testState={{collections, globals: {fields: {answersVisible: false}}}} >
                <ViewProfileAnswers />    
            </Datastore>
            <Datastore instanceKey='b' testState={{roles:['Owner'], collections, globals: {fields: {answersVisible: false}}}} >
                <ViewProfileAnswers />    
            </Datastore>
        </DemoSection>
    </View>
}

function profileAnswersStorySets() {return [
    {
        label: 'Edit Profile Answers',
        instanceKey: 'a', personaKey: 'a',
        collections,
        content: <ProfileModuleHolder module={ProfileAnswersFeature.config.profileModules[0]} />,
        serverCall: {profile: {
            update: ({datastore, updates, preview}) => {
                datastore.updateGlobalProperty('fields', updates);
                datastore.updateObject('persona', 'a', preview);
            },
        }, eventlog: {
            logEvent: () => {}
        }},
        stories: [
            {label: 'Hide Answers', actions: [
                CLICK('Edit {tLabel}'), CLICK('Visible to everyone'), CLICK('Save')
            ]},
            {label: 'Hide & Show Answers', actions: [
                CLICK('Edit {tLabel}'), CLICK('Visible to everyone'), CLICK('Save'),
                CLICK('Edit {tLabel}'), CLICK('Visible to everyone'), CLICK('Save'),                
            ]},
        ]
    }
]}
