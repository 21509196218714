import React, { useContext } from "react";
import { useDatastore } from "../../util/datastore";

/** 
 * Simple Context Provider, that passes the own datastore through
 * Used to still have access to the parent datastore in case embedded instances are utilized
 **/ 
const ParentDatastoreContext = React.createContext(null);
export function ParentDatastoreProvider({children}) {
    // Simply pass your own datastore
    const datastore = useDatastore()
    return <ParentDatastoreContext.Provider value={datastore}>
        {children}
    </ParentDatastoreContext.Provider>
}

export function useParentDatastore() {
    const ownDatastore = useDatastore();
    const parentDatastore = useContext(ParentDatastoreContext)
    // Return the parent datastore, if not available your own
    return parentDatastore ?? ownDatastore
}