import { DataVizText } from "component/text.js";
import { View } from "react-native";
import { useState } from "react";
import { ColoredBallPill } from "./colorpill";
import { Tag, TextButton } from "component/button.js";
import { Center, HorizBox, Pad } from "component/basics.js";

import { TextField, UtilityText } from "component/text.js";
import { CTAButton } from "component/button.js";
import { StyleSheet } from "react-native";
import { HoverView, PadBox } from "component/basics.js";
import { ColoredBall } from "component/colorpill";
import { Checkmark } from "@carbon/icons-react";
import { colorBarBackground, colorBlack, colorDataVizRegular, colorDataVizStrong, colorGreyBorder, colorGreyPopupBackground, colorPurpleBackground, colorWhite } from "./color";


export function PollSurveyThenResults({
    question, optionLabels, optionTexts, optionColors, onVote, votes, 
    myVote, myComment = null, showOtherOption = false, showButton, 
    otherOptionLabel, otherOptionText, otherOptionPlaceholder = null 
}) {
    const s = PollSurveyThenResultsStyle;
    const votedForOther = myVote && myVote === (otherOptionLabel ?? otherOptionText);

    return <View style={s.pollWrap}>
        <DataVizText type='heading1' text={question || ''} />
        <View style={s.subtitle}>
            <DataVizText type='heading2' label={myVote ? 'Your response' : 'Select one option'} />
        </View>
        {!myVote && <PollSurvey 
            optionLabels={optionLabels} optionTexts={optionTexts} optionColors={optionColors} 
            myVote={myVote} myComment={myComment} 
            showButton={showButton} onVote={onVote} 
            showOtherOption={showOtherOption} otherOptionLabel={otherOptionLabel} 
            otherOptionText={otherOptionText} otherOptionPlaceholder={otherOptionPlaceholder}
        />}
        {myVote && !votedForOther && <PollResult 
            optionLabels={optionLabels} optionTexts={optionTexts} votes={votes} myVote={myVote} 
        />}
        {myVote && votedForOther && <PollTextResult 
            myVote={myVote} comment={myComment} 
        />}
        {myVote && <Center><CTAButton wide label='Change my vote' onPress={() => onVote(null)} /></Center>}
    </View>
}
const PollSurveyThenResultsStyle = StyleSheet.create({
    pollWrap: {
        backgroundColor: colorGreyPopupBackground,
        padding: '20px',
        borderRadius: '12px',
    },
    subtitle: {
        marginTop: 24,
        marginBottom: 12
    },
})

export function PollPicker({value, optionLabels, optionTexts, optionColors, placeholder, onChangeValue}) {
    const [expanded, setExpanded] = useState(!value);
    const options = optionLabels ?? optionTexts;

    function onVote(option) {
        const value = options.indexOf(option) + 1;
        setExpanded(false);
        onChangeValue(value)
    }

    if (expanded) {
        return <View>
            <DataVizText type='heading2' label={placeholder} />
            <PollSurvey optionLabels={optionLabels} optionTexts={optionTexts} optionColors={optionColors} myVote={value} showButton={false} onVote={onVote} />
        </View>
    } else {
        return <HorizBox center>
            <ColoredBallPill label={options[value - 1]} color={optionColors[value - 1]} />
            <Pad size={10} />
            <TextButton type='tiny' label='Change' onPress={() => setExpanded(true)} />
        </HorizBox>
    }
}


export function PollSurvey({ 
    optionLabels, optionTexts, optionColors, myVote, myComment=null, showButton = true, onVote,
    showOtherOption = false, otherOptionLabel, otherOptionText, otherOptionPlaceholder = 'Placeholder value'
}) {
    const s = PollSurveyStyle;
    const options = optionLabels ?? optionTexts;
    const [selectedAnswer, setSelectedAnswer] = useState(myVote);
    const [textfieldValue, setTextfieldValue] = useState(myComment);

    const otherOptionValue = otherOptionLabel ?? otherOptionText;

    function onSelect(option) {
        setSelectedAnswer(option)
        if (!showButton) {
            onSubmit(option)
        }
    }

    function onSubmit(option) {
        const isOther = option === otherOptionValue;
        onVote(option, isOther ? textfieldValue : null);
    }

    return <View style={[s.poll]} >
        <View style={[s.listWrapSurvey]} role="list">
            {options.map((option, idx) =>
                <PollOptionButton key={option}
                    selected={selectedAnswer === option}
                    label={optionLabels?.[idx]} text={optionTexts?.[idx]}
                    color={optionColors?.[idx]} onPress={() => onSelect(option)}
                />
            )}
            {showOtherOption && <View>
                <PollOptionButton 
                    selected={selectedAnswer === otherOptionValue}
                    label={otherOptionLabel} text={otherOptionText}
                    color={optionColors?.[idx]} onPress={() => onSelect(otherOptionValue)} 
                />
                {(selectedAnswer === otherOptionValue) && <TextField 
                    value={textfieldValue} onChange={setTextfieldValue} 
                    placeholder={otherOptionPlaceholder} 
                    testID='other-option'
                />}
            </View>}
        </View>
        {showButton &&
            <View style={[s.buttonWrap]}>
                <CTAButton disabled={(!selectedAnswer)} wide label='Submit my vote' onPress={() => onSubmit(selectedAnswer)} />
            </View>
        }
    </View>
}
const PollSurveyStyle = StyleSheet.create({
    buttonWrap: {
        width: 167
    },
    listWrapSurvey: {
        paddingVertical: 15,
        borderRadius: 8,
        width: '100%',
    },
    poll: {
        alignItems: 'center',
    }
})


function PollOptionButton({ label, text, color, onPress, selected = false, styledNextTopic = false }) {
    const s = PollOptionButtonStyle;

    return <View role="listitem">
        <HoverView role='button' style={[styledNextTopic ? s.buttonNextTopic : s.button]}
            hoverStyle={s.hover} testID={label ?? text} ariaLabel={label} ariaText={text}
            onPress={onPress}>
            {color && <PadBox right={8}><ColoredBall color={color} /></PadBox>}
            <View style={s.body}>
                <UtilityText label={label} text={text} />
            </View>
            <View style={[s.rightIcon, selected ? s.iconSelected : '']}>
                <Checkmark color='white' />
            </View>
        </HoverView>
    </View>
}
const PollOptionButtonStyle = StyleSheet.create({
    button: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
        alignItems: 'center',
        paddingRight: 10,
        paddingLeft: 20,
        paddingVertical: 10,
        marginBottom: 12,
        width: "100%",
        borderRadius: 100,
        borderWidth: 1,
        backgroundColor: colorWhite,
        borderColor: colorGreyBorder,
    },

    buttonNextTopic: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
        alignItems: 'center',
        paddingRight: 10,
        paddingLeft: 20,
        paddingVertical: 10,
        width: "100%",
        borderTopWidth: 1,
        borderColor: colorGreyBorder,
    },

    hover: {
        borderColor: colorBlack,
    },
    body: {
        flex: 1
    },
    rightIcon: {
        marginLeft: 10,
        borderColor: colorGreyBorder,
        borderRadius: 100,
        borderWidth: 1,
        padding: 4
    },
    iconSelected: {
        backgroundColor: colorBlack,
        borderColor: colorBlack
    }
})

export function PollResult({ optionLabels, optionTexts, votes, myVote }) {
    const s = PollResultStyle;
    const options = optionLabels ?? optionTexts;

    return <View style={s.poll}>
        <View style={s.listWrapResult} role='list'>
            {options.map(option =>
                <PollOptionPercentBar
                    percent={getPercentAnswer(option, votes)}
                    isMine={myVote === option}
                    label={optionLabels ? option : null}
                    text={optionTexts ? option : null}
                    key={option}
                />
            )}
        </View>
    </View>
}
const PollResultStyle = StyleSheet.create({
    poll: {
        alignItems: 'center',
    },
    listWrapResult: {
        paddingHorizontal: 12,
        paddingTop: 16,
        paddingBottom: 4,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: colorGreyBorder,
        backgroundColor: colorGreyPopupBackground,
        width: '100%',
        marginBottom: 24
    },
})

function getPercentAnswer(answer, votes) {
    const sameVote = votes.filter(vote => {
        return vote === answer
    })
    const percent = Math.round((sameVote.length * 100) / votes.length)
    return percent;
}

function PollOptionPercentBar({ label, text, percent = 0, isMine = true }) {
    const s = PollOptionPercentBarStyle;

    return <View style={s.container}>
        <View style={s.bar}>
            <View style={[s.filledBar, isMine ? s.myBar : s.otherBar, {flex: percent}]} />
            <View style={{flex: 100 - percent}} />
        </View>
        <View style={[s.barLabels, percent == 0 ? {paddingLeft: 0} : null]}>
            <UtilityText type='tiny' label={label} text={text} color={colorBlack} />
            <HorizBox center>
                <View style={[s.tag, !isMine ? {display: 'none'} : null]}>
                    <Tag label='You' type='tiny' strong overrideTinyTextColor color={colorBarBackground} />
                </View>
                <UtilityText type='small' weight="medium" text={percent + '%'} color={colorBlack} />
            </HorizBox>
        </View>
    </View>
}
const PollOptionPercentBarStyle = StyleSheet.create({
    container: {
        height: 36,
        marginBottom: 12,
        width: '100%',
    },
    tag: {
        marginRight: 8
    },
    barLabels: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 8
    },
    bar: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 88,
        bottom: 0,
        flexDirection: 'row',
        alignItems: 'center',
    },
    filledBar: {
        height: 36,
        borderRadius: 4,
    },
    myBar: {
        backgroundColor: colorBarBackground,
    },
    otherBar: {
        backgroundColor: colorDataVizRegular,
    }
})


const PollDataStyles = (isMine, percent) => StyleSheet.create({
    container: {
        marginBottom: 8,
        width: "100%",
    },
    rowContainer: {
        flexDirection: 'row',
        marginBottom: 4,
        marginTop: 10,
        width: "100%",
        alignItems: 'center'
    },
    bar: {
        backgroundColor: isMine ? colorDataVizStrong : colorDataVizRegular,
        height: 36,
        borderRadius: 4,
        marginRight: 8,
        flex: percent,
        // width: percent+'%',
    },
    tag: {
        marginLeft: 8,
        display: isMine ? 'block' : 'none'
    },
    percentLabel: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 94,
        flexGrow: 0,
        flexShrink: 0
    }
})


export function PollTextResult({ myVote, comment }) {
    const s = PollTextResultStyle;
    return <View style={s.poll}>
        <View style={s.textResultWrap} >
            <PollOptionButton
                selected
                label={comment ?? myVote}
            />
        </View>
    </View>
}
const PollTextResultStyle = StyleSheet.create({
    poll: {
        alignItems: 'center',
    },
    textResultWrap: {
        paddingHorizontal: 20,
        paddingTop: 20,
        paddingBottom: 10,
        borderRadius: 8,
        backgroundColor: colorGreyPopupBackground,
        width: '100%',
        marginBottom: 24
    },
});