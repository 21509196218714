import React from 'react';
import { useState } from 'react';
import { signInWithGoogle, getFirebaseDataAsync, signInWithTokenAsync } from '../util/firebase';
import { ConversationScreen, LoadingScreen, Narrow, Pad, PadBox, WindowTitle } from '../component/basics';
import { Image, StyleSheet, View } from 'react-native';
import { useDatastore, useModulePublicData, usePersonaKey, useSiloKey } from '../util/datastore';
import { Heading, UtilityText } from '../component/text';
import { RichText } from '../component/richtext';
import { colorPinkBackground, colorTealBackground, colorWhite, colorBlack, colorBlackHover, colorSecondaryJasper } from '../component/color';
import { CTAButton } from '../component/button';
import { assembleUrl, boolToString, makeAssetUrl, makeRandomNonce } from '../util/util';
import { logEventAsync, useLogEvent } from '../util/eventlog';
import { FirstLoginSetup } from '../feature/profile/ProfilePhotoAndName';
import { useEffect } from 'react';
import { getIsLocalhost } from '../util/util';
import { BannerMessage } from '../component/banner';
import { callServerApiAsync } from 'system/servercall';
import { silosWithoutGoogleLogin } from 'util/loginproviders';
import { getScreenStackForUrl } from 'util/navigate';
import { getSSOParentLoginToken } from 'platform-specific/ssoinjection';

export const LoginStructure = {
    key: 'login',
    name: 'Login',
    screen: LoginScreen,   
    subscreens: {
        tokenRedirect: TokenRedirectScreen,
        fragmentRedirect: FragmentRedirectScreen,
        fragmentredirect: <UtilityText label='BUG: Lowercase URL transform'/>,
    }
}


export function LoginScreen({ action }) {
    useLogEvent('login-screen', { action });
    const datastore = useDatastore();
    const personaKey = usePersonaKey();

    useEffect(() => {
        if (personaKey) {
            datastore.goBack();
        }
    }, [personaKey]);

    useEffect(() => {
        if(getSSOParentLoginToken()) {
        // if the token is invalid or expired, it will throw an error
        try {
            datastore.signInWithTokenAsync(getSSOParentLoginToken());
        } catch (error) {  
            console.log(error);
        }
    }
    }, [datastore]);

    return <UnauthenticatedLoginScreen action={action} />;
};

export function OnboardingScreen() {
    const datastore = useDatastore();
    async function onFieldsChosen({updates, preview}) {
        await datastore.callServerAsync('profile', 'update', {
            updates, preview,
            structureKey: 'profile',
            instanceKey: datastore.getPersonaKey(),
        });
        logEventAsync(datastore, 'profile-setup', preview);
    }

    return <ConversationScreen>
        <PadBox horiz={20} vert={20}>
            <FirstLoginSetup onFieldsChosen={onFieldsChosen} />
        </PadBox>
    </ConversationScreen>
}

export function UnauthenticatedLoginScreen({action}) {
    const s = UnauthenticatedLoginScreenStyle;
    const [bubbleHeight, setBubbleHeight] = useState(0);
    const siloKey = useSiloKey();
    const showGoogle = silosWithoutGoogleLogin.indexOf(siloKey) == -1;
    return <ConversationScreen backgroundColor={colorPinkBackground}>
        <WindowTitle title={'Join the discussion' + (action ? ' to ' + action : '')} />
        <View style={s.outer}>
            <Pad size={40} />        
            <Heading type="large" weight="medium" center label={'Join the discussion' + (action ? ' to ' + action : '')} />
            <Pad size={4}/>
            <View style={s.subHeadWrapper}>
                <UtilityText center label={"Once you log in, enter a display name"} />
            </View>
            <Pad size={52} />
            <View style={s.imageWrapper}>
                <View style={[s.bubble, { top: -(bubbleHeight / 2) }]}
                onLayout={(event) => setBubbleHeight(event.nativeEvent.layout.height)}>
                    <PadBox>
                    <UtilityText weight='medium' center color={colorWhite} label={'Add your display name'} />
                    </PadBox>
                </View> 
                <Image source={{ uri: makeAssetUrl("images/set_visibility_image.png") }} style={{ width: 250, height: 160 }} />
            </View>
            <Pad size={32} />
            <View style={s.loginButtonsWrapper}>
                {showGoogle && <GoogleLogin />}
                {loginProviders.map(loginProvider => 
                    <SSOLogin key={loginProvider.key} loginProvider={loginProvider} />
                )}
            </View>
            <Pad size={32} />
            <PadBox horiz={20} bottom={20}>
                <CookieNotice />
            </PadBox>
        </View>
    </ConversationScreen>
}

const UnauthenticatedLoginScreenStyle = StyleSheet.create({
    outer: {
        backgroundColor: colorPinkBackground,
        flex: 1,
        alignItems: 'center',
    },
    subHeadWrapper: {
        maxWidth: 400,
        minWidth: 330,  
    },
    imageWrapper: {
        position: 'relative',
        alignItems: 'center',
    },
    loginButtonsWrapper: {
        alignItems: 'center',
    },
    bubble: {
        position: 'absolute',
        maxWidth: 210,
        minHeight: 30,
        borderRadius: 32,
        backgroundColor: colorTealBackground,
        top: -20,
        zIndex: 1,
        borderColor: colorPinkBackground,
        borderWidth: 1.5,
        paddingHorizontal: 12,
        alignItems: 'center',
        justifyContent: 'center',
    },
    footer: {
        maxWidth: 400,
        minWidth: 375,
    }
});


var loginProviders = [];
export function registerLoginProviders(providers) {
    loginProviders = [...loginProviders, ...providers];
}


function GoogleLogin() {
    const datastore = useDatastore();

    async function handleGoogleSignIn() {
        logEventAsync(datastore, 'login-request', { method: 'google' });
        try {
            const result = await signInWithGoogle();
            logEventAsync(datastore, 'login-success', {
                method: 'google',
                email: result?.user?.email ?? 'unknown',
            });
        } catch (error) {
            console.error(error);
        }
    };

    return <PadBox><CTAButton
        icon={<LoginProviderIcon providerIcon='google.png' />}
        label='Continue with Google' color={colorBlack} onPress={handleGoogleSignIn} 
    /></PadBox>
}

function makeStateChunk({siloKey, provider, debug}) {
    return siloKey + '-' + provider + '-' + boolToString(debug);
}

function parseStateChunk(chunk) {
    const parts = chunk.split('-');
    return {
        siloKey: parts[0],
        provider: parts[1],
        debug: parts[2] == 'true',
    };
}

// TODO: Actually check the nonce on the callback
export function SSOLogin({loginProvider}) {
    const datastore = useDatastore();
    const siloKey = useSiloKey();
    if (!loginProvider.silos.includes(siloKey)) {
        return null;
    }

    function onLogin() {
        logEventAsync(datastore, 'login-request', { method: loginProvider.key });

        const codeCallbackUrl = 'https://psi.newpublic.org/api/auth/callback'
        const fragmentRedirectUrl = 'https://psi.newpublic.org/' + siloKey + '/login/one/fragmentRedirect';
        const redirect_uri = loginProvider.mode == 'code' ? codeCallbackUrl : fragmentRedirectUrl;
        const nonce = makeRandomNonce();

        const stateChunk = makeStateChunk({ siloKey, provider: loginProvider.key, debug: getIsLocalhost() });
        const loginUrl = assembleUrl(loginProvider.authUrl, { 
            state: stateChunk,
            nonce,
            client_id: loginProvider.clientId,
            redirect_uri,
            scope: loginProvider.scope,
            ... loginProvider.extraParams
        });
        
        datastore.openUrl(loginUrl, 'Login with ' + loginProvider.name, 'width=600,height=600');
    }

    return <PadBox top={20}>
        <CTAButton type='secondary' onPress={onLogin} 
            icon={<LoginProviderIcon providerIcon={loginProvider.icon} />}
            label={'Continue with ' + loginProvider.name}
        />
    </PadBox>            
}

export function TokenRedirectScreen({ token, provider, email }) {
    const datastore = useDatastore();
    async function handleToken() {
        await signInWithTokenAsync(token);
        logEventAsync(datastore, 'login-success', { provider, email });

        datastore.closeWindow();
    }
    useEffect(() => {
        handleToken(token);
    }, [token]);
    return <UtilityText label='Logging in...' />
}

function getFragmentParams(fragment) {
    const fragmentContent = fragment.startsWith('#') ? fragment.substring(1) : fragment;
    const params = new URLSearchParams(fragmentContent);
    return Object.fromEntries(params.entries());
}

export function FragmentRedirectScreen() {
    const datastore = useDatastore();
    const siloKey = useSiloKey();
    const fragment = datastore.getUrlFragment();

    async function loginWithTokenFragment() {
        const fragmentParams = getFragmentParams(fragment);
        const ssoToken = fragmentParams.id_token;
        const state = parseStateChunk(fragmentParams.state);
        const provider = state.provider;
        window?.opener?.postMessage({type: 'psi-login', ssoToken, provider}, '*');
        const {loginToken} = await datastore.callServerAsync('auth', 'convertToken', { ssoToken, provider });
        await datastore.signInWithTokenAsync(loginToken);
        if (state.siloKey != 'test') {
            datastore.closeWindow();
        }
    }

    useEffect(() => {
        loginWithTokenFragment();
    }, [fragment])

    return <BannerMessage label='Logging in...'/>
}

function LoginProviderIcon({providerIcon}) {
    return <Image source={{ uri: makeAssetUrl(`images/${providerIcon}`) }} style={{ width: 20, height: 20 }} />
}

function CookieNotice() {
    const s = CookieNoticeStyle;

    const siloKey = useSiloKey();
    const defaultPrivacyNoticeLink = 'https://psi.newpublic.org/pages/' + siloKey + '/privacy-notice.html';
    const privacyNoticeLink = useModulePublicData('admin', ['links', 'privacyNotice']) ?? defaultPrivacyNoticeLink;

    return <View style={s.container}> 
        <RichText label='🍪 This discussion space uses only essential cookies. We do not use cookies for advertising purposes. [Learn more]({privacyNoticeLink}).'
            formatParams={{privacyNoticeLink}}
         />
    </View>
}
const CookieNoticeStyle = StyleSheet.create({
    container: {
        backgroundColor: colorSecondaryJasper,
        padding: 20,
        borderRadius: 8,
    }
})

window.addEventListener('message', async (event) => {
    // console.log('top level message', event);
    if (event.data?.type == 'psi-login') {
        console.log('top level signing in');
        const {ssoToken, provider} = event.data;
        const {loginToken} = await callServerApiAsync({component: 'auth', funcname: 'convertToken', params: { ssoToken, provider }}); 
        signInWithTokenAsync(loginToken);
        // await datastore.signInWithTokenAsync(loginToken);
        console.log('top level signed in');
    }
});

