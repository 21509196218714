import { getIsInAndroid, getIsInIOS } from "./util";

export const requestAppLogin = () => {
    sendMessageToContainer('login');
}

export const requestAppClosePanel = () => {
    sendMessageToContainer('closePanel');
}

export const requestAppNavigateToExternalUrl = (url) => {
    sendMessageToContainer('navigateTo', url);
}

export function sendMessageToContainer(action, params) {
    if (getIsInIOS(window)) {
        window.webkit?.messageHandlers?.[action].postMessage(params);
    } else if (getIsInAndroid(window)) {
        window.Android?.[action](params)
    }
}
