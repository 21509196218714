import React, { useEffect } from 'react';
import { newStructures } from './structure/index';
import { closeSidebar, useLiveUrl } from 'util/navigate.js'
import { getScreenStackForUrl, gotoInstance } from 'util/navigate.js'
import { addStructures } from 'structure/index.js'
import { ScreenStack, getStructureForKey, useStandardFonts } from 'util/instance.js'
import { addDefaultFeatures, addFeatures } from 'feature/index.js'
import { newDefaultFeatures, newFeatures, newRoles } from './feature';
import { addEventTypes } from 'util/eventlog.js'
import { setFirebaseConfig } from 'util/firebase.js'
import { extendRoles } from 'component/admin.js'
import { getIsLocalhost } from 'util/util.js'
import { Text } from 'react-native';
import { registerLoginProviders } from 'structure/login.js'
import { cbcIntLogin, cbcLogin, rcIntLogin, rcLogin, rtbfLogin, zdfIntLogin } from 'util/loginproviders.js'
import useSSOLoginFromParent from './util/useSSOLoginFromParent';
import { FocusLoopView } from 'component/focustrap';
import { KeyboardShortcutHandler } from 'platform-specific/keyboardShortcutHandler';

const defaultFirebaseConfig = {
    apiKey: "AIzaSyDIg3OR3i51VYrUyUd_L5iIownjdSnExlc",
    authDomain: "np-psi-dev.firebaseapp.com",
    databaseURL: "https://np-psi-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "np-psi-dev",
    storageBucket: "np-psi-dev.appspot.com",
    messagingSenderId: "768032889623",
    appId: "1:768032889623:web:634a1604eda03820ab7552"
};

try {
    const config = require('./production-config');
    setFirebaseConfig(config.firebaseConfig);
    console.log('Using production config');
} catch (e) {
    console.warn('No production config. Using default.');
    console.warn('See https://github.com/wearenewpublic/psi-product/blob/main/docs/deploy.md for more information.')
    setFirebaseConfig(defaultFirebaseConfig);
}

addFeatures(newFeatures);
addDefaultFeatures(newDefaultFeatures);
extendRoles(newRoles);
registerLoginProviders([rcIntLogin, rcLogin, cbcLogin, cbcIntLogin, zdfIntLogin, rtbfLogin]);

export default function App() {
    useStandardFonts();
    const url = useLiveUrl();
    const {siloKey, structureKey, instanceKey, screenStack} = getScreenStackForUrl(url);
    const structure = getStructureForKey(structureKey);

    console.log('App', { url, siloKey, structureKey, instanceKey, screenStack, structure });
    
    useSSOLoginFromParent(siloKey);
    useEffect(() => {
        if (getIsLocalhost() && !structureKey) {
            gotoInstance({structureKey: 'admin', instanceKey: 'one'});
        }
    }, [url]);

    if (!structureKey) {
        return <Error message='You need a valid space URL to see a space' />
    } else if (!structure) {
        return <Error message={'Unknown structure: ' + structureKey} />
    } else if (!instanceKey) {
        return <Error message='Missing instance key' />
    } else {
        if (structure.hasFocusTrap) {
            return <FocusLoopView onCloseLoop={closeSidebar} index={0}>
                <ScreenStack url={url} screenStack={screenStack} siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey} />
                <KeyboardShortcutHandler />
            </FocusLoopView>;
        }
        else return <ScreenStack url={url} screenStack={screenStack} siloKey={siloKey} structureKey={structureKey} instanceKey={instanceKey} />;

    }
}

function Error({message}) {
    return <Text>{message}</Text>
}
