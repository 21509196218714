import { CLICK, POPUP } from "system/demo.js"
import { CommunityGuidelines } from "../feature/question/CommunityGuidelinesFeature";

export function communityGuidelinesStorySets() {
    return [
        {
            label: 'Close Guidelines',
            content: <CommunityGuidelines />,
            stories: [
                {
                    label: 'Open and Close Guidelines', actions: [
                        CLICK('Community Guidelines'),
                        POPUP(CLICK('close-modal'))
                    ]}
            ]
        },    
    ];
}