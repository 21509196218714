import { View } from "react-native";

import { Datastore } from "util/datastore.js";
import { STATIC_TOPIC } from '../../structure/cbcrc/topic/static';
import { Pad } from "component/basics";
import { DemoSection } from "system/demo.js";
import { RecirculationSlider } from "../../component/cbcrc/recirculationCards";
import { PollScreen, PollWidgetScreen, pollWidgetStories as pollWidgetStorySets } from './polldemo';
import { TopicStructureFullDemo } from "./topicstructuredemo";
import { TopicTeaserScreen } from "./topicteaserdemo";
import { Byline } from "component/people";
import { Badge } from "../../component/cbcrc/badge";
import { REPLACE_CBC_Tag } from "../../component/cbcrc/tag";
import { colorBlack, colorPink } from "component/color";
import { AskMeAnythingDemo } from "./askmeanythingstructuredemo";
import { RCConversationThreadWithCommentSliderDemo, RCConversationThreadWithPerspectiveDemo } from "./rcconversationthreaddemo";


export const CBCRCDemoFeature = {
    key: 'demo_cbcrc',
    name: 'Demo CBCRC',
    config: {
        componentSections: [
            {
                label: 'CBC-RC Components', key: 'cbcrc', pages: [
                    { label: 'Poll', key: 'poll', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6311-22005&t=2tD3gEuK5FbZOslE-0', screen: PollScreen },
                    { label: 'PollWidget', key: 'pollWidget', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6311-22005&t=2tD3gEuK5FbZOslE-0', storySets: pollWidgetStorySets },
                    { label: 'Titled Writer', key: 'titledWriter', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=3-32&node-type=canvas&t=EGaU0LgfaowklmF5-0', screen: TitledWriterScreen },
                    { label: 'Recirculation Slider / Cards', key: 'recirculation', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6312-22717&t=sVDGhoOkO4He5usT-0', screen: RecirculationScreen, },
                    { label: 'Topic Teaser', key: 'topicTeaser', designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6376-16667&node-type=FRAME&t=Qi3NQID2OPtk4FfL-0', screen: TopicTeaserScreen }
                ]
            }
        ],
        structureSections: [
            {
                label: 'CBC-RC Structures', key: 'cbcstruct', pages: [
                    { label: 'Topic of the moment', key: 'topicofthemoment', screen: TopicStructureFullDemo },
                    { label: 'Ask me anything', key: 'askmeanything', screen: AskMeAnythingDemo },
                    { label: 'RC Conversation with perspectives', key: 'rc-conversations-perspective', screen: RCConversationThreadWithPerspectiveDemo },
                    { label: 'RC Conversation with slider', key: 'rc-conversations-slider', screen: RCConversationThreadWithCommentSliderDemo },
                ]
            },
        ]
    }
}


function TitledWriterScreen() {
    return <View>
        <Datastore
            config={{
                bylineTags: [(persona) => <REPLACE_CBC_Tag color={colorPink} textColor={colorBlack} type="tiny" text={"Journalist"} />,],
                profilePhotoLayers: [(persona) => <Badge imageUrl={'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/perso/logo-radio-canada-pizza-rouge-archives.jpg'} />],
            }}>

            <DemoSection text='Role tag for a Titled Writer'>
                <REPLACE_CBC_Tag color={colorPink} textColor={colorBlack} type="tiny" text={"Journalist"} />
            </DemoSection>
            <Pad />

            <DemoSection text='Badge for a Titled Writer'>
            <View style={{
                width: 48,
                height: 48,
                backgroundColor: 'transparent'
            }}>
                <Badge imageUrl={'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/perso/logo-radio-canada-pizza-rouge-archives.jpg'} />
            </View>
            </DemoSection>

            <DemoSection text='Byline with Titled Writer widgets'>
            <Byline userId='aa' time={Date.now()} edited={Date.now()} />
            <Byline userId='bb' type='large' time={Date.now()} edited={Date.now()} />
            <Byline userId='c' type='large' time={Date.now()} edited={Date.now()} />
            </DemoSection>

        </Datastore>
    </View>
}

function RecirculationScreen() {
    const relatedConversations = [
        {
            key: 1,
            id: 1,
            from: 'a',
            text: 'Is Godzilla good?',
            picture: 'https://placehold.co/250x150',
        },
        {
            key: 2,
            id: 2,
            from: 'b',
            text: 'Is Kong bad?',
            picture: 'https://placehold.co/250x150',
        },
        {
            key: 3,
            id: 3,
            from: 'c',
            text: 'Would you watch Gozilla vs Kong?',
            picture: 'https://placehold.co/250x150',
        },
    ]

    const collections = {
        derived_relatedQuestionPreview: [
            { key: 1, commentCount: 1234, userList: ['a', 'b', 'c'] },
            { key: 2, commentCount: 64, userList: ['a', 'b', 'c'] },
        ]
    };

    const { relatedContents, testContents, relatedTopics } = STATIC_TOPIC;

    return <View>
        <Datastore testState={{collections}}>
            <DemoSection text='Thread Promo - large' >
                <RecirculationSlider cardsData={relatedConversations} cardType='conversation' controls cardSize="large" />
            </DemoSection>
            <DemoSection text='Thread Promo - small' >
                <RecirculationSlider cardsData={relatedConversations} cardType='conversation' controls />
            </DemoSection>
            <DemoSection text='Content Promo' >
                <RecirculationSlider cardsData={relatedContents} cardType='content' controls />
            </DemoSection>
            <DemoSection text='Single slide demo' >
                <RecirculationSlider cardsData={testContents} cardType='content' />
            </DemoSection>
            <DemoSection text='Previous topics demo' >
                <RecirculationSlider cardsData={relatedTopics} cardType='topic' />
            </DemoSection>
        </Datastore>
    </View>
}
