import { useIsAdmin } from "component/admin.js"
import { Flag, FlagFilled } from '@carbon/icons-react';
import { useDatastore, useObject, usePersonaKey } from "util/datastore.js"
import { SubtleButton } from "component/button.js"

export const AdminRemoveFeature = {
    name: 'Admin Remove Comment',
    key: 'admin_remove',
    config: {
        commentRightActions: [ActionAdminRemove]
    }
}

export function ActionAdminRemove({commentKey}) {
    const personaKey = usePersonaKey();
    const comment = useObject('comment', commentKey);
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();

    if (!isAdmin || comment?.from == personaKey) {
        return null;
    }

    async function removeComment(commentKey) {
        await datastore.callServerAsync('moderation', 'adminSetCommentViolates', {
            commentKey, appealText: '', violates: !comment.violates
        });
    }

    return <SubtleButton icon={comment.violates ? FlagFilled : Flag} ariaLabel='remove' onPress={() => removeComment(commentKey)} />
}
