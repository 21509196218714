import { Narrow } from "component/basics.js"
import { DemoSection } from "system/demo.js"
import { Datastore } from "util/datastore.js"
import { VideoVotingFeature } from "../../../feature/zdf/videovoting/VideoVotingArticleFeature";
export function VideoVotingArticleFeatureDemo() {

    return (
        <Narrow>
            <DemoSection text="Video Voting Teaser (no video voting enabled)">
                <Datastore>
                    <VideoVotingFeature.config.teaser/>
                </Datastore>
            </DemoSection>
            <DemoSection text="Video Voting Teaser (video voting enabled)">
                <Datastore
                    testState={{globals: {
                        videoVotingEnabled: true,
                        questionThreadKey: "someQuestionKey"
                    }}}>
                    <VideoVotingFeature.config.teaser/>
                </Datastore>
            </DemoSection>
            <DemoSection text="Voting Voting Admin Screen (no video voting created yet)">
                <Datastore testState={{globals:{}}}>
                    <VideoVotingFeature.config.adminScreen/>
                </Datastore>
            </DemoSection>
            <DemoSection text="Voting Voting Admin Screen (video voting exists)">
                <Datastore
                    testState={{globals: {
                        videoVotingEnabled: true,
                        questionKey: "someQuestionKey"
                    }}}>
                    <VideoVotingFeature.config.adminScreen/>
                </Datastore>
            </DemoSection>
        </Narrow>
    );
}