import { useCollection, useDatastore, useModulePublicData } from "../../../util/datastore"
import { ModerationFilters } from "./moddashboardqueue"
import { useEffect, useMemo, useRef, useState } from "react"

/** Get number of items in each sub-queue for all threads and all users. Reads a count variable from the database to avoid loading all queue data. */
export function useGlobalQueueCounts() {
    const countsRef = useRef({});

    // Initialize counters for all filters with 0
    for (const filter of Object.values(ModerationFilters)) {
        countsRef.current[filter.id] = 0;
    }

    const queueCounts = useModulePublicData("moderation", ["count_queue_filtered"]);

    // Update counters with the actual number of items per filter
    if (queueCounts) {
        Object.values(queueCounts).forEach((count, index) => {
            const filterId = Object.keys(queueCounts)[index];
            countsRef.current[filterId] = count ?? 0; // Use 0 if count is null or undefined
        });
    }

    // Create a dependency array based on all count references
    const countValues = Object.values(countsRef.current);
    // Return a new object only when one of the counts change
    return useMemo(() => {
        return { ...countsRef.current };
    }, countValues);
}

/** Get number of items in each sub-queue based on a queue data subset (e.g. for a single user or a single question thread). Reads the length of each sub-queue. */
// getQueueSubsetCounts should not be used for the all-comments queue. It would return incorrect counts if some items are unloaded (hidden behind "load more").
export function getQueueSubsetCounts({ queueData }) {
    const queueCounts = Object.values(ModerationFilters).reduce((counts, filter) => {
        counts[filter.id] = queueData[filter.id]?.queue?.length || 0; // Default to 0 if undefined
        return counts;
    }, {});

    return queueCounts;
}

function useUserQueue({ userId }) {
    const datastore = useDatastore();
    const timestampLastModerationAction = useModulePublicData("moderation", ["user", userId, "timeLastModerationAction"]);
    const [userQueue, setUserQueue] = useState([]);

    async function fetchUserQueue() {
        if (!userId) { return; }
        const result = await datastore.callServerAsync("moderationZdf", "getUserQueue", { key: userId });
        setUserQueue(Object.values(result ?? {}));
    }

    // Fetches queue whenever new mod tasks come in or when mod tasks change status (e.g. from awaiting decision to rejected)
    useEffect(() => {
        fetchUserQueue();
    }, [timestampLastModerationAction]);
    return userQueue;
}


/**
 * General hook to retrieve all necessary queue data
 * @param filterOptions Array of `ModerationFilters`
 * @param sortingOrder "newest" or "oldest"
 * @param limit amount of mod tasks to load before user is required to load more
 * @param useAllFilteredQueues Hook to retrieve all sub-queues (currently for all users or 1 user)
 * @param useSingleFilteredQueue Hook to retrieve the sub-queue for a filter option (currently for all instances or 1 instance)
 */
export function useModQueue({
    filterOptions = [],
    sortingOrder = "oldest",
    limit = 20,
    startTime = Date.now(),
    structureKey = null,
    instanceKey = null,
    useAllFilteredQueues = useFilteredQueues,
    useSingleFilteredQueue = useFilteredQueueAllInstances,
    useQueueCounts = getQueueSubsetCounts,
}) {
    // Final queue to be returned
    const [returnQueue, setReturnQueue] = useState([]);

    // Count amount of more items to load (not the total count!)
    const [moreItemsToLoad, setMoreItemsToLoad] = useState(0);
    // Count of newly added items, which are available, but not returned
    const [newItemsToLoad, setNewItemsToLoad] = useState([]);

    const queueData = useAllFilteredQueues({ limit, startTime, sortingOrder, instanceKey, useSingleFilteredQueue });
    const queueCounts = useQueueCounts({ queueData });

    useEffect(() => {
        let combinedQueue = [];
        let newItems = [];

        // If no filter is selected, show the queue items from all filters
        if (filterOptions.length === 0) {
            Object.values(ModerationFilters).forEach((filter) => {
                combinedQueue = combinedQueue.concat(queueData[filter.id]?.queue ?? []);
                newItems = newItems.concat(queueData[filter.id]?.newItemsToLoad ?? []);
            });
        }
        // Show the queue items from the selected filters
        else {
            filterOptions.forEach((filter) => {
                combinedQueue = combinedQueue.concat(queueData[filter.id]?.queue ?? []);
                newItems = newItems.concat(queueData[filter.id]?.newItemsToLoad ?? []);
            });
        }

        // Remove duplicate mod tasks from combined queue.
        // This is important because one comment can appear under multiple filters, e.g. rejected and warned.
        const queueStringified = combinedQueue.map((modTask) => JSON.stringify(modTask));
        const queueStringifiedWithoutDupes = queueStringified.filter(
            (stringifiedModTask, idx) => queueStringified.indexOf(stringifiedModTask) === idx
        );
        combinedQueue = queueStringifiedWithoutDupes.map((stringifiedModTask) => JSON.parse(stringifiedModTask));

        const filteredQueue = combinedQueue.sort((a, b) => {
            if (sortingOrder === "newest") {
                return b.time - a.time;
            } else {
                return a.time - b.time;
            }
        });

        setReturnQueue(filteredQueue.slice(0, limit));

        // Update the new and more items count
        setNewItemsToLoad(newItems);
        setMoreItemsToLoad(combinedQueue.length - limit);
    }, [queueData, limit]);

    return {
        queue: returnQueue,
        moreItemsToLoad: moreItemsToLoad,
        newItemsToLoad: newItemsToLoad,
        queueCounts: queueCounts,
    };
};

/** Get mod tasks from all sub-queues and all users
 * @param useSingleFilteredQueue hook to retrieve the sub-queue of a filter
 */
export function useFilteredQueues({ limit, startTime = Date.now(), sortingOrder = "oldest", useSingleFilteredQueue }) {
    const queuesRef = useRef({});

    // Update the queues in the ref but keep the object reference stable
    Object.values(ModerationFilters).forEach(filter => {
        // TODO: useSingleFilteredQueue is probably a hook and should not be called in a callback function or loop
        queuesRef.current[filter.id] = useSingleFilteredQueue({
            filterOption: filter,
            limit,
            startTime,
            sortingOrder,
        });
    });

    // Create a dependency array based on all queue references
    const queueValues = Object.values(queuesRef.current);
    // Return a new object only when one of the queues changes
    return useMemo(() => {
        return { ...queuesRef.current };
    }, queueValues);
}

/** Get mod tasks from all sub-queues for a single user
 *  @param instanceKey user ID
 */
export function useFilteredQueuesOfUser({ startTime = Date.now(), sortingOrder = "oldest", instanceKey }) {
    const [returnQueueData, setReturnQueueData] = useState({});

    const queue = useUserQueue({ userId: instanceKey });
    const queueArray = Object.values(queue || {});

    useEffect(() => {
        let newQueues = {};

        Object.values(ModerationFilters).forEach((filterOption) => {
            let modObjs = [];
            if (filterOption === ModerationFilters.AwaitingDecision) {
                modObjs = queueArray.filter((v) => v.judgement === undefined);
            } else if (filterOption === ModerationFilters.HumanApproved) {
                modObjs = queueArray.filter((v) => v.judgement === "approve" && v.humanJudgement === true);
            } else if (filterOption === ModerationFilters.Rejected) {
                modObjs = queueArray.filter((v) => v.judgement === "reject");
            } else if (filterOption === ModerationFilters.AutomaticallyApproved) {
                modObjs = queueArray.filter((v) => v.judgement === "approve" && v.humanJudgement === false);
            } else if (filterOption === ModerationFilters.Warned) {
                modObjs = queueArray.filter((v) => v.receivedWarning === true);
            }

            const filteredTasks = modObjs
                .filter((modTask) => modTask != null && modTask.type)
                .filter((item) => (sortingOrder === "oldest" ? item.time >= 0 : item.time <= startTime))
                .sort((a, b) => (sortingOrder === "oldest" ? a.time - b.time : b.time - a.time));

            const newTasks = modObjs
                .filter((modTask) => modTask != null && modTask.type)
                .filter((item) => (sortingOrder === "oldest" ? item.time <= 0 : item.time >= startTime))
                .sort((a, b) => (sortingOrder === "oldest" ? a.time - b.time : b.time - a.time));

            newQueues[filterOption.id] = {
                queue: filteredTasks,
                newItemsToLoad: newTasks,
            };
        });

        setReturnQueueData(newQueues);
    }, [queue, startTime, sortingOrder]);

    return returnQueueData;
}

/** Retrieves mod tasks for all instances (from module public) */
export function useFilteredQueueAllInstances({ filterOption, limit = 20, startTime = Date.now(), sortingOrder = "oldest" }) {
    
    // Always load 1 more than the minimum amount to be able to see if there are more items to retrieve
    const [currentLimit, setCurrentLimit] = useState(limit+1);
    const [newItemsToLoad, setNewItemsToLoad] = useState([]);
    const [returnQueue, setReturnQueue] = useState([])

    const modObjs = useModulePublicData('moderation', ['queue_filtered', filterOption.id], {limit: currentLimit, oldest: sortingOrder==="oldest"});

    useEffect(()=>{

        //Filter the returned queu based on specified start time and end time
        const keys = Object.keys(modObjs || {});
        const queue = keys.map(key => ({ key, ...modObjs[key] })).sort((a, b) => b.time - a.time);

        const filteredQueue = queue.filter(item => sortingOrder==="oldest" ? item.time >= 0 : item.time <= startTime);
        const newItems = queue.filter(item => sortingOrder==="oldest" ? item.time <= 0 :  item.time >= startTime)

        // Case Filtered Queue becomes smaller, than the returned queue -> Means newer items have been added -> More items need to be loaded
        // We have to increase the limit and fetch more
        if(filteredQueue.length < returnQueue.length) {
            setCurrentLimit(currentLimit + limit)
        }
        setNewItemsToLoad(newItems)  
        setReturnQueue(filteredQueue);    
    }, [modObjs, startTime]);

    useEffect(() => {
        if (limit >= currentLimit) {
            setCurrentLimit(limit + 1)
        }
    }, [limit])

    return useMemo(()=>{
        return {queue: returnQueue, newItemsToLoad};
    },[returnQueue, newItemsToLoad])
}

/** Retrieves mod tasks only for current instance */
export function useFilteredQueueCurrentInstance({ filterOption, startTime = Date.now(), sortingOrder = "oldest" }) {
    const [returnQueue, setReturnQueue] = useState([]);
    const [newItemsToLoad, setNewItemsToLoad] = useState([]);

    const modObjs = useCollection("queue_filtered_" + filterOption.id);

    useEffect(() => {
        // Objects in collections cannot really be deleted. We have to check if they have one of the necessary properties.
        const filteredTasks = modObjs
            .filter((modTask) => modTask != null && modTask.type)
            .filter((item) => (sortingOrder === "oldest" ? item.time >= 0 : item.time <= startTime))
            .sort((a, b) => (sortingOrder === "oldest" ? a.time - b.time : b.time - a.time));

        const newTasks = modObjs
            .filter((modTask) => modTask != null && modTask.type)
            .filter((item) => (sortingOrder === "oldest" ? item.time <= 0 : item.time >= startTime))
            .sort((a, b) => (sortingOrder === "oldest" ? a.time - b.time : b.time - a.time));
        setReturnQueue(filteredTasks);
        setNewItemsToLoad(newTasks);
    }, [modObjs, startTime, sortingOrder]);

    return useMemo(() => {
        return { queue: returnQueue, newItemsToLoad };
    }, [returnQueue, newItemsToLoad]);
}