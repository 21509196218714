import { UtilityText } from "component/text.js"
import { TopicLiveStatus } from "../../../component/cbcrc/header";
import { useGlobalProperty } from "util/datastore.js"
import { useConfig } from "util/features";

export const TopicLiveStatusFeature = {
    key: 'topiclivestatusleft',
    name: 'Live Status - Left widget',
    dev: true,
    config: {
        topicHeaderLeftWidgets: [TopicLiveStatusWidget],
        topicDescriptionWidget: TopicLiveStatusDescription
    }
}

export const TopicLiveStatusRightFeature = {
    key: 'topiclivestatusright',
    name: 'Live Status - Right widget',
    dev: true,
    config: {
        topicHeaderRightWidgets: [TopicLiveStatusWidget],
        topicDescriptionWidget: TopicLiveStatusDescription
    }
}

function TopicLiveStatusWidget() {
    const status = useGlobalProperty('status');
    const live = status === 'live';

    return <TopicLiveStatus live={live} />    
}

function TopicLiveStatusDescription() {
    const status = useGlobalProperty('status');
    const {headerFontColor} = useConfig();
    const label = status === 'live' ? 'Topic of the moment' : 'Discussion topic';

    return <UtilityText color={headerFontColor} label={label} />
}


