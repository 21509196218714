import { HorizBox, HoverView, Pad, PadBox } from "component/basics.js"
import { colorDisabledText, colorGreyBorder, colorRed } from "component/color.js"
import { UtilityText } from "component/text.js"
import { useDatastore } from "util/datastore.js"
import { logEventAsync } from "util/eventlog.js"
import React, { createContext, useContext, useEffect, useState } from "react";
import { StyleSheet, View } from "react-native";

/**
 * @typedef {Object} Tab
 * @property {string} id
 * @property {string} label 
 * @property {React.ComponentType<any>} component
 */

/**
 * @typedef {Object} TabContextInformation
 * @property {Tab} activeTab
 * @property {string} activeTabId
 * @property {Tab[]} allTabs
 * @property {function} setActiveTabId
 * @property {function} getTabById
 */

export const TabContext = createContext(undefined)

export const TabContextProvider = ({ children, initialTabId, tabs }) => {
    
    const datastore = useDatastore();
    
    const getTabById = (id) => {
        return tabs.find(tab=>(tab.id===id))
    }
    const [activeTabId, setActiveTabId] = useState(initialTabId);
    const [activeTab, setActiveTab] = useState(getTabById(initialTabId));

    // Log the initial tab before the user switches
    useEffect(() => {
        logEventAsync(datastore, "tabnavigation-open", {
            tabId: activeTabId,
        });
    }, []);


    // Handle case where current tab gets hidden
    useEffect(()=>{
        if(tabs && getTabById(activeTabId).hidden) {
            changeActiveTab(tabs.find(t=>!t.hidden).id);
        }
    }, [tabs])

    const changeActiveTab = (id) => {
        setActiveTabId(id);
        setActiveTab(getTabById(id));
 
        logEventAsync(datastore, "tabnavigation-switch-tab", {
            tabId: id,
        });
    }

    const tabContextValue = {
        activeTabId: activeTabId,
        activeTab: activeTab,
        allTabs: tabs,
        setActiveTabId: changeActiveTab,
        getTabById: getTabById
    }

    return <TabContext.Provider value={tabContextValue}>
        {children}
    </TabContext.Provider>
}

/**
 * 
 * @returns {TabContextInformation}
 */
export function useTabs () {
    return useContext(TabContext);
}

export function TabNavigation({ children, initialTabId, tabs}) {

    // if no initial id or the id does not exist, take the first tab from list
    const tabId = (initialTabId && tabs.find(t=>t.id===initialTabId)) ? initialTabId : tabs[0].id

    return <TabContextProvider initialTabId={tabId} tabs={tabs}>
        {children}
    </TabContextProvider>
}

export function TabBar() {
    const s = TabStyle;

    const tabContext = useTabs()

    // Tabbar with just one Tab gets hidden
    if(tabContext.allTabs.length <= 1) {
        return <></>
    }
    return (
        <View>
            <HorizBox center={false}>
                {tabContext.allTabs?.map(
                    (tab, idx) =>
                        !tab.hidden && (
                            <HorizBox center={false} key={tab.id}>
                                <TabButton tab={tab} testId={tab.label}/>
                                { idx < tabContext.allTabs.length - 1 && <Pad size={30} /> }
                            </HorizBox>
                        )
                )}
            </HorizBox>
            <Pad size={5} />
            <div style={s.tabBarUnderline} />
            <Pad size={10} />
        </View>
    );
}

export function TabButton({tab, testId}) {
    const s = TabStyle;

    const tabContext = useTabs()
    const tabIsActive = (tabContext.activeTabId === tab.id);

    const [hover, setHover] = useState(false);

    return (
        <HoverView
            shrink
            style={s.tabButton}
            setHover={setHover}
            onPress={() => {
                tabContext.setActiveTabId(tab.id);
            }}
            role="button"
            testID={testId}
        >
            <div style={s.tabButtonInner}>
                <HorizBox center>
                    {tab.icon && (
                        <>
                            <UtilityText label={tab.icon}/>
                            <Pad size={8} />
                        </>
                    )}
                    <UtilityText
                        label={tab.label}
                        underline={hover}
                        strong={tabIsActive}
                        color={tabIsActive ? "black" : colorDisabledText}
                    />
                    {tab.secondaryLabel && (
                        <>
                            <Pad size={8} />
                            <UtilityText
                                label={tab.secondaryLabel}
                                color={tabIsActive ? colorRed : colorDisabledText}
                                type="tiny"
                            />
                        </>
                    )}
                </HorizBox>
                {tabIsActive ? <div style={s.tabUnderline} /> : null}
            </div>
        </HoverView>
    );
}

export function TabContent() {
    const tabContext = useTabs();
    // Render all tabs and hide them selectively to not reset state between switch
    return (
        <>
            {tabContext.allTabs.map((tab) => (
                <View key={tab.id} style={{ display: tab.id === tabContext.activeTabId ? "flex" : "none" }}>
                    <tab.component {...tab.params} />
                </View>
            ))}
        </>
    );
}

const TabStyle = StyleSheet.create({
    tabButton: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    tabButtonInner: {
        display: "flex",
        flexDirection: "column"
    },
    tabUnderline: {
        position: "absolute",
        marginTop: "34px",
        height: "2px",
        width: "100%",
        backgroundColor: "black"
    },
    tabBarUnderline: {
        height: "1px",
        backgroundColor: colorGreyBorder
    }
});