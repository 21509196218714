import { DemoSection, SpacedArray } from "system/demo.js"
import { UtilityText } from "component/text.js"
import { View } from "react-native-web"
import { AdaptiveLayoutArticleQuestionTeaserWithComment, AdaptiveLayoutCMSEmbeddedQuestionTeaser, ArticleQuestionsAdminScreen, CardStack, CommentPreview, EditorialQuestionFeature, EditorialQuestionTeaser, MiniQuestionPromo, MultiQuestionTeaser, NarrowQuestionTeaserWithComment, QuestionInfo, SingleQuestionTeaser, TeaserButton, WideQuestionTeaserWithCommnt, teaserPalette } from "../feature/teaser/EditorialQuestionFeature"
import { Datastore } from "util/datastore.js"
import { PadBox } from "component/basics.js"
import { Edit } from "@carbon/icons-react"
import { TopicChooserAdminScreen, TopicPhotoTeaser, TopicPhotoTeaserForArticle, TopicPhotoTeaserForTopic } from "../feature/teaser/TopicPhotoTeaser"
import { CLICK } from "system/demo.js"
import { ParamContext } from "util/params.js"
import { ArticleAdminScreen, ArticleFeaturesScreen, ArticleScreen, ArticleTeaser } from "../structure/article"
import { TopicTeaser } from "../structure/topic"

export const TeaserDemoFeature = {
    key: 'demo_teaser',
    name: 'Demo - Teasers',
    config: {
        componentSections: [
            {label: 'Teaser', pages: [
                {label: 'Old Teaser', key: 'oldteaser', screen: OldTeaserScreen},
                {
                    label: 'New Teaser', 
                    key: 'newteaser', 
                    designUrl: 'https://www.figma.com/design/MX0AcO8d0ZlCBs4e9vkl5f/PSI-Design-System?node-id=6312-22175&t=pgpscnehX1ig2r0m-1',
                    screen: NewTeaserScreen
                },
                {
                    label: 'Topic Photo Teaser',
                    key: 'topicphototeaser',
                    storySets: topicPhotoTeaserStorySets
                },
                {
                    label: 'Article System',
                    key: 'articlesystem',
                    storySets: articleSystemStorySets
                },
                {
                    label: 'Direct-Linked Teaser',
                    key: 'directlinkedteaser',
                    storySets: directLinkedTeaserStorySets
                }
            ]}
        ]
    }
}


function OldTeaserScreen() {
    const question = {
        key: 1,
        from: 'a',
        text: 'Is Godzilla good?',
    }
    const questionTwo = {
        key: 2,
        from: 'b',
        text: 'Should we welcome Godzilla to Tokyo?',
    }
    const collections = {
        derived_questionPreview: [
            {key: 1, commentCount: 1234}
        ]
    }
    const config = EditorialQuestionFeature.defaultConfig;

    return <View>
        <DemoSection text='Mini Question Promo'>
            <Datastore testState={{collections}}>
                <SpacedArray>
                    <MiniQuestionPromo question={question} />
                    <MiniQuestionPromo question={questionTwo} joinButton/>
                </SpacedArray>
            </Datastore>
        </DemoSection>
        <DemoSection text='Single Question Teaser'>
            <Datastore testState={{collections}}>
                <SingleQuestionTeaser question={question} />
            </Datastore>
            <Datastore testState={{collections, roles:['Editoral']}}>
                <SingleQuestionTeaser question={questionTwo} />
            </Datastore>
        </DemoSection>
        <DemoSection text='Multi Question Teaser'>
            <Datastore testState={{collections}}>           
                <MultiQuestionTeaser questions={[question, questionTwo]} />
            </Datastore>
        </DemoSection>
        <DemoSection text='Editorial questions Teaser'>
            <Datastore testState={{collections: {...collections, question: [question, questionTwo]}}} config={config}>
                <EditorialQuestionTeaser />
            </Datastore>
            <Datastore testState={{roles:['Editorial'], collections: {...collections, question: [question, questionTwo]}}} config={config}>
                <EditorialQuestionTeaser />
            </Datastore>
            <Datastore testState={{roles:['Editorial']}} >
                <EditorialQuestionTeaser />
            </Datastore>
        </DemoSection>
    </View>
}



function NewTeaserScreen() {
    const question = {
        key: 1,
        from: 'a',
        text: 'Is Godzilla good?',
    }

    const commentPreview = {
        key: 1,
        from: 'a',
        text: 'I love Godzilla. He is so cute.',
        time: Date.now(),
        upvoteCount: 5
    }

    const collections = {
        derived_questionPreview: [
            {key: 1, commentCount: 1234, commentPreview}
        ]
    }

    // return null;
    return <View>
        <Datastore config={{...EditorialQuestionFeature.defaultConfig, teaserColorPalette: teaserPalette.green}} testState={{collections}}>
            <DemoSection text='Question (Green) Teaser'>
                <AdaptiveLayoutArticleQuestionTeaserWithComment question={question} commentPreview={commentPreview} commentCount={12} />
            </DemoSection>
            </Datastore>
        <Datastore config={{...EditorialQuestionFeature.defaultConfig, teaserColorPalette: teaserPalette.tan}} testState={{collections}}>
            <DemoSection text='Question (Tan) Teaser'>
                <AdaptiveLayoutArticleQuestionTeaserWithComment question={question} commentPreview={commentPreview} commentCount={12} />
            </DemoSection>
            <DemoSection text='Wide Question Teaser (squished by component demo margin)'>
                <WideQuestionTeaserWithCommnt questionText={question.text} questionKey={question.key} entryUrl={question.entryUrl} commentPreview={commentPreview} commentCount={12} />
            </DemoSection>            
        </Datastore>
        <Datastore config={{...EditorialQuestionFeature.defaultConfig, teaserColorPalette: teaserPalette.green}}>
            <DemoSection text='Wide Empty State (squished by component demo margin)'>
                <WideQuestionTeaserWithCommnt questionText={question.text} questionKey={question.key} entryUrl={question.entryUrl} />
            </DemoSection>
            <DemoSection text='Narrow Empty State'>
                <NarrowQuestionTeaserWithComment questionText={question.text} questionKey={question.key} entryUrl={question.entryUrl} />
            </DemoSection>
        </Datastore>
        <Datastore config={{...EditorialQuestionFeature.defaultConfig}} testState={{modulePublic: {'isBeta': true}}} >
            <DemoSection text='With Beta Tag'>
                <NarrowQuestionTeaserWithComment questionText={question.text} questionKey={question.key} entryUrl={question.entryUrl} />
            </DemoSection>
        </Datastore> 
        

        <Datastore config={{...EditorialQuestionFeature.defaultConfig, teaserColorPalette: teaserPalette.tan}}>
            <DemoSection text='Comment Stack'>
                <CardStack>
                    <PadBox horiz={20} vert={20}>
                        <UtilityText text='Content goes here' />
                    </PadBox>
                </CardStack>
            </DemoSection>

            <DemoSection text='Teaser Button'>
                <TeaserButton text='Teaser Button' icon={<Edit color='white' />} />
            </DemoSection>

            <Datastore>
                <DemoSection text='Comment Preview'>
                    <CommentPreview commentPreview={commentPreview} />
                </DemoSection>
            </Datastore>

            <DemoSection text='Question Info'>
                <QuestionInfo questionText={question.text} questionKey={question.key} entryUrl={question.entryUrl} commentCount={42} />
            </DemoSection>
        </Datastore>

    </View>
}

const imageUrl = 'https://talkb.org/images/climate.png'

function topicPhotoTeaserStorySets() {return [
    {
        label: 'Topic Photo Teaser', 
        content: <TopicPhotoTeaser topicKey='climate' topicPhoto={imageUrl} topicName='Climate' entryUrl={imageUrl} entryTitle='This is fine' entryImage='https://talkb.org/images/climate.png' />,
        stories: [
            {label: 'Click', actions: [
                CLICK('photo-teaser')
            ]}
        ],
        roles: ['Owner']
    },
    {
        label: 'Topic Photo Teaser With Beta Tag', 
        content: <TopicPhotoTeaser topicKey='climate' topicPhoto={imageUrl} topicName='Climate' entryUrl={imageUrl} entryTitle='This is fine' entryImage='https://talkb.org/images/climate.png' />,
        modulePublic: {'isBeta': true},
    },    
    {
        label: 'Topic Photo Teaser for Article',
        instanceKey: 'article',
        globals: {articleInfo: {title: 'This is fine', image: imageUrl}},
        collections: {topic: [{key: 'climate', text: 'Climate', photoUrl: imageUrl}]},
        content: <TopicPhotoTeaserForArticle />
    },
    {
        label: 'Topic Photo Teaser for Topic',
        globals: {name: 'Climate', photoUrl: imageUrl},
        instanceKey: 'climate',
        config: {
            teaser: TopicPhotoTeaserForTopic
        },
        content: <ParamContext.Provider value={{globalParams: {entryUrl: 'https://foo.com'}}}>
            <TopicTeaser />
            {/* <TopicPhotoTeaserForTopic /> */}
        </ParamContext.Provider>
    },
    {
        label: 'Topic Chooser',
        content: <TopicChooserAdminScreen />,
        modulePublic: {
            topic: {
                climate: {key: 'climate', name: 'Climate', photoUrl: imageUrl},
                godzilla: {key: 'godzilla', name: 'Godzilla', photoUrl: imageUrl},                
            }
        },
        roles: ['Editorial'],
        serverCall: {topics: {selectTopic: () => {}}},
        stories: [
            {label: 'Select Topic', actions: [
                CLICK('Climate'),
            ]}
        ]
    }

]}

const serverCall = {
    constructor: {runConstructor: () => {}}
}

function articleSystemStorySets() {return [
    {
        label: 'Article Teaser - Editorial Question Teaser Empty',
        serverCall,
        structureKey: 'article',
        config: {
            teaser: EditorialQuestionTeaser
        },
        content: <ArticleTeaser />,
    },
    {
        label: 'Article Teaser - No Teaser Admin',
        serverCall,
        roles: ['Editorial'],
        content: <ArticleTeaser />,
        stories: [
            {label: 'Open Admin Screen', actions: [
                CLICK('Open admin screen')
            ]}
        ]
    },
    {
        label: 'Article Admin Screen - Article Questions',
        roles: ['Editorial'],
        config: {
            adminScreen: ArticleQuestionsAdminScreen
        },
        content: <ArticleAdminScreen />,
    },
    {
        label: 'Article Admin Screen - No teaser',
        roles: ['Editorial'],
        content: <ArticleAdminScreen />,
    },
    {
        label: 'Article Admin Screen - Not admin',
        content: <ArticleAdminScreen />,
    },
    {
        label: 'Article Features Screen',
        structureKey: 'article',
        content: <ArticleFeaturesScreen />,
        roles: ['Owner']
    }
]}

function directLinkedTeaserStorySets() {return [
    {
        label: 'Direct-Linked Question Teaser',
        globals: {name: 'Is Godzilla good?'},
        config: {
            teaserColorPalette: teaserPalette.green
        },
        collections: {comment: [{key: 1, from: 'a', text: 'I love Godzilla. He is so cute.', time: 1}]},
        content: <AdaptiveLayoutCMSEmbeddedQuestionTeaser />,
    }
]}
