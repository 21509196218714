import { UserMultiple } from "@carbon/icons-react";
import { HorizBox, Pad, PadBox } from "component/basics.js"
import { colorWhite } from "component/color.js"
import { spacings } from "component/constants";
import { UtilityText } from "component/text.js"
import { useCollection } from "util/datastore.js"
import { useConfig } from "util/features";

export const TopicParticipantsFeature = {
    key: 'topicparticipants',
    name: 'Topic Participants',
    dev: true,
    config: {
        topicHeaderBottomWidgets: [TopicParticipantsWidget]
    },
}

function TopicParticipantsWidget() {
    const personas = useCollection('persona');
    const {headerFontColor} = useConfig();
    const count = personas?.length;

    return <PadBox top={24}>
        <HorizBox>
            <UserMultiple color={headerFontColor} />
            <Pad size={spacings.xs} />
            <UtilityText color={headerFontColor} label='{count} {noun}'
                formatParams={{ count, singular: 'participant', plural: 'participants' }}
            />
        </HorizBox>
    </PadBox>
}
