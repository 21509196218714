import { useSessionData } from "util/datastore.js"

export const VIDEO_VOTING_SESSION_STATES = {
    initVideo: "initVideo", //The initial state. It should wait until the video is ready to play
    initSession: "initSession", // Video voting gets initialized: Show the intro screen
    waitForQuestion: "waitForQuestion", // Video plays, wait until a question triggers
    showQuestion: "showQuestion", // Show question dialog
    showQuestionResults: "showQuestionResults", // Show question dialog
    endScreen: "endScreen", // Show the end screen
    ended: "ended", // The video voting has finished
    disabled: "disabled", // The videovoting has been disabled
    skippedVoting: "skippedVoting" // The videovoting has been skipped for the current viewing session
  };


/**
 * @typedef {Object} VideoVotingSessionData
 * @property {string} state - Current state of session
 * @property {string} id - Current id of session
 * @property {string} currentQuestionKey - The key of the current question
 * @property {boolean} anonymous - Wether anonymous voting is enabled
 * @property {Object.<string, VideoVotingSessionQuestionData>} questions - data about answered questions during session
 * @property {string} personaKey - key of the current user of the session
 * @property {Object} template - template of the video voting
 */

/**
 * @typedef {Object} VideoVotingSessionQuestionData
 * @property {boolean} answered - Wether the question was already answered in the current session
 */

/**
 * @param {string} instanceKey - optional - The instance key of the voting. Defaults to current instance key
 * @returns {VideoVotingSessionData} The temporary video voting session data
 */
export function useVideoVotingSessionData() {
    const sessionData = useSessionData(["videoVotingSessionState"])
    return sessionData;
}

/**
 * 
 * @param {} datastore
 * @param {VideoVotingSessionData} session
 */
export function setVideoVotingSessionData(datastore, session) {
    datastore.setSessionData(["videoVotingSessionState"], session)
}

/**
 * 
 * @param {} datastore
 * @param {VideoVotingSessionData} session
 */
export function updateVideoVotingSessionData(datastore, session) {
    const sessionData =  datastore.getSessionData(["videoVotingSessionState"])
    datastore.setSessionData(["videoVotingSessionState"], {...sessionData, ...session})
}