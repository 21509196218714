import React from "react";
import { ConversationScreen, FlowBox, HorizBox, HoverView, Pad, PadBox, Separator } from "component/basics.js"
import { CTAButton, TextButton, BetaTag } from "component/button.js"
import { colorBlackHover, colorNearBlack, colorPinkBackground, colorWhite, colorGreyBorder, colorTealBackground, colorSurfaceDiscovery, colorMintGreenBackground } from "component/color.js"
import { Heading, UtilityText } from "component/text.js"
import { useCollection, useDatastore, useInstanceKey, useObject, useStructureKey, useGlobalProperty, useModulePublicData, useGlobalParams } from "util/datastore.js"
import { gotoInstance, gotoInstanceScreen } from "util/navigate.js"
import { StyleSheet, View, Pressable } from "react-native";
import { TeaserScreen } from "../../system/teaser";
import { useIsAdmin } from "component/admin.js"
import { Byline, ProfilePhoto } from "component/people.js"
import { AddQuestionBox, useGlobalQuestions } from "../../component/question";
import { useState } from "react";
import { Catcher } from "system/catcher.js"
import { AddComment, Edit } from "@carbon/icons-react";
import { keyToUrl, toBool } from "../../util/util";
import { useLogEvent } from "util/eventlog.js"
import { Toggle } from "component/form.js"
import { RichText } from "component/richtext.js"
import { useConfig } from "util/features.js"

export const teaserPalette = getTeaserPalette();

export const EditorialQuestionFeature = {
    name: 'Connected Question/Topic',
    key: 'editorialquestion',
    config: {
        teaser: EditorialQuestionTeaser,
        adminScreen: ArticleQuestionsAdminScreen
    },
    defaultConfig: {
        teaserSingleQuestion: AdaptiveLayoutArticleQuestionTeaserWithComment,
        teaserMultiQuestion: MultiQuestionTeaser,
        teaserColorPalette: teaserPalette.green
    }
}

export const CMSEmbeddedQuestionTeaserFeature = {
    name: 'Question Teaser (CMS)',
    key: 'cmsembeddedquestionteaser',
    dev: true,
    config: {
        teaser: AdaptiveLayoutCMSEmbeddedQuestionTeaser
    },
    defaultConfig: {
        teaserColorPalette: teaserPalette.green
    }
}

export const TanQuestionTeaserFeature = {
    name: 'Question (Tan)',
    key: 'tanquestionteaser',
    config: {
        teaserSingleQuestion: AdaptiveLayoutArticleQuestionTeaserWithComment,
        teaserColorPalette: teaserPalette.tan
    },
};

export const GreenQuestionTeaserFeature = {
    name: 'Question (Green)',
    key: 'greenquestionteaser',
    config: {
        teaserSingleQuestion: AdaptiveLayoutArticleQuestionTeaserWithComment,
        teaserColorPalette: teaserPalette.green
    },
};

function getTeaserPalette() {
    const blackButtonPalette = {
        backgroundColor: colorNearBlack,
        textColor: colorWhite    
    };

    const whiteButtonPalette = {
        backgroundColor: colorWhite,
        textColor: colorNearBlack    
    };

    return {
        green: {
            questionBackgroundColor: colorTealBackground,
            questionTextColor: colorWhite,
            commentBackgroundColor: colorMintGreenBackground,
            narrowTeaserElements: {
                buttonPalette: blackButtonPalette,
            },
            wideTeaserElements: {
                buttonPalette: whiteButtonPalette
            }
        },
        tan: {
            questionBackgroundColor: colorPinkBackground,
            questionTextColor: colorNearBlack,
            commentBackgroundColor: colorSurfaceDiscovery,
            narrowTeaserElements: {
                buttonPalette: blackButtonPalette,
            },
            wideTeaserElements: {
                buttonPalette: blackButtonPalette
            }
        }
    };
}


export function ArticleQuestionsAdminScreen() {
    const cutoffDate = new Date('2025-1-1');
    const questions = useGlobalQuestions();
    const [search, setSearch] = useState('');
    const localSelectedQuestions = useCollection('question').filter(q => !q.deleted);
    const datastore = useDatastore();
 
    const recentQuestions = questions?.filter(question => question.time > cutoffDate);
    const filteredQuestions = recentQuestions?.filter(question => !search || question.question?.toLowerCase().includes(search.toLowerCase()));
    const selectedQuestions = filteredQuestions.filter(question => localSelectedQuestions.find(q => q.key == question.key));
    const otherQuestions = filteredQuestions.filter(question => !selectedQuestions.find(q => q.key == question.key));

    async function onAdded(questionKey) {
        await datastore.callServerAsync('questions', 'select', {questionKey});
    }

    return <ConversationScreen>
        <Pad />
        <PadBox horiz={20}><Heading label='Choose Questions for this Article' /></PadBox>        
        <PadBox horiz={20} vert={20}>
            <AddQuestionBox onChange={setSearch} onAdded={onAdded} />
        </PadBox>
        <Separator />
        <Pad />
        {selectedQuestions?.map(question =>
            <Catcher key={question.key}><Question question={question} /></Catcher>
        )}
        {otherQuestions?.map(question =>
            <Catcher key={question.key}><Question question={question} /></Catcher>
        )}
    </ConversationScreen>
}

function Question({question}) {
    const datastore = useDatastore();
    const selectedQuestion = useObject('question', question.key);
    const isChosen = selectedQuestion && !selectedQuestion.deleted;

    async function onChange(selected) {
        await datastore.callServerAsync('questions', selected ? 'select' : 'unselect', {
            questionKey: question.key
        });
    }

    return <PadBox horiz={20}>
        <Toggle spread text={question.text ?? question.question} value={isChosen} onChange={onChange} />
    </PadBox>
}

export function EditorialQuestionTeaser() {
    const isAdmin = useIsAdmin();
    const questions = useCollection('question').filter(q => !q.deleted);
    const instanceKey = useInstanceKey();
    const {teaserSingleQuestion, teaserMultiQuestion} = useConfig();
    const url = keyToUrl(instanceKey);
    useLogEvent('teaser-show', {url});

    if (questions.length == 1) {
        return <TeaserScreen>
            {React.createElement(teaserSingleQuestion, {question: questions[0]})}
        </TeaserScreen>
    } else if (questions.length > 1) {
        return <TeaserScreen>
            {React.createElement(teaserMultiQuestion, {questions})}
        </TeaserScreen>
    } else if (isAdmin) {
        return <TeaserScreen>
            <AdminTeaserAction />
        </TeaserScreen>
    } else {
        return <TeaserScreen/>
    }
}

function AdminTeaserAction() {
    const instanceKey = useInstanceKey();
    const structureKey = useStructureKey();
    const datastore = useDatastore();
    return <FlowBox columnGap={10}>
        <CTAButton type='secondary' label='Edit questions (admin)' 
            onPress={() => datastore.gotoInstanceScreen({structureKey, instanceKey, screenKey: 'admin', params: {}})} /> 
        <CTAButton type='secondary' label='Configure Teaser Features (admin)' 
            onPress={() => datastore.gotoInstanceScreen({structureKey, instanceKey, screenKey: 'features', params: {}})} />
    </FlowBox>
}

function PinkBox({children}) {
    return <View style={{backgroundColor: colorPinkBackground, borderRadius: 10}}>
        {children}
    </View>
}

export function MultiQuestionTeaser({questions}) {
    const isAdmin = useIsAdmin();

    return <PinkBox>
        <PadBox horiz={20} vert={20}>
            <HorizBox center>
                <AddComment />
                <Pad size={8} />
                <UtilityText type='tiny' label='Related Conversations' caps weight='strong' />
            </HorizBox>
            <Pad size={16} />
            {questions.map((question, idx) => 
                <MiniQuestionPromo key={question.key} question={question} showSeparator={idx > 0} />
            )}
            {isAdmin && <PadBox top={20}><AdminTeaserAction /></PadBox>}
        </PadBox>
    </PinkBox>
}

export function SingleQuestionTeaser({question}) {
    const isAdmin = useIsAdmin();
    const questionPreview = useObject('derived_questionPreview', question.key);

    return <PinkBox>
        <PadBox horiz={20} vert={20}>
            <HorizBox center>
                <AddComment />
                <Pad size={8} />
                <UtilityText type='tiny' label='Related Conversation' caps weight='strong' />
                <Pad size={8} />
                {questionPreview?.commentCount > 0 ? 
                    <UtilityText type='tiny' weight='medium' color={colorBlackHover} label='{count} {noun}' formatParams={{count: questionPreview?.commentCount, singular: 'response', plural: 'responses'}} />
                :
                    <UtilityText type='tiny' weight='medium' color={colorBlackHover} label='Be the first to share your thoughts' />
                }
            </HorizBox>
            <Pad size={16} />
            <MiniQuestionPromo key={question.key} question={question} showCount={false} joinButton />
            {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction/></PadBox>}
        </PadBox>
    </PinkBox>
}


export function MiniQuestionPromo({question, showSeparator=false, joinButton=false, showCount=true}) {
    const s = MiniQuestionPromoStyle;
    const questionPreview = useObject('derived_questionPreview', question.key);
    const articleInfo = useGlobalProperty('articleInfo') ?? {};
    const instanceKey = useInstanceKey();
    const globalParams = {entryUrl: keyToUrl(instanceKey), entryTitle: articleInfo.title, entryImage: articleInfo.image};
    const datastore = useDatastore();

    function onPress() {
        datastore.gotoInstance({structureKey: 'question', instanceKey: question.key, globalParams});
    }

    return <View>
        {showSeparator && <PadBox top={12} bottom={12}><Separator /></PadBox>}
        <HorizBox center>  
            <PadBox top={4}><ProfilePhoto userId={question.from} type='large' /></PadBox>
            <Pad size={12} />
            <View style={{flex: 1}}>
                <TextButton level={5} editorial type='small' style={s.question} 
                    text={question.text} onPress={onPress} 
                /> 
                {showCount && <Pad size={4} />}
                {showCount && (questionPreview?.commentCount ? 
                    <UtilityText type='tiny' weight='medium' color={colorBlackHover} label='{count} {noun}' formatParams={{count: questionPreview?.commentCount, singular: 'response', plural: 'responses'}} />
                :
                    <UtilityText type='tiny' weight='medium' color={colorBlackHover} label='Be the first to share your thoughts' />
                )}
            </View>
            {joinButton && 
                <CTAButton label='Join' onPress={() => gotoInstance({structureKey: 'question', instanceKey: question.key})} />
            }           
        </HorizBox>
    </View>
}
const MiniQuestionPromoStyle = StyleSheet.create({
    question: {
        fontFamily: 'PlayfairDisplay_400Regular',
        fontSize: 18,
    },
})

export function AdaptiveLayoutCMSEmbeddedQuestionTeaser() {
    const questionText = useGlobalProperty('name');
    const questionKey = useInstanceKey();
    const {entryUrl} = useGlobalParams();
    const comments = useCollection('comment', {filter: {replyTo: null}, sortBy: 'time', reverse: true});
    const filteredComments = comments?.filter(c => 
        !c.replyTo && c.text?.trim() && !c.violates && !c.inReview
    );

    const pinnnedCommentKeys = useGlobalProperty('pinned_comments') ?? {};
    const pinnedComments = filteredComments?.filter(c => pinnnedCommentKeys[c.key]);
    const commentPreview = pinnedComments[0] ?? filteredComments[0] ?? null;
    const commentCount = filteredComments?.length;
    return <TeaserScreen>
        <AdaptiveLayoutQuestionTeaser questionText={questionText} questionKey={questionKey} entryUrl={entryUrl} commentPreview={commentPreview} commentCount={commentCount} />
    </TeaserScreen>
}

export function AdaptiveLayoutArticleQuestionTeaserWithComment({question}) {
    const instanceKey = useInstanceKey();
    const entryUrl = keyToUrl(instanceKey);

    const questionText = question.text;
    const questionKey = question.key;
    const questionPreview = useObject('derived_questionPreview', questionKey);
    const commentPreview = questionPreview?.commentPreview;
    const commentCount = questionPreview?.commentCount;
    const isAdmin = useIsAdmin();

    return <TeaserScreen>
        <AdaptiveLayoutQuestionTeaser questionText={questionText} questionKey={questionKey} entryUrl={entryUrl} commentPreview={commentPreview} commentCount={commentCount} />
        {isAdmin && <PadBox top={20}><AdminTeaserAction /></PadBox>}
    </TeaserScreen>
}

export function AdaptiveLayoutQuestionTeaser({questionText, questionKey, entryUrl,commentPreview, commentCount}) {
    const [narrow, setNarrow] = useState(false);
    const datastore = useDatastore();
    function onLayout(event) {
        const {width} = event.nativeEvent.layout;
        setNarrow(width < 540);
    }

    function onPress() {
        const globalParams = {entryUrl};
        datastore.gotoInstance({structureKey: 'question', instanceKey: questionKey, globalParams}); 
    }

    return <Pressable testID='question-teaser' onLayout={onLayout} onPress={onPress}>
        {narrow ? 
            <NarrowQuestionTeaserWithComment questionText={questionText} commentPreview={commentPreview} commentCount={commentCount} />
        :
            <WideQuestionTeaserWithCommnt questionText={questionText} commentPreview={commentPreview} commentCount={commentCount} />
        }
    </Pressable>
}

export function NarrowQuestionTeaserWithComment({questionText, commentPreview, commentCount}) {
    const s = NarrowQuestionTeaserStyle;
    const { teaserColorPalette: { questionBackgroundColor, commentBackgroundColor, narrowTeaserElements: {buttonPalette} } } = useConfig();
    return <View style={[s.top, {backgroundColor: questionBackgroundColor}]}>
        <PadBox horiz={20} vert={20}>
            <QuestionInfo questionText={questionText} commentCount={commentCount} narrow />
        </PadBox>
        <View style={[s.bottom, {backgroundColor: commentBackgroundColor}]}>
            <PadBox bottom={20}><CommentPreview commentPreview={commentPreview}/></PadBox>  
            <TeaserButton wide label='Share your thoughts' backgroundColor={buttonPalette.backgroundColor} 
            textColor={buttonPalette.textColor} icon={<Edit color={buttonPalette.textColor} />}/>
        </View>
    </View>
}

const NarrowQuestionTeaserStyle = StyleSheet.create({
    top: {
        borderRadius: 10,
    },
    bottom: {
        padding: 20,
        flex: 1,
        justifyContent: 'center',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,   
    }
})


export function WideQuestionTeaserWithCommnt({questionText, commentPreview, commentCount}) {
    const s = WideQuestionTeaserStyle;
    const { teaserColorPalette: { questionBackgroundColor, commentBackgroundColor, wideTeaserElements: {buttonPalette} } } = useConfig();
    return <View style={s.outer}>
        <View style={[{backgroundColor: questionBackgroundColor}, s.left]}>
            <QuestionInfo questionText={questionText} commentCount={commentCount} />
            <Pad size={20} />
            <TeaserButton label='Share your thoughts' backgroundColor={buttonPalette.backgroundColor} 
            textColor={buttonPalette.textColor} icon={<Edit color={buttonPalette.textColor} />}/>
        </View>
        <View style={[{backgroundColor: commentBackgroundColor}, s.right]}>
            <CommentPreview commentPreview={commentPreview}/>                
        </View>
    </View>
}

const WideQuestionTeaserStyle = StyleSheet.create({
    outer: {
        borderRadius: 10,
        flexDirection: 'row',
    },
    left: {
        padding: 24,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        flex: 1,
    },
    right: {
        padding: 24,
        flex: 1,
        justifyContent: 'center',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
    }
})


export function CommentPreview({commentPreview}) {
    if (!commentPreview) {
        return <SkeletonCommentInCardStack />
    }

    return <CardStack>
        <PadBox horiz={20} vert={16}>
            <Byline userId={commentPreview.from} type='small' clickable={false} time={commentPreview.time} />
            <Pad size={12} />
            <RichText numberOfLines={2} text={commentPreview.text} />
        </PadBox>
    </CardStack>
}

export function QuestionInfo({questionText, commentCount, narrow=false}) {
    const showBetaTag = useModulePublicData('isBeta')
    const {teaserColorPalette: { questionTextColor: textColor }} = useConfig();
    return <View>
        <HorizBox center>
            <AddComment color={textColor} />
            <Pad size={8} />
            <UtilityText type='tiny' weight='regular' label="Let's talk about..." color={textColor} caps />
            {showBetaTag && <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Pad size={8} />
                <BetaTag type={textColor === colorWhite ? 'secondary' : 'primary'} />
            </View>}
        </HorizBox>
        <Pad size={narrow ? 20 :24}/>
        <Heading text={questionText} type="large" weight="medium" color={textColor}></Heading>
        <Pad size={8}/>
        {commentCount ? 
            <UtilityText type='tiny' weight='medium' label='{count} {noun}' color={textColor}
                formatParams={{count: commentCount, singular: 'response', plural: 'responses'}} 
            />
        :
            <UtilityText type='tiny' weight='medium' label='Be the first to share your thoughts' color={textColor} />
        }
    </View>
}

export function TeaserButton({label, text, icon, onPress, wide, backgroundColor=colorNearBlack, textColor=colorWhite}) {
    const s = TeaserButtonStyle;
    const buttonStyle = [s.button, { backgroundColor }, wide && s.wideStyle];
    return <HoverView onPress={onPress} style={buttonStyle}>
        <UtilityText type='large' label={label} text={text} color={textColor}/>
        {icon && <PadBox left={12}>{icon}</PadBox>}
    </HoverView>
}
const TeaserButtonStyle = StyleSheet.create({
    button: {
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderRadius: 32,
        alignSelf: 'flex-start',
        flexDirection: 'row',
        height: 40, 
        alignItems: 'center',
    },
    wideStyle: {
        alignSelf: 'stretch', 
        justifyContent: 'center', 
        flex: 1
    }
});

export function CardStack({children}) {
    const s = CardStackStyle;
    return <View>
        <View style={s.topCard}>
            {children}
        </View>
        <View style={s.secondCard} />
        <View style={s.thirdCard} />
    </View>
}

function SkeletonCommentInCardStack() {
    return (
        <CardStack>
            <PadBox horiz={12} vert={12}>
                <SkeletonComment />
            </PadBox>
        </CardStack>
    );
}

function SkeletonComment() {
    const s = SkeletonCommentStyle;
    return (
        <View>
            <View style={s.horiz}>
                <SkeletonProfilePicture />
                <SkeletonText width={76} height={7} borderRadius={4} />
            </View>
            <View>
                <Pad size={16} />
                <View >
                    <SkeletonText width={'95%'} />
                    <Pad size={6} />
                    <SkeletonText width={'70%'} />
                </View>
            </View>
            <Pad size={18} />
        </View>
    );
}

const SkeletonCommentStyle = StyleSheet.create({
    horiz: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});

function SkeletonProfilePicture() {
    const s = SkeletonProfilePictureStyle;
    return <View style={s.photo}></View>;
}

const SkeletonProfilePictureStyle = StyleSheet.create({
    photo: {
        width: 24,
        height: 24,
        borderRadius: 16,
        backgroundColor: '#F6F0EC',
        marginRight: 8,
    },
});

function SkeletonText({ width, height, borderRadius }) {
    return (
        <View
            style={{
                height: height || 8,
                borderRadius: borderRadius || 2,
                width: width || '100%',
                backgroundColor: '#F6F0EC',
            }}
        />
    );
}

const CardStackStyle = StyleSheet.create({
    topCard: {
        backgroundColor: 'white',
        boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.05)',
        borderWidth: 1,
        borderColor: colorGreyBorder,
        borderRadius: 6,
        marginBottom: 16,
    },
    secondCard: {
        // backgroundColor: '#faf7f6',
        backgroundColor: 'white',
        boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.05)',
        borderWidth: 1,
        borderColor: colorGreyBorder,
        borderRadius: 6,
        opacity: 0.8,
        zIndex: -1,    
        height: 20,
        position: 'absolute',
        left: 10,
        right: 10,
        bottom: 8
    },
    thirdCard: {
        backgroundColor: 'white',
        boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.05)',
        borderWidth: 1,
        borderColor: colorGreyBorder,
        borderRadius: 6,
        opacity: 0.6,
        zIndex: -2,    
        height: 20,
        position: 'absolute',
        left: 20,
        right: 20,
        bottom: 0    
    }
});