import { useEffect, useState } from "react";
import { useDatastore } from "../../util/datastore";

export function useModerationAuthorsByIds(ids = []) {
    const datastore = useDatastore();
    const [modAuthors, setModAuthors] = useState({}); 

    async function fetchModerationAuthors(idsToFetch) {
        const result = await datastore.callServerAsync('moderationZdf', 'getModItemAuthorsByIds', {authorIds: idsToFetch});
        // Functional update to prevent inconsistent states due to asynchronous handling
        setModAuthors(prevModAuthors => ({
            ...prevModAuthors,
            ...result
        }));
    }

    useEffect(() => {
        const newIds = ids.filter(id => (modAuthors[id] == null));
            if(newIds.length > 0) {
                fetchModerationAuthors(newIds);
            }
    }, [ids]);
    return modAuthors;
}

export function useModerationAuthorById(id) {
    return useModerationAuthorsByIds([id]);
}