export const RecordEntryUrlFeature = {
    name: 'Record Entry URL',
    key: 'record_entry_url',
    dev: true,
    config: {
        commentPostCheckers: [recordEntryPointChecker],
    }
}

export function recordEntryPointChecker({datastore, comment}) {
    const {entryUrl} = datastore.getGlobalParams();
    const params = datastore.getGlobalParams();
    console.log('entryUrl', entryUrl, params);
    if (entryUrl) {
        return {allow: true, commentProps: {entryUrl}};
    } else {
        return {allow: true}
    }
}



