import { TrashCan } from "@carbon/icons-react";
import { Container, HorizBox, Pad, PadBox } from "component/basics";
import { CTAButton, TextButton } from "component/button";
import { colorRed, colorTextGrey } from "component/color";
import { Modal } from "component/modal";
import { Heading, Paragraph, UtilityText } from "component/text";
import { useState } from "react";
import { View } from "react-native";
import { useDatastore, useInstanceKey, usePersonaKey } from "util/datastore"

export const AccountDeleteFeature = {
    name: 'Account Delete',
    key: 'profiledelete',
    config: {
        accountWidgets: [AccountDeleteWidget]
    }
}

export function AccountDeleteWidget() {
    const [expanded, setExpanded] = useState(false);

    return <View>
        {expanded && <AccountDeleteModal onClose={() => setExpanded(false)} />}
        <Pad size={40} />
        <Heading type='small' label='Delete my data'/>
        <Pad size={8} />
        <Paragraph color={colorTextGrey} 
            label="If requested, your account's discussion history and engagement in any discussion is deleted as part of the request. This action cannot be undone."
        />
        <Pad size={24} />
        <CTAButton type='delete' label='Delete' 
            icon={<TrashCan style={{fill: colorRed}} />}
            onPress={() => setExpanded(true)}
        />
    </View>
}

export function AccountDeleteModal({onClose}) {
    const datastore = useDatastore();
    const [inProgress, setInProgress] = useState(false);

    async function onDelete() {
        setInProgress(true);
        await datastore.callServerAsync('profile', 'deleteAllUserData', {});
        datastore.signOut();
        datastore.goBack();
    }

    if (inProgress) {
        return <Modal onClose={onClose}>
            <PadBox horiz={20} vert={20}>
                <UtilityText color={colorTextGrey} label='Deleting profile data. Please wait...' />
            </PadBox>
        </Modal>
    }
    
    return <Modal onClose={onClose}>
        <PadBox horiz={20} vert={20}>
            <Heading type='large' weight='strong' label='Are you sure you want to delete your profile data?' />
            <Pad size={12} />
            <Paragraph color={colorTextGrey} label='Deleting your profile cannot be undone. Any data and activity associated with your profile may take up to 72 hours to be removed.' />
            <Pad size={32} />
            <HorizBox center>
                <CTAButton wide type='delete' label='Delete' 
                    icon={<TrashCan style={{fill: colorRed}} />}
                    onPress={onDelete} 
                />
            </HorizBox>
        </PadBox>
    </Modal>

}