import { CLICK, INPUT, POPUP, POPUP_CLOSE } from "../system/demo";
import { AdminUsersScreen } from "../feature/admin/AdminUsersFeature";
import { stringToFbKey } from "../util/firebase";
import { AdminScreen, AdminStructure } from "../structure/admin";
import { QuestionListScreen } from "feature/admin/QuestionList";

export const AdminDemo = {
    key: 'demo_admin',
    name: 'Admin Demo',
    config: {
        componentSections: [
            {
                label: 'Admin', key: 'internal', pages: [
                    {
                        label: 'Admin Users', key: 'adminusers', storySets: adminUsersStorySets
                    },
                    {
                        label: 'Admin Dash', key: 'admindashboard', storySets: adminDashStorySets,
                    },
                    {
                        label: 'Question List', key: 'questionlist', storySets: questionListStorySets,
                    }
                ]
            },
        ]
    }
}

function adminUsersStorySets() { return [
    {
        label: 'Lacks capability',
        content: <AdminUsersScreen />,
        serverCall: {admin: {
            getAdminUsers: () => null,
        }}
    },
    {
        label: 'Admin Users',
        content: <AdminUsersScreen />,
        roles: ['Owner'],
        collections: {fakeAdmin: [
            {key: 'admin@admin%dorg', roles: ['Owner'], email: 'admin@admin.org'},
            {key: 'bob@bob%dcom', roles: ['Moderator'], email: 'bob@bob.com'}
        ]},
        serverCall: {admin: {
            getAdminUsers: ({datastore}) => datastore.getCollection('fakeAdmin'),
            setAdminRoles: ({datastore, email, roles}) => {
                datastore.updateObject('fakeAdmin', stringToFbKey(email), {roles});
            },
            addAdminUsers: ({datastore, emails, roles}) => {
                datastore.addObject('fakeAdmin', {roles, email: 'fake@fake.com'});
            }
        }},
        stories: [
            {label: 'Set roles', actions: [
                CLICK('Moderator'),
                POPUP(CLICK('Developer')),
                POPUP_CLOSE()
            ]},
            {label: 'Add user', actions: [
                INPUT('new-emails', 'fake@fake.com'),
                CLICK('Select roles'),
                POPUP(CLICK('Editorial')),
                POPUP_CLOSE(),
                CLICK('Add Admins')
            ]}
        ]
    }
]}

function adminDashStorySets() {return [
    {
        label: 'Admin Dash',
        content: <AdminScreen />,
        roles: ['Owner'],
        config: AdminStructure.defaultConfig,
        stories: [
            {label: 'Go to Component Demo', actions: [
                CLICK('Component Demo')
            ]},
            {label: 'Go to Event Log', actions: [
                CLICK('Event Log')
            ]},
        ]
    },
    {
        label: 'Not an admin',
        content: <AdminScreen />,
    }
]}

const oldTime = new Date('2024-1-1').valueOf();
const recentTime = new Date("2026-1-1").valueOf();

function questionListStorySets() {return [
    {
        label: 'Question List',
        content: <QuestionListScreen />,
        modulePublic: {
            question: {
                a: {question: 'What is the capital of France?', key: 'a', time: recentTime},
                b: {question: 'What is the capital of Germany?', key: 'b', time: recentTime},
                c: {question: 'What is the capital of Italy?', key: 'c', time: recentTime},
                d: {question: 'This is an old question you cannot see?', key: 'd', time: oldTime},
            }
        },
        serverCall: {
            questions: {
                add: () => null,
            }
        },
        stories: [
            { 
                label: 'Add a question', actions: [
                    INPUT('add-question', 'What is the capital of Spain?'),
                    CLICK('Add Question')
                ]
            },
            {
                label: 'Search for a question', actions: [
                    INPUT('add-question', 'Germany'),
                ]
            },
            {
                label: 'Select a question', actions: [
                    CLICK('What is the capital of Germany?')
                ]
            }
        ]
    }
]}
