import { Heading } from "component/text.js"
import { StyleSheet, View } from "react-native"
import { useConfig } from "util/features.js"
import { Pad, PadBox } from "component/basics.js"
import { useInstanceKey, usePersonaKey } from "util/datastore.js"

export const ProfileSettingsFeature = {
    name: 'Profile Settings',
    key: 'profilesettings',
    config: {
        profileWidgets: [ProfileSettingsWidget]
    },
    defaultConfig: {
        settingWidgets: []
    }
}

export function ProfileSettingsWidget() {
    // Don't show settings when visiting another user's profile.
    const profileUserId = useInstanceKey();
    const personaKey = usePersonaKey();
    if (profileUserId !== personaKey) {
        return;
    }

    const config = useConfig();
    const settingWidgets = config.settingWidgets;
    const s = SettingStyle;

    return <View>
        <Pad size={20}/>
        <Heading type="large" weight="medium" label='Settings' />
        <Pad size={20}/>
        <div style={s.optionsContainer}>
            {settingWidgets.map((SettingWidget, idx) => 
                <PadBox bottom={10} key={"setting-" + idx}>
                    <SettingWidget />
                </PadBox>
            )}
        </div>
    </View>
}

const SettingStyle = StyleSheet.create({
    optionsContainer: {
        width: "fit-content"
    }
});

