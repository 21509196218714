import { CLICK } from "system/demo.js"
import { ComposerEntryPointCard, PostedCommentEntryPointCard } from "../feature/question/ContextEntryPointsFeature";
import { makeAssetUrl, urlToKey } from 'util/util.js'
import React, { useState } from "react"; 
import { ParamContext, WithScreenStack } from "util/params.js"
import { HelpIfDifferentUrl } from "../feature/question/SingleResponseHelpFeature";
import { Pad } from "component/basics.js"
import { UtilityText } from "component/text.js"
import { View } from "react-native";

const articleTitle = 'Title of article that the comment author was reading when they posted the comment';

const initialComment = {
    entryUrl: 'https://google.com',
};

const mockGlobalParams = {
    entryUrl: makeAssetUrl("https://google.com"),
};

const mockModulePublic = {
    article: {articleInfo: {
        [urlToKey('https://google.com')]: {title: articleTitle, image: makeAssetUrl("images/bubbles.png")},
    }}
}

function ComposerContextEntryPointWrapper() {
    const [comment, setComment] = useState(initialComment);
    return <ComposerEntryPointCard comment={comment} setComment={setComment} />
}

export function ContextEntryStorySets() {
    return [
        {
            label: 'Composer View',
            globalParams: mockGlobalParams,
            modulePublic: mockModulePublic,
            content: <ComposerContextEntryPointWrapper comment={initialComment} />,
            serverCall: {local: {openURL: () => {}}},
            stories: [
                {
                    label: 'Remove Article',
                    actions: [
                        CLICK('Remove Article')
                    ]
                }
            ],
        },
        {
            label: 'Posted Comment View',
            globalParams: mockGlobalParams,
            modulePublic: mockModulePublic,
            content: <PostedCommentEntryPointCard comment={initialComment} />,
            stories: []
        }, 
        {
            label: 'Single Response Help - different URL',
            globalParams: {entryUrl: "https://foo.com"},
            content: <View>
                 <HelpIfDifferentUrl comment={{entryUrl: 'https://bar.com'}}/>
                 <Pad size={80} />
            </View>,
        },
        {
            label: 'Single Response Help - same URL',
            globalParams: {entryUrl: "https://bar.com"},
            content: <View>
                 <HelpIfDifferentUrl comment={{entryPoints: [{url: 'https://bar.com'}]}}/>
                 <UtilityText text='No help bubble should be displayed' />
            </View>,
        }
    ];
}
