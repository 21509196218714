import { Narrow, Pad } from "component/basics.js"
import { Paragraph } from "component/text.js"
import { usePersonaKey } from "util/datastore.js"
import { DemoSection } from "system/demo.js"
import { PrivateThankYouMessage } from "../../feature/zdf/PrivateCommentFeedbackFeature";
import { StructureDemo } from "util/instance.js"


export function PrivateThankYouMessageDemo() {
    const currentPersonaKey = usePersonaKey();

    const demoComment = {
        key: "c1",
        from: currentPersonaKey,
        time: Date.now() - 2000000,
        text: "This is a very thoughtful comment that deserves positive feedback.",
        deservesPositiveFeedback: true,
        thankYouText: 'Here you go, you deserve this cookie: 🍪',
    };

    return (
        <Narrow>
            <Paragraph type="large" text="A private thank-you message is connected to a user comment." />
            <Pad />
            <DemoSection text="Private Thank-You Message without Author">
                <PrivateThankYouMessage comment={demoComment} />
            </DemoSection>
            <DemoSection text="Private Thank-You Message with Author">
                <PrivateThankYouMessage comment={demoComment} showAuthor={true} />
            </DemoSection>
        </Narrow>
    );
}


export function PrivateCommentFeedbackFullDemo() {
    const globals = {
        name: "How can we help refugees from Ukraine?",
        questionAuthor: "a",
        questionTime: Date.now() - 5000000,
    };

    const thought_provoking_prompts = [
        {
            key: "tpp1",
            suggestedPerspective: "Exploring successful integration programs from other countries could provide valuable insights for supporting Ukrainian refugees.",
            reminder: "Focus on sharing constructive feedback and empathetic responses to foster a more inclusive and productive dialogue.",
            // TODO: I don't know why the time I add here seems to have no effect on the demo
            time: Date.now() - 5000000,
        }
    ]

    const comments = [
        {
            key: 1,
            from: "a",
            text: "The community center in my area is offering free German classes for refugees now. I think teaching our language is the best way to help integrate refugees",
            deservesPositiveFeedback: true,
            thankYouText: "Here you go, you deserve this cookie: 🍪"
        },
        {
            key: 2,
            from: "b",
            replyTo: 1,
            text: "So you're saying I'm supposed to teach German classes now? As if that was a realistic thing to ask of people. I have a full-time job and I'm not a teacher",
        },
        {
            key: 3,
            from: "c",
            text: "I don't want any refugees in my city. We don't have the space or money to accommodate them",
        },
    ];

    const collections = { comment: comments, thought_provoking_prompt: thought_provoking_prompts }
    
    return <StructureDemo 
        testState={{globals, collections, roles: ['Moderator']}}
        structureKey='question' 
        features={{conversationhelper: true, privatecommentfeedback: true}} 
    />
}