import { useState } from "react";
import { StyleSheet } from "react-native";
import { View } from "react-native-web";
import { colorWhite } from "../color";
import { UtilityText } from "../text";

const tooltipStyle = StyleSheet.create({
    toolTip: {
        position: "absolute",
        flexDirection: "column",
        height: "fit-content",
        width: "fit-content",
        borderRadius: 8,
        padding: 12,
        backgroundColor: colorWhite,
        boxShadow: "0px 10px 24px rgba(0, 0, 0, 0.1)",
        zIndex: 1
    },
    tooltipContainer: {
        width: "fit-content"
    }
})


export function ToolTip({ toolTipWidget, label, children, disabled, offset = 0 }) {
    const [isHovering, setHover] = useState(false)
    return (<View style={tooltipStyle.tooltipContainer} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
        {isHovering && !disabled && <View style={{position: "relative"}}>
            <View style={[tooltipStyle.toolTip, {bottom: offset}]}> 
                {toolTipWidget || <UtilityText label={label} />}  
            </View>
        </View>}
        {children}
    </View>
    )
}