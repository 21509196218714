import React from "react";
import {SubtleButton} from "component/button";
import { useDatastore, useGlobalProperty} from "util/datastore";
import {useIsAdmin} from "component/admin";
import {ArrowDown, ArrowUp, Pin, PinFilled} from "@carbon/icons-react";
import {StyleSheet, View} from "react-native";
import {spacings} from "component/constants";

export const PinAnswerFeature = {
    name: 'Pin answer in Topic',
    key: 'pin_answer',
    dev: true,
    config: {
        commentAboveWidgets: [PinActionWidget],
        replyAboveWidgets: [PinActionWidget],
        replyBoosters: [replyBooster],
        commentBoosters: [commentBooster],
    }
}


const pinStyle = StyleSheet.create({
    widget: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: spacings.md,
    },
})


function PinActionWidget({comment}) {
    const s = pinStyle;
    return (
        <View style={s.widget}>
            <ActionPinAnswer comment={comment} />
            <ActionIncreasePinPriority comment={comment} />
            <ActionDecreasePinPriority comment={comment} />
        </View>
    )
}

function commentBooster({comments}) {
    const pins = useGlobalProperty('pinned_answers');
    const filteredPins = pins.filter(pin =>
        comments?.find(comment => comment.key === pin.key)
    )?.sort((a, b) => a.priority - b.priority);

    const replies = filteredPins.map(pin => comments.find(comment => comment.key === pin.key));
    console.log("commentBooster", replies);
    return replies[0] ?? undefined;
}

function replyBooster({comments}) {
    const pins = useGlobalProperty('pinned_answers');
    const filteredPins = pins?.filter(pin =>
        comments?.find(comment => comment.key === pin.key)
    )?.sort((a, b) => a.priority - b.priority);

    if(!filteredPins) return undefined;
    const replies = filteredPins.map(pin => comments.find(comment => comment.key === pin.key));
    console.log("replyBooster", replies);
    return replies[0] ?? undefined;
}


function ActionPinAnswer({comment}) {
    const pins = useGlobalProperty('pinned_answers') ?? [];
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const pinned = pins?.filter((x) => x.key === comment.key)[0];
    const otherPinnedToParent = pins?.filter(x => x.replyTo === comment.replyTo && x.key !== comment.key);
    if(!isAdmin && !pinned) {
        return null;
    }
    if(!isAdmin && pinned) {
        return (
            <SubtleButton icon={PinFilled}
                ariaLabel={label}
            />)
    }

    async function onPin() {
        if(otherPinnedToParent.length >= 2) {
            return;
        }

        const nextPriority = otherPinnedToParent.sort((a, b) => b.priority - a.priority)[0]?.priority ?? -1;
        const newPins = [...pins, {key: comment.key, pinned: true, replyTo: comment.replyTo, priority: nextPriority + 1}];
        datastore.setGlobalProperty('pinned_answers', newPins);
    }

    async function onUnPin() {
        const newPins = pins.filter(x => x.key !== comment.key);
        datastore.setGlobalProperty('pinned_answers', newPins);
    }

    const label = pinned ? 'Pinned' : 'Pin';

    return (
        <SubtleButton icon={pinned ? PinFilled : Pin}
            ariaLabel={label} onPress={pinned ? onUnPin : onPin}
        />);
}

function ActionIncreasePinPriority({comment}) {
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();

    const pins = useGlobalProperty('pinned_answers') ?? [];
    const pinned = pins?.filter((x) => x.key === comment.key)[0];

    const otherPinnedToParent = pins?.filter(x => x.replyTo === comment.replyTo && x.key !== comment.key);
    const higherRankedPin = otherPinnedToParent.filter(x => x.priority < pinned?.priority).sort((a, b) => b.priority - a.priority)[0];

    if(!isAdmin || !pinned || !higherRankedPin) {
        return null;
    }
    async function onReorder() {
        const newPins = [
            ...pins.filter(x => x.key !== comment.key && x.key !== higherRankedPin.key),
            {key: comment.key, pinned: true, replyTo: comment.replyTo, priority: pinned.priority - 1},
            {key: higherRankedPin.key, pinned: true, replyTo: comment.replyTo, priority: higherRankedPin.priority + 1}];
        datastore.setGlobalProperty('pinned_answers', newPins);
    }

    return (<SubtleButton icon={ArrowUp} ariaLabel={'Lower priority'} onPress={onReorder} />);
}

function ActionDecreasePinPriority({comment}) {
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();

    const pins = useGlobalProperty('pinned_answers') ?? [];
    const pinned = pins?.filter((x) => x.key === comment.key)[0];

    const otherPinnedToParent = pins?.filter(x => x.replyTo === comment.replyTo && x.key !== comment.key);
    const lowerRankedPin = otherPinnedToParent.filter(x => x.priority > pinned?.priority).sort((a, b) => a.priortiy - b.priority)[0];

    if(!isAdmin || !pinned || !lowerRankedPin) {
        return null;
    }
    async function onReorder() {
        const newPins = [
            ...pins.filter(x => x.key !== comment.key && x.key !== lowerRankedPin.key),
            {key: comment.key, pinned: true, replyTo: comment.replyTo, priority: pinned.priority + 1},
            {key: lowerRankedPin.key, pinned: true, replyTo: comment.replyTo, priority: lowerRankedPin.priority - 1}];
        datastore.setGlobalProperty('pinned_answers', newPins);
    }

    return (<SubtleButton icon={ArrowDown} ariaLabel={'Lower priority'} onPress={onReorder} />);

}