import { Narrow, HorizBox } from "component/basics";
import { DemoHeader } from "system/demo.js"
import { CircularBar } from "../../contrib/zdf/countdown";
import { Heading } from "component/text.js"
import { Hourglass } from "@carbon/icons-react";
import { colorGreyPopupBackground } from "component/color.js"


export function CircularBarDemo() {
    return (
        <Narrow>
            <DemoHeader text="Circular Bar" />
            <HorizBox center>
                <CircularBar />
                <Heading text="Basic progress bar" />
            </HorizBox>
            <HorizBox center>
                <CircularBar percentage={20}>
                    <Hourglass></Hourglass>
                </CircularBar>
                <Heading text="With an element inside and 20%" />
            </HorizBox>
            <HorizBox center>
                <CircularBar
                    size={100}
                    circleRadius={25}
                    strokeColor="red"
                    strokeWidth={10}
                    percentage={75}
                    strokeBackgroundColor={colorGreyPopupBackground}
                ></CircularBar>
                <Heading text="Different colors and sizes" />
            </HorizBox>
        </Narrow>
    );
}