import { callServerApiAsync } from "system/servercall.js"
import { GUIDELINES } from "../../question/CommunityGuidelinesFeature";

export const BlacklistFeature = {
    name: 'Blacklist',
    key: 'blacklist',
    config: {
        commentModerators: [checkBlacklistAsync],
    }
}

async function checkBlacklistAsync({ datastore, comment }) {
    if (!comment.text?.trim()) {
        return { allow: true, commentProps: { violates: false, inReview: false } };
    }

    const moderationResult = await callServerApiAsync({
        datastore,
        component: "moderationZdf",
        funcname: "checkBlacklist",
        params: {
            text: comment.text,
        },
    });

    if (moderationResult.violates) {
        return {
            violates: true,
            source: "blacklist",
            // TODO: Listing a specific community guideline doesn't quite fit for a blacklist violation
            violations: [GUIDELINES.NO_UNCIVIL],
        };
    } else {
        return { violates: false };
    }
}