import { View } from "react-native";
import { useDatastore } from "util/datastore.js"
import { useEffect } from "react";

export const ArticleTopicTaggingFeature = {
    name: 'Tag Article Topics',
    key: 'articletopictagging',
    config: {
        topBanners: [IndexWidget]
    }
}

// Invisible Widget which tags an article on open
function IndexWidget() {

    const datastore = useDatastore();

    useEffect(() => {

        const getTags = async () => {
            let response = await datastore.callServerAsync('interests', 'getArticleTopics');
            console.log(response)
            if(!response || response == []){
                console.log("No topics found")
                response = datastore.callServerAsync('interests', 'determineArticleTopics');
            }
        }
        getTags()
    }, [])

    return <View></View>
}