import { AccountDeleteWidget } from "feature/account/AccountDeleteFeature"
import { AccountRequestDataWidget } from "feature/account/RequestDataFeature"
import { CLICK, POPUP, POPUP_CLOSE } from "system/demo"

export const ProfileDeleteDemo = {
    key: 'demo_profile_delete',
    name: 'Profile Delete Demo',
    config: {
        componentSections: [
            {label: 'Account', key: 'account', pages: [
                {
                    designUrl: 'https://www.figma.com/design/iP7DEaY8SVqQcEGN6Feipj/Basic-Profile-%2B-Privacy-Settings?node-id=1966-5780&p=f&t=MYbBGUW6ZQjY60Ab-0',
                    label: 'Account Delete', key: 'accountdelete', storySets: profileDeleteStorySets
                }
            ]}
        ]
    }
}

function profileDeleteStorySets() {return [
    {
        label: 'Profile Delete',
        instanceKey: 'a',
        personaKey: 'a',
        serverCall: {profile: {
                deleteAllUserData: () => {}
            },
            local: {
                signOut: () => {},
            }
        },
        content: <AccountDeleteWidget />,
        stories: [
            {label: 'Show Modal', actions: [
                CLICK('Delete')
            ]},
            {label: 'Delete Profile', actions: [
                CLICK('Delete'),
                POPUP(CLICK('Delete'))
            ]},
            {label: 'Cancel', actions: [
                CLICK('Delete'),
                POPUP(CLICK('close-modal')),
            ]},
        ]
    },
    {
        label: 'Profile Request Data',
        content: <AccountRequestDataWidget />,
    }
]}