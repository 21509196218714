import { PadBox } from "component/basics.js"
import { Tag } from "component/button.js"
import { colorBlueBackground } from "component/color.js"
import { StructureDemo } from "util/instance.js"

export const TopicConfigDemoFeature = {
    name: 'Topic Config Demo',
    key: 'demo_topic_config',
    config: {
        structureSections: [
            {label: 'Config Slots', key: 'slots', pages: [
                {label: 'Topic Config', key: 'topicconfig', screen: TopicConfigDemoScreen},
            ]}
        ]
    }    
}

function TopicConfigDemoScreen() {
    const globals = {
        name: 'Godzilla'
    }
    return <StructureDemo testState={{globals}} structureKey='topic' 
        features={{
            topic_config: true, topicrelatedcontent: false,
            topiclivestatus: false,  topicpoll: false, nexttopicpoll: false,
            topicfollow: false, topicparticipants: false
        }}
        // features={{topic_config: true, topicrelatedcontent: false}} 
    />
}

export const TopicConfigFeature = {
    name: 'Show Topic Config Slots',
    key: 'topic_config',
    dev: true,
    config: {
        topicDescriptionWidget: () => <DemoWidget text='Topic Description Widget' />,
        topicContentWidgets: [() => <DemoWidget text='Topic Content Widget' />],
        topicTopWidgets: [() => <DemoWidget text='Topic Top Widget' />],
        topicBottomWidgets: [() => <DemoWidget text='Topic Bottom Widget' />],
        topicHeaderLeftWidgets: [() => <MiniWidget text='Topic Header Left Widget' />],
        topicHeaderRightWidgets: [() => <MiniWidget text='Topic Header Right Widget' />],
        topicHeaderBottomWidgets: [() => <MiniWidget text='Topic Header Bottom Widget' />],
    },   
}

export function DemoWidget({color=colorBlueBackground, text}) {
    return <PadBox bottom={10}><Tag color={color} text={text} /></PadBox>
}

export function MiniWidget({text, color=colorBlueBackground}) {
    return <Tag compact color={color} text={text} />
}

