import { ReactionTypesWidget } from "../feature/question/ReactionTypesFeature";
import { StructureDemo } from "util/instance.js";
import { CLICK } from "system/demo.js";


const config = {
    reactionTypes: [
        {label: 'Thank you', emoji: '🙏'}, 
        {label: 'New to me', emoji: '💡'}, 
        {label: 'Disagree', emoji: '🤔'},
    ]
}

const collections = {
    reaction: [
        {reactTo: 1, reactionType: 'New to me', from: 'b'},
        {reactTo: 1, reactionType: 'Thank you', from: 'a'},
    ]
}

export function reactionTypesStorySets() {return [
    {
        label: 'Reaction Types Widget', 
        config, collections,
        content: <ReactionTypesWidget comment={{key: 1}} />,
        stories: [
            {label: 'Remove Reaction', actions: [
                CLICK('Thank you')
            ]},
            {label: 'Add Reactions', actions: [
                CLICK('New to me'),
                CLICK('Disagree')
            ]}

        ]
    }
]}

const comments = [
    {key: 1, from: 'a', text: 'Godzilla sucks'},
    {key: 2, from: 'b', text: 'Godilla rules'},
    {key: 3, from: 'c', text: 'Godzilla does not exist'},
]

const reactions = [
    {from: 'a', reactTo: 2, reactionType: 'Thank you'},
    {from: 'c', reactTo: 2, reactionType: 'Thank you'},
    {from: 'c', reactTo: 2, reactionType: 'New to me'},
    {from: 'b', reactTo: 1, reactionType: 'Disagree'},
]

const globals = {
    name: 'Is Godzilla Good?',
    questionAuthor: 'a',
    questionTime: Date.now(),
}

export function ReactionTypesFullDemo() {
    const collections = {comment: comments, reaction: reactions}
    return <StructureDemo testState={{globals, collections}} structureKey='question' features={{reactiontypes: true}} />
}