import { FakeTopicParticipantsWidget } from "../feature/topic/FakeParticipantCount"
import { HeaderBox } from "component/basics.js"
import { colorTealBackground } from "component/color.js"
import { QuestionCards } from "../feature/topic/QuestionCardsFeature"
import { CLICK } from "system/demo.js"
import { ReadingListWidget } from "../feature/topic/ReadingListFeature"

export const TopicDemoFeature = {
    key: 'demo_topic',
    name: 'Demo - Topic',
    config: {
        componentSections: [
            {label: 'Topic', key: 'topic', pages: [
                {
                    label: 'Topic Basics',
                    key: 'topicbasics',
                    storySets: topicBasicsStorySets
                }
            ]}
        ],
   }
}

function topicBasicsStorySets() {return [
    {
        label: 'Fake Participant Count',
        content: <HeaderBox backgroundColor={colorTealBackground}><FakeTopicParticipantsWidget /></HeaderBox>
    },
    {
        label: 'Question Cards',
        collections: {
            relatedConversations: [{key: 'a', name: 'My Question', deleted: false}]
        },
        embeddedInstanceData: {
            question: {
                a: {
                    globals: {name: 'My Question'},
                    collections: {comment: [
                        {key: 1, from: 'a', text: 'This is a comment in an embedded instance'},
                    ]}
                }
            }
        },
        content: <QuestionCards />,
        stories: [
            {label: 'Show More', actions: [
                CLICK('Show more')
            ]}
        ]
    },
    {
        label: 'Reading List',
        collections: {backlink_article: [
            {key: 'a', title: 'My Article', url: 'https://www.google.com', image: 'https://via.placeholder.com/150', description: 'This is a description of my article', date: '2021-01-01'},
            {key: 'b', title: 'An article that has an annoyingly long title', url: 'https://www.google.com', image: 'https://via.placeholder.com/150',  date: '2024-01-01'}

        ]},
        content: <ReadingListWidget />
    }
]}

