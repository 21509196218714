export const STATIC_TOPIC = {
    name: 'How artificial intelligence impacts your life?',
    status: 'live', // infer from start/end at?
    startAt: '2024-06-01',
    endAt: '2024-06-30',
    conversationStarter: 'Submit your question about AI to our panel of experts',
    poll: {
        question: 'How do you feel about the increasing use of AI in everyday life?',
        resultline: 'Your response',
        tagline: 'Select one option',
        type: 'MultipleChoice',
        showDataviz: true,
        options: [ {label: 'Very positive'},{label: 'Neutral'},{label: 'Somewhat negative'}, {label: 'Very negative'}],
    },
    relatedConversations: [
        {
            id: 'Vzi0jr8KJPwg',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
        },
        {
            id: 'JwirywJwGAMW',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
        },
        {
            id: '8hTlMegzCe21',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
        }
    ],
    relatedTopics: [
        {
            id: 'Vzi0jr8KJPwg',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
            live: true,
            startAt: '2024-06-01',
            endAt: '2024-06-30',
        },
        {
            id: 'JwirywJwGAMW',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
            live: false,
            startAt: '2024-06-01',
            endAt: '2024-06-30',
        },
        {
            id: '8hTlMegzCe21',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
            live: false,
            startAt: '2024-06-01',
            endAt: '2024-06-30',
        }
    ],
    testContents: [
        {
            id: '3ldrP0ctarks-test',
            text: "Prédire les complications du cancer grâce à l'IA",
            picture: 'https://placehold.co/250x150',
            type: 'media',
        },
    ],
    nextTopicPoll: {
        question: 'Which topic would you like us to cover next?',
        tagline: 'Vote on one topic',
        resultLine: 'Your vote',
        type: 'MultipleChoiceOther',
        showDataviz: false,
        otherOptionValue: 'Submit your topic',
        otherOptionPlaceholder: 'Write in a topic...',
        options: [{ label:  'War in Ukraine' },
                { label: 'Violence in Toronto' },
                { label: 'Immigration protest' },
                { label: 'First nation leadership'},
            ],
    },
}
