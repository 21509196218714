import { HorizBox } from "component/basics.js"
import { Toggle } from "component/form.js"
import { UtilityText } from "component/text.js"
import { useDatastore, useModulePublicData, usePersonaKey } from "util/datastore.js"
import { logEventAsync } from "util/eventlog.js"

export const ProfileVideoVotingSettingFeature = {
    name: 'Video Voting Setting',
    key: 'profilevideovotingsetting',
    parentFeature: 'profilesettings',
    config: {
        settingWidgets: [VideoVotingSetting]
    }
}

// TODO I am not sure if the setting makes sense as a feature. If you dont enable it by default, you would have to enable it for each user manually
export function VideoVotingSetting() {
    const personaKey = usePersonaKey();
    const datastore = useDatastore();

    const votingDisabledGloballyFromDatabase = useModulePublicData("videovoting", ["disabledForUser", personaKey]);

    const toggleVideoVoting = async () => {
        const newVotingDisabledGlobally = !votingDisabledGloballyFromDatabase;
        
        logEventAsync(datastore, 'videovoting-change-global-setting', {
            votingDisabledGlobally: newVotingDisabledGlobally.toString()
        });

        await datastore.callServerAsync('videovoting', 'toggleVideoVotingGlobally', {
            personaKey: personaKey,
            isDisabled: newVotingDisabledGlobally
        });
    }

    return <Toggle value={!votingDisabledGloballyFromDatabase} label='Enable voting for all videos' onChange={toggleVideoVoting}/>
}