import { PadBox } from "component/basics.js"
import { ReactionButton } from "component/button.js"
import { UtilityText } from "component/text.js"
import { needsLogin } from "structure/login.js"
import { useCollection, useDatastore, usePersonaKey } from "util/datastore.js"
import { logEventAsync } from "util/eventlog.js"
import { REPLACE, useConfig, useIsReadOnly } from "util/features.js"
import { StyleSheet, View } from "react-native"

export const ReactionTypesFeature = {
    name: 'Reaction Types',
    key: 'reactiontypes',
    config: {
        commentBelowWidgets: [ReactionTypesWidget]
    },
    defaultConfig: {
        reactionTypes: [
            {label: 'Relatable', emoji: '❤️'}, 
            {label: 'Helpful', emoji: '➕'}, 
            {label: 'Controversial', emoji: '💬'}, 
            {label: 'Interesting', emoji: '💡'},
        ]
    }
}

export const NarwwhalReactionsFeature = {
    name: 'Narwhal Reactions',
    key: 'narwhalreactions',
    dev: true,
    parentFeature: 'reactiontypes',
    config: {
        reactionTypes: REPLACE([
            {label: 'Best', emoji: '🏆', single: true}, 
            {label: 'Provocative', emoji: '🔥'}, 
            {label: 'New to me', emoji: '💡'}, 
            {label: 'Clarifying', emoji: '🧠'}, 
        ])
    }
}

export const NewPublicReactionsFeature = {
    name: 'New_ Public Reactions',
    key: 'newpublicreactions',
    dev: true,
    parentFeature: 'reactiontypes',
    config: {}
}

function countReactions(reactions) {
    const counts = {};
    reactions.forEach(reaction => {
        const {reactionType} = reaction;
        counts[reactionType] = counts[reactionType] || 0;
        counts[reactionType]++;
    })
    return counts;
}

export function ReactionTypesWidget({comment}) {
    const s = ReactionTypesWidgetsStyle;
    const {reactionTypes} = useConfig();
    const personaKey = usePersonaKey();
    const datastore = useDatastore();
    const reactions = useCollection('reaction', {filter: {reactTo: comment.key}});
    const myReactions = useCollection('reaction', {filter: {reactTo: comment.key, from: personaKey}});
    const readOnly = useIsReadOnly();

    const reactionCounts = countReactions(reactions);
    const myReactionCounts = countReactions(myReactions);
    const viewOnly = comment.from == personaKey || readOnly;

    function onReact({reaction}) {
        if (myReactionCounts[reaction.label]) {
            datastore.deleteObject('reaction', myReactions.find(r => r.reactionType == reaction.label).key);
            logEventAsync(datastore, 'reaction-undo', {text: reaction.label});
        } else {
            if (reaction.single) {
                const existing = datastore.getCollection('reaction', {filter: {from: personaKey, reactionType: reaction.label}});    
                existing.forEach(r => datastore.deleteObject('reaction', r.key));
            }
            datastore.addObject('reaction', {reactTo: comment.key, from: personaKey, reactionType: reaction.label});
            logEventAsync(datastore, 'reaction', {text: reaction.label});
        }
    }

    const shownReactionTypes = reactionTypes.filter(reaction => 
        !viewOnly || reactionCounts[reaction.label] > 0
    );

    return <View style={s.outer}>
        {shownReactionTypes.map((reaction, i) => 
           <PadBox key={i} right={viewOnly ? 24 : 16} top={20}>
                <ReactionButton onPress={datastore.needsLogin(() => onReact({reaction}), 'react')} 
                    emoji={reaction.emoji}
                    label={reaction.label} 
                    selected={myReactionCounts[reaction.label] > 0}
                    count={reactionCounts[reaction.label] || 0}
                    viewOnly={viewOnly}
                />
            </PadBox>
        )}
    </View>
}
const ReactionTypesWidgetsStyle = StyleSheet.create({
    outer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start'
    }
})



