    import { Pad, PadBox, HoverView } from "component/basics.js"
    import { colorPink, colorPurpleBackground, colorPinkBackground, colorBlack } from "component/color.js"
    import { StyleSheet, View } from "react-native";
    import { UtilityText, LinkText, Paragraph } from "component/text.js"
    import { FlashFilled, Information } from '@carbon/icons-react';
    import React, { useState } from 'react';
    import LinearGradient from 'react-native-web-linear-gradient';
    import { Modal } from "component/modal";
    import { useModulePublicData } from "util/datastore";
    import { Text } from 'react-native';

    export const AITagFeature = {
        name: 'AI Tag',
        key: 'aitag',
        dev: true,
        config: {
            pageTopRightWidgets: [() => <ClikableAITag />]
        }
    }

    export function ClikableAITag() {
        const [showModal, setShowModal] = useState(false);
        const onPress = () => {
            setShowModal(!showModal);
        };

        return <View>
            <AITag isInModal={false} onPress={onPress} />
            {showModal && <AITagModal onClose={() => setShowModal(false)} />}
        </View>
    }

    export function AITagModal({onClose}) {
        const charterUrl = useModulePublicData('charterUrl') || null;
        return <Modal onClose={onClose}>
            <PadBox horiz={20} vert={20}>
                <Pad size={20} />
                <AITag isInModal={true}/>
                <Pad size={12} />
                <Text style={{flexWrap: 'wrap'}}>
                    <Paragraph label="To maintain a welcoming and respectful environment, this space uses AI to moderate comments. AI may flag your post for your additional review if it does not follow the community guidelines. " />
                    {charterUrl && <LinkText label="Learn more." url={charterUrl} testID="learn-more"/>}
                </Text>
            </PadBox>  
        </Modal>
    }

    export function AITag({isInModal=false, onPress}) {
        const s = AITagStyle;
        return <LinearGradient colors={[colorPurpleBackground, colorPinkBackground, colorPink]} 
                locations={[0, 0.5, 1]} start={{x: 0, y: 0}} end={{x: 1, y: 0}}
                style={s.container}>
            <FlashFilled size={12}/>
            <PadBox right={6}/>
            <UtilityText label={"AI-moderated"} weight='medium' type='tiny' />
            <PadBox right={6}/>
            {isInModal ? <Information /> : <View style={s.iconWrapper}>
                <HoverView onPress={onPress} testID="ai-moderated-tag-info" style={s.iconTapTarget}>
                    <Information />
                </HoverView>
            </View>}
        </LinearGradient>
    }

    const AITagStyle = StyleSheet.create({
        container: {
            height: 28,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 32,
            paddingHorizontal: 6,
            alignSelf: 'flex-start',
        },
        iconTapTarget: {
            width: 44, // expands tap target beyond the iconWrapper
            height: 44,
            justifyContent: 'center',
            alignItems: 'center',
        },
        iconWrapper: {
            position: 'relative',
            width: 16,
            height: 16,
            justifyContent: 'center',
            alignItems: 'center',
        },
        infoBoxWrapper: {
            position: 'absolute',
            top: 28,
            right: 0,
        },
        infoBox: {
            backgroundColor: colorBlack,
            borderRadius: 8,
            width: 300,
        },
    });
