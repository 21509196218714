import { StyleSheet, View } from "react-native";
import { PollWidget } from "../../feature/cbcrc/poll/pollwidget";

import { useCollection, useDatastore } from "util/datastore.js";
import { CTAButton } from "component/button.js";
import { STATIC_TOPIC } from '../../structure/cbcrc/topic/static';
import { HeaderBox, Pad } from "component/basics.js";
import { EditorialHeading, UtilityText } from "component/text.js";
import { useState } from "react";
import { spacings } from "component/constants";
import { PollResult, PollSurvey, PollSurveyThenResults, PollTextResult } from "component/poll";
import { CLICK } from "system/demo";


const COLLECTION_TOPIC_DB_KEY = "topics";

export function PollScreen() {
    const [myVote, setMyVote] = useState(null);
    const options = ['Cat', 'Dog', 'Komodo Dragon'];
    const votes = ['Dog', 'Dog', 'Dog'];

    function onVote(choice, comment = null) {
        let message =  'You voted for ' + choice;
        if(comment) {
            message += ' with comment ' + comment;
        }
        alert(message);
    }
    function onPollVote(choice, comment = null) {
        setMyVote({ label: choice, ...(comment && { comment }) });
    }
    function onChangeVote() {
        setMyVote(null);
    }

    return <View>
        <EditorialHeading level={1} strong text='PollSurvey' />
        <PollSurvey question='What is your favorite animal?' optionTexts={options} onVote={onVote} />
        <Pad />
        <EditorialHeading level={1} strong text='PollSurvey with write in' />
        <PollSurvey 
            question='What is your favorite animal?' optionTexts={options} 
            onVote={onVote} showOtherOption 
            otherOptionText={'Other'} otherOptionPlaceholder="Placeholder value" />
        <EditorialHeading level={1} strong text='PollResult' />
        <PollResult optionTexts={options} votes={['Cat', 'Dog', 'Dog']} myVote='Cat' visualizationType='percent' />
        <PollResult optionTexts={options} votes={['Dog', 'Dog', 'Dog']} myVote='Dog' visualizationType='percent' />
        <EditorialHeading level={1} strong text='PollTextResult' />
        <PollTextResult myVote={'Dog'} comment={'Badger'}/>

        <Pad />
        <EditorialHeading level={1} strong text='PollSurveyThenResults' />
        <PollSurveyThenResults
            question='What is your favorite animal?' optionTexts={options}
            onVote={onPollVote} onChangeVote={onChangeVote}
            votes={[...votes, myVote?.label]} myVote={myVote?.label} isSubmitted={myVote} tagline='Vote now!' resultline='Results' visualizationType='percent' />
        
        <EditorialHeading level={1} strong text='PollSurveyThenResults TextResult' />
        <PollSurveyThenResults 
            question='What is your favorite animal?' optionTexts={options} 
            showOtherOption
            onVote={onPollVote} 
            onChangeVote={onChangeVote}
            otherOptionText={'Other'}
            otherOptionPlaceholder={'Other...'}
            votes={[...votes, myVote?.label]} myVote={myVote?.label} comment={myVote?.comment} isSubmitted={myVote} tagline='Vote now!' resultline='Results' visualizationType='percent' />
    </View>
}

export function pollWidgetStories() {return [
    {
        label: 'PollWidget',
        content: <PollWidget pollKey='topicPoll' 
                question={STATIC_TOPIC.poll.question} 
                optionTexts={STATIC_TOPIC.poll.options.map(x => x.label)} />,
        stories: [
            {
                label: 'Neutral', actions: [
                    CLICK('Neutral'),
                    CLICK('Submit my vote')
                ]
            },
            {
                label: 'Neutral then Somewhat negative', actions: [
                    CLICK('Neutral'),
                    CLICK('Submit my vote'),
                    CLICK('Change my vote'),
                    CLICK('Somewhat negative'),
                    CLICK('Submit my vote')
                ]
            }
        ]
        
    }
]}

