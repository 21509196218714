import { useIsAdmin } from "component/admin.js"
import { RecirculationSlider } from "../../../component/cbcrc/recirculationCards";
import { Card, ConversationScreen, HorizBox, Pad, PadBox, Separator } from "component/basics.js"
import { CTAButton } from "component/button.js"
import { Heading, UtilityText } from "component/text.js"
import { useCollection, useDatastore, useObject } from "util/datastore.js"
import { useGlobalQuestions } from "../../../component/question";
import { useState } from "react";
import { Toggle } from "component/form.js"
import { Catcher } from "system/catcher.js"
import { spacings } from "component/constants";

export const TopicRelatedConversationsFeature = {
    key: 'topicrelatedconversations',
    name: 'Related Conversations',
    subscreens: {
        relatedConversationsAdmin: () => <RelatedConversationsAdmin />,
    },
    config: {
        topicContentWidgets: [RelatedConversations],
    }
}

export function RelatedConversations() {
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();
    const relatedConversations = useCollection('relatedConversations', { filter: { deleted: false } });
    return (
        <PadBox vert={spacings.xl2} horiz={spacings.lg}>
            {isAdmin && (
                <>
                    <CTAButton type='primary' label='Edit related conversations' onPress={datastore.needsLogin(() => datastore.pushSubscreen('relatedConversationsAdmin', {}))} />
                    <Pad size={spacings.xl} />
                </>
            )}
            {!!relatedConversations?.length && (
                <>
                    <Heading type="large" weight="medium" label='Discuss this topic with other citizens' />
                    <Pad size={spacings.xl} />
                    <RecirculationSlider cardsData={relatedConversations} cardType='conversation' controls />
                </>
            )}
        </PadBox>
    )
};

function Question({ question }) {
    const datastore = useDatastore();
    const selectedQuestion = useObject('relatedConversations', question.key);
    const isChosen = selectedQuestion && !selectedQuestion.deleted;

    async function onChange(selected) {
        await datastore.callServerAsync('topics', selected ? 'selectRelatedQuestion' : 'unselectRelatedQuestion', {questionKey: question.key });
    }

    return <PadBox bottom={20} horiz={20}>
        <Card>
            <HorizBox spread>
                <UtilityText type='small' paragraph text={question.text ?? question.question} weight="medium" />
                <Pad />
                <Toggle lable='Selected' value={isChosen} onChange={onChange} />
            </HorizBox>
        </Card>
    </PadBox>
}


export function RelatedConversationsAdmin() {
    const questions = useGlobalQuestions();
    const [search, setSearch] = useState('');
    const filteredQuestions = questions?.filter(question => !search || question.question?.toLowerCase().includes(search.toLowerCase()));

    return (
        <ConversationScreen>
            <Pad />
            <PadBox horiz={20}><Heading label='Choose related conversations for this Topic' /></PadBox>
            <Pad />
            <Separator />
            <Pad />
            {/* TODO need a new "Question component?" */}
            {filteredQuestions?.map(question =>
                <Catcher key={question.key}><Question question={question} /></Catcher>
            )}
        </ConversationScreen>
    )
}
