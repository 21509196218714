import { useGlobalProperty } from "util/datastore.js"
import { PollWidget } from "../poll/pollwidget";
import { UtilityText } from "component/text.js"
import { PadBox } from "component/basics.js"
import { spacings } from "component/constants";

export const TopicPollFeature = {
    key: 'topicpoll',
    name: 'Topic Poll',
    defaultConfig: {   
        topicTopWidgets: [],
        topicBottomWidgets: [] 
    }
}

export const TopicPollTopPositionFeature = {
    key: 'topictoppoll',
    name: 'Top Position',
    config: {
        topicTopWidgets: [TopicPoll],      
    }
}

export const TopicPollBottomPositionFeature = {
    key: 'topicbottompoll',
    name: 'Bottom Position',
    config: {
        topicBottomWidgets: [TopicPoll]        
    }
}


export const TopicPollInContentPositionFeature = {
    key: 'topicincontentpoll',
    name: 'In content position',
    config: {
        topicContentWidgets: [TopicPoll],      
    }
}

function TopicPoll() {
    const poll = useGlobalProperty('poll');
    if (!poll) return <UtilityText label='No poll defined' />;

    const optionTexts = poll.options.map(x => x.label);

    return <PadBox vert={spacings.xl2} horiz={spacings.lg}>
        <PollWidget 
            pollKey='topicPoll' 
            question={poll.question}
            optionTexts={optionTexts}
        />
    </PadBox>
}

export const NextTopicPollFeature = {
    key: 'nexttopicpoll',
    name: 'Next Topic Poll',
    config: {
        topicBottomWidgets: [NextTopicPoll]       
    }
}

function NextTopicPoll() {
    const nextTopicPoll = useGlobalProperty('nextTopicPoll');
    if (!nextTopicPoll) return <UtilityText label='No upcoming topic poll defined' />;

    const optionTexts = nextTopicPoll.options.map(x => x.label);

    return <PadBox top={spacings.xl2} horiz={spacings.lg}>
        <PollWidget
            pollKey={'upcomingTopicPoll'}
            question={nextTopicPoll.question}
            optionTexts={optionTexts}
            showOtherOption
            otherOptionText={nextTopicPoll.otherOptionValue}
            otherOptionPlaceholder={nextTopicPoll.otherOptionPlaceholder}
        />
    </PadBox>
}
