import { View } from "react-native-web";
import { useIsAdmin } from "component/admin";
import { Banner } from "component/banner"
import { Pad } from "component/basics";
import { colorPink } from "component/color";
import { Heading, UtilityText } from "component/text";
import { useConfig } from "util/features";

export const CloseConversationFeature = {
    key: 'readonly',
    name: 'Pause Conversation',
    config: {
        composerPreview: ClosedConversationBanner,
        readOnly: true
    },
    defaultConfig: {
        closedTitle: '🚧 Temporarily Unavailable',
        closedMessage: 'This discussion is temporarily unavailable. Check back for updates.',
    }
}

export function ClosedConversationBanner() {
    const {closedTitle, closedMessage} = useConfig();
    const isAdmin = useIsAdmin();
    return <View>
        <Banner color={colorPink}>
            <Heading label={closedTitle} />
            <Pad size={16} />
            <UtilityText label={closedMessage} />            
        </Banner>
        {isAdmin && <Pad size={16} />}
        {isAdmin && <UtilityText type='tiny' weight='medium' label='If you re-open this conversation, you will need to reload it (Admin)' />}
    </View>
}
