import { PadBox } from "component/basics.js"
import { Tag } from "component/button.js"
import { colorBlueBackground, colorGreyPopupBackground, colorPink, colorTextGrey } from "component/color.js"


export const HideRejectedFeature = {
    key: 'hide_rejected',
    name: 'Hide Rejected',
    dev: true,
    config: {
        commentFilters: [filterFunc],
        replyFilters: [filterFunc],
        commentTopWidgets: [InReviewTag],
        composerTopWidgets: [InReviewTag],
        commentBodyStylers: [bodyStyler]
    }
}

function bodyStyler({comment}) {
    if (comment.violates || comment.inReview) {
        return {color: colorTextGrey, backgroundColor: colorGreyPopupBackground, padding: 12, borderRadius: 8};
    } else {
        return null;
    }
}

function filterFunc({datastore, isAdmin, comment}) {
    if (
            (comment.inReview || comment.violates) && 
            !isAdmin &&
            !(comment.replyTo && comment.from == datastore.getPersonaKey())) {
        return false;
    } else {
        return true;
    }
}

function InReviewTag({comment}) {
    if (comment.inReview) {
        return <PadBox bottom={20}>
            <Tag type='tiny' overrideTinyTextColor color={colorBlueBackground} label='🔍 Under Review' />
        </PadBox>
    } else if (comment.violates && comment.humanJudgement) {
        return <PadBox bottom={20}>
            <Tag type='tiny' overrideTinyTextColor color={colorPink} label='🚫 Rejected by Human Moderator' />
        </PadBox>
    } else {
        return null;
    }
}
