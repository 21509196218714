
import { UtilityText } from "../../../component/text";
import { JudgementCard } from "../../../component/zdf/moddashboard/judgementcard";
import { CLICK, DemoSection } from "../../../system/demo";
import { Datastore } from "../../../util/datastore";
import { defaultPersonaList, personaListToMap } from "../../../util/testpersonas";
import { modDashboardDemoData } from "./moddashboard.data";

export function JudgementCardDemo() {

    const modTask_awaiting = modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai;
    const modTask_approved = modDashboardDemoData.modTaskQueues.thread1.c_t1_approved_auto;
    const modTask_rejected = modDashboardDemoData.modTaskQueues.thread1.c_t1_rejected;

    const myWatchState = {...modDashboardDemoData.watchStates.c_t1_approved_auto, amIViewing:true};
    const otherWatchState = modDashboardDemoData.watchStates.c_t1_approved_auto;

    return (
        <Datastore
            personaPreview={personaListToMap(defaultPersonaList)}
            testState={{
                modulePublic: modDashboardDemoData.modulePublic,
                serverCall: modDashboardDemoData.serverCalls,
            }}
        >
            <DemoSection text="Judgement Card - Approved comment">
                <JudgementCard
                    modTask={modTask_approved}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_approved.key]}
                    isSelected={true}
                />
            </DemoSection>
            <DemoSection text="Judgement Card - Rejected comment">
                <JudgementCard
                    modTask={modTask_rejected}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_rejected.key]}
                    isSelected={true}
                />
            </DemoSection>
            <DemoSection text="Judgment Card - Awaiting decision">
                <JudgementCard
                    modTask={modTask_awaiting}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_awaiting.key]}
                    isSelected={true}
                />
            </DemoSection>
            <DemoSection text="View of different reviewing moderators">
                <UtilityText weight="strong" text="Comment card is selected"/>
                <JudgementCard
                    modTask={modTask_approved}
                    taskWatchState={myWatchState}
                    isSelected={true}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_approved.key]}
                />
                <JudgementCard
                    modTask={modTask_approved}
                    taskWatchState={otherWatchState}
                    isSelected={true}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_approved.key]}
                />
                <UtilityText weight="strong" text="Comment card is not selected"/>
                <JudgementCard
                    modTask={modTask_approved}
                    taskWatchState={myWatchState}
                    isSelected={false}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_approved.key]}
                />
                <JudgementCard
                    modTask={modTask_approved}
                    taskWatchState={otherWatchState}
                    isSelected={false}
                    modAuthor={modDashboardDemoData.demoModAuthors[modTask_approved.key]}
                />
            </DemoSection>
        </Datastore>
    );
}

function JudgementFlowDemo() {
    const modTask_awaiting = modDashboardDemoData.modTaskQueues.thread1.c_t1_awaiting_ai;
    return <JudgementCard modTask={modTask_awaiting} modAuthor={modDashboardDemoData.demoModAuthors[modTask_awaiting.key]} isSelected={true} />
}

export function ModDashboardJudgementCardStorySets() {
    return [
        {
            label: "Judgement Card Flow",
            serverCall: modDashboardDemoData.serverCalls,
            content: <JudgementFlowDemo/>,
            stories: [
                {
                    label: "Approve",
                    actions: [
                        CLICK("Approve")
                    ],
                },
                {
                    label: "Reject",
                    actions: [
                        CLICK("Reject"),
                        CLICK("Other"),
                        CLICK("No violent content or calls for harm"),
                        CLICK("Reject"),
                        CLICK("No thanks")
                    ],
                },
                {
                    label: "Reject and warn",
                    actions: [
                        CLICK("Reject"),
                        CLICK("Other"),
                        CLICK("No violent content or calls for harm"),
                        CLICK("Reject"),
                        CLICK("Warn user")
                    ],
                },
                {
                    label: "Reject and go back",
                    actions: [
                        CLICK("Reject"),
                        CLICK("Other"),
                        CLICK("No violent content or calls for harm"),
                        CLICK("Reject"),
                        CLICK("Back to selection"),
                        CLICK("Back to selection"),
                    ],
                }
            ],
        }
    ];
}