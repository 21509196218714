import { Narrow } from "component/basics.js"
import { View } from "react-native"
import { DemoSection } from "system/demo.js"
import { StructureDemo } from "util/instance.js"
import { ConversationHelperAdminScreen, ThoughtProvokingPrompt, ThoughtProvokingPromptHistory, ThoughtProvokingPromptHistoryItem, ThoughtProvokingPromptSelector } from "../../../feature/zdf/ConversationHelperFeature";
import { Datastore } from "util/datastore.js"
import { conversationHelperDemoData }  from "./conversationhelperdemo.data";
import { AutoModeratorReactionTypesWidget } from "../../../component/zdf/conversationhelper/autoModeratorReactions";

export function ThoughtProvokingPromptDemo() {

    const collections = {
        thought_provoking_prompt: conversationHelperDemoData.thoughtProvokingPrompts,
        thought_provoking_prompt_reactions: conversationHelperDemoData.reactions,
        summary: conversationHelperDemoData.summaries
    };
    const config={
        reactionTypes: conversationHelperDemoData.reactionTypes,
    }

    return (
        <Narrow>
            <View style={{ gap: 12 }}>

                <DemoSection text="Thought-Provoking Prompt">
                    <Datastore testState={{collections}} config>
                        <ThoughtProvokingPrompt currentThoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[2]} />
                    </Datastore>
                </DemoSection>

                <DemoSection text="Thought-Provoking Prompt with Reactions">
                    <Datastore testState={{collections}} config={{...config, reactionsEnabled: true }}>
                        <ThoughtProvokingPrompt currentThoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[2]} />
                    </Datastore>
                </DemoSection>

                <DemoSection text="Thought-Provoking Prompt with History">
                    <Datastore testState={{collections}} config={{...config, historyEnabled: true }}>
                        <ThoughtProvokingPrompt currentThoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[2]} />
                    </Datastore>
                </DemoSection>

                <DemoSection text="Thought-Provoking Prompt with Summary">
                    <Datastore testState={{collections}} config={{...config, summaryEnabled: true }}>
                        <ThoughtProvokingPrompt currentThoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[2]} />
                    </Datastore>
                </DemoSection>

                <Datastore testState={{collections}} config={{...config, summaryEnabled: true }}>
                    <DemoSection text="Thought-Provoking Prompt History">
                        <ThoughtProvokingPromptHistory/>
                    </DemoSection>
                    <DemoSection text="Thought-Provoking Prompt History Item">
                        <ThoughtProvokingPromptHistoryItem thoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[0]} />
                    </DemoSection>
                </Datastore>

                <Datastore testState={{collections}} config={{...config, summaryEnabled: true, reactionsEnabled: true }}>
                    <DemoSection text="Thought-Provoking Prompt History Item with Reactions">
                        <ThoughtProvokingPromptHistoryItem thoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[0]} />
                    </DemoSection>
                </Datastore>
                
                <Datastore testState={{collections}} config={{...config, summaryEnabled: true }}>
                    <DemoSection text="Thought-Provoking Prompt Reaction Widget - Newest Prompt">
                        <AutoModeratorReactionTypesWidget thoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[2]} />
                    </DemoSection>
                    <DemoSection text="Thought-Provoking Prompt Reaction Widget - Previous Prompt">
                        <AutoModeratorReactionTypesWidget thoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[0]} />
                    </DemoSection>
                </Datastore>
            </View>
        </Narrow>
    );
}

export function ThoughtProvokingPromptAdminDemo () {
    return <View>
        <Datastore
            testState={{
                globals: conversationHelperDemoData.globals,
                roles: ['Moderator'],
                collections: {
                    thought_provoking_prompt: conversationHelperDemoData.thoughtProvokingPrompts,
                    thought_provoking_prompt_reactions: conversationHelperDemoData.reactions,
                    comment: conversationHelperDemoData.comments
                },
                serverCall: conversationHelperDemoData.serverCalls      
            }}
            config={{
                reactionTypes: conversationHelperDemoData.reactionTypes,
            }}
        >
            <ConversationHelperAdminScreen></ConversationHelperAdminScreen>

        </Datastore>
    </View>
}


export function ConversationHelperFullDemo() {
    const collections = { comment: conversationHelperDemoData.comments, thought_provoking_prompt: conversationHelperDemoData.thoughtProvokingPrompts }
    return <StructureDemo testState={{
            roles: ['Moderator'],
            collections, 
            globals: conversationHelperDemoData.globals,
            serverCall: conversationHelperDemoData.serverCalls
        }}
        structureKey='question' 
        features={{ conversationhelper: true }} 
    />
}

export function ConversationHelperAdminFullDemo() {
    const collections = { comment: conversationHelperDemoData.comments, thought_provoking_prompt: conversationHelperDemoData.thoughtProvokingPrompts }
    return <StructureDemo 
        screenKey={"convohelperadmin"} 
        testState={{globals: conversationHelperDemoData.globals, collections}}
        structureKey='question' features={{ conversationhelper: true }} />
}
