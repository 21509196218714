import { defaultPersonaList } from "../../../util/testpersonas";

const demoTimestamp = 1700000000000; // Consistent dates for demos

const demoAuthors = {
    // Is the moderator persona
    a: { 
        ...defaultPersonaList[0], 
        memberSince: new Date(1000000000000),
        authorKey: defaultPersonaList[0].key,
        authorPhoto: defaultPersonaList[0].photoUrl,
        authorName: defaultPersonaList[0].name,
        authorMemberSince: new Date(1000500000000),
    },
    // Used for awaiting decision variants
    b: { 
        ...defaultPersonaList[1], 
        memberSince: new Date(1000500000000),
        authorKey: defaultPersonaList[1].key,
        authorPhoto: defaultPersonaList[1].photoUrl,
        authorName: defaultPersonaList[1].name,
        authorMemberSince: new Date(1000500000000),
    },
    // Rejected comments, warned once
    c: { 
        ...defaultPersonaList[2], 
        memberSince: new Date(1001000000000),
        authorKey: defaultPersonaList[2].key,
        authorPhoto: defaultPersonaList[2].photoUrl,
        authorName: defaultPersonaList[2].name,
        authorMemberSince: new Date(1000500000000),
    },
    // Rejected comments, warned and blocked
    d: { 
        ...defaultPersonaList[3], 
        memberSince: new Date(1001500000000),
        authorKey: defaultPersonaList[3].key,
        authorPhoto: defaultPersonaList[3].photoUrl,
        authorName: defaultPersonaList[3].name,
        authorMemberSince: new Date(1000500000000),
    },
    // Only approved comments
    e: { 
        ...defaultPersonaList[4],
        memberSince: new Date(1002000000000),
        authorKey: defaultPersonaList[4].key,
        authorPhoto: defaultPersonaList[4].photoUrl,
        authorName: defaultPersonaList[4].name,
        authorMemberSince: new Date(1002000000000),
    }
}

const suspensionInfo = {
    [demoAuthors.c.key] : {
        warnings: {
            c_t1_rejected_first_warning : {},
            c_t1_rejected_second_warning : {}
        }
    },
    [demoAuthors.d.key] : {
        blockedByKey: demoAuthors.a.key,
        blockedByName: demoAuthors.a.name,
        isBlocked: true,
        warnings: {
            c_t1_rejected_blocked_first_warning: {},
            c_t1_rejected_blocked_second_warning: {}
        }
    }
}

const demoUserProfileData = {
    a: {
        preview: demoAuthors.a,
        commentCount: 0,
        flagsGivenCount: 10,
        flagsReceivedCount: 0,
        rejectedCommentCount: 0,
        isBlocked: false
    },
    b: {
        preview: demoAuthors.b,
        commentCount: 3,
        flagsGivenCount: 0,
        flagsReceivedCount: 3,
        rejectedCommentCount: 0,
        isBlocked: true
    },
    c: {
        preview: demoAuthors.c,
        commentCount: 2,
        flagsGivenCount: 0,
        flagsReceivedCount: 2,
        rejectedCommentCount: 2,
        suspension: suspensionInfo[demoAuthors.c.key]
    },
    d: {
        preview: demoAuthors.d,
        commentCount: 2,
        flagsGivenCount: 0,
        flagsReceivedCount: 2,
        rejectedCommentCount: 2,
        suspension: suspensionInfo[demoAuthors.d.key]
    },
    e: {
        preview: demoAuthors.d,
        commentCount: 2,
        flagsGivenCount: 0,
        flagsReceivedCount: 0,
        rejectedCommentCount: 0,
        suspension: suspensionInfo[demoAuthors.d.key]
    } 
}

const demoModAuthors = {
    c_t1_awaiting_ai: {
        ...demoAuthors.b
    },
    c_t1_awaiting_moderator_note: {
        ...demoAuthors.b
    },
    c_t1_awaiting_report: {
        ...demoAuthors.b
    },
    c_t1_awaiting_report_multiple: {
        ...demoAuthors.b
    },
    c_t1_approved_auto: {
        ...demoAuthors.e
    },
    c_t1_approved_human: {
        ...demoAuthors.e
    },
    c_t1_rejected: {
        ...demoAuthors.c
    },
    c_t1_rejected_first_warning: {
        ...demoAuthors.c
    },
    c_t1_rejected_second_warning: {
        ...demoAuthors.c
    },
    c_t1_rejected_blocked_first_warning: {
        ...demoAuthors.d
    },
    c_t1_rejected_blocked_second_warning: {
        ...demoAuthors.d
    },
    // Thread 2
    c_t2_awaiting_ai: {
        ...demoAuthors.b
    },
    c_t2_approved_auto: {
        ...demoAuthors.e
    },
    c_t2_approved_human: {
        ...demoAuthors.e
    },
    c_t2_rejected: {
        ...demoAuthors.c
    },
}

const modulePublicQuestion = {
    thread1: {
        key: "thread1",
        from: demoAuthors.a.key,
        question: "Is godzilla a serious problem?",
        time: demoTimestamp,
        commentCount: 100,
        countAwaitingDecision: 1,
        heated: true,
    },
    thread2: {
        key: "thread2",
        from: demoAuthors.b.key,
        question: "What's the best way to survive a zombie apocalypse?",
        time: demoTimestamp - 100000000,
        commentCount: 42
    },
    thread3: {
        key: "thread3",
        from: demoAuthors.c.key,
        question: "If aliens invade, who's negotiating?",
        time: demoTimestamp - 200000000,
        commentCount: 58,
        readonly: true,
    },
    thread4: {
        key: "thread4",
        from: demoAuthors.d.key,
        question: "Is pineapple on pizza actually good?",
        time: demoTimestamp - 2000000000,
        commentCount: 230
    }
}

const commentsThread1 = {
    // Awaiting decision
    c_t1_awaiting_ai: {
        key: "c_t1_awaiting_ai",
        from: demoAuthors.b.key,
        time: demoTimestamp - 10000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was flagged by AI and is awaiting a moderator's decision.",
        violates: true,
    },
    c_t1_awaiting_report: {
        key: "c_t1_awaiting_report",
        from: demoAuthors.b.key,
        time: demoTimestamp - 9000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was reported by another user.",
        violates: true,
    },
    c_t1_awaiting_moderator_note: {
        key: "c_t1_awaiting_moderator_note",
        from: demoAuthors.b.key,
        time: demoTimestamp - 9000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment has moderator notes.",
        violates: true,
    },
    c_t1_awaiting_report_multiple: {
        key: "c_t1_awaiting_report_multiple",
        from: demoAuthors.b.key,
        time: demoTimestamp - 9000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was reported by multiple users.",
        violates: true,
    },
    // Approved
    c_t1_approved_human: {
        key: "c_t1_approved_human",
        from: demoAuthors.b.key,
        time: demoTimestamp - 20000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was approved by a human moderator.",
    },
    c_t1_approved_auto: {
        key: "c_t1_approved_auto",
        from: demoAuthors.c.key,
        time: demoTimestamp - 30000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was automatically approved.",
    },
    // Rejected
    c_t1_rejected: {
        key: "c_t1_rejected",
        from: demoAuthors.d.key,
        time: demoTimestamp - 40000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was rejected by a human moderator.",
        violates: true,
    },
    // Rejected for blocked persona
    c_t1_rejected_blocked_first_warning: {
        key: "c_t1_rejected_blocked_first_warning",
        from: demoAuthors.d.key,
        time: demoTimestamp - 40000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was rejected by a human moderator and the user was warned for the first time.",
        violates: true,
    },
    c_t1_rejected_blocked_second_warning: {
        key: "c_t1_rejected_blocked_second_warning",
        from: demoAuthors.d.key,
        time: demoTimestamp - 40000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was rejected by a human moderator and the user was warned for the second time",
        violates: true,
    },
    // Rejected and warned twice for blockable persona
    c_t1_rejected_first_warning: {
        key: "c_t1_rejected_first_warning",
        from: demoAuthors.c.key,
        time: demoTimestamp - 40000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was rejected by a human moderator and the user was warned for the first time.",
        violates: true,
    },
    c_t1_rejected_second_warning: {
        key: "c_t1_rejected_first_warning",
        from: demoAuthors.c.key,
        time: demoTimestamp - 40000000,
        instanceKey: modulePublicQuestion.thread1.key,
        text: "This comment was rejected by a human moderator and the user was warned for the second time",
        violates: true,
    },
};

const commentsThread2 = {
    // Awaiting decision
    c_t2_awaiting_ai: {
        key: "c_t2_awaiting_ai",
        from: demoAuthors.b.key,
        time: demoTimestamp - 10000000,
        instanceKey: modulePublicQuestion.thread2.key,
        text: "These lazy kids form today would not last a minute in the apocalypse.",
        violates: true,
    },
    c_t2_approved_human: {
        key: "c_t2_approved_human",
        from: demoAuthors.b.key,
        time: demoTimestamp - 20000000,
        instanceKey: modulePublicQuestion.thread2.key,
        text: "Everyone needs an emergency survival kit",
    },
    c_t2_rejected: {
        key: "c_t2_rejected",
        from: demoAuthors.d.key,
        time: demoTimestamp - 40000000,
        instanceKey: modulePublicQuestion.thread2.key,
        text: "This would be very fun.",
        violates: true,
    },
    c_t2_approved_auto: {
        key: "c_t2_approved_auto",
        from: demoAuthors.c.key,
        time: demoTimestamp - 30000000,
        instanceKey: modulePublicQuestion.thread2.key,
        text: "I hope we will never experience an apocalypse.",
    },
}

const backlinksThread1 = {
    article0: {
        title: "Godzilla on a rampage",
        key: "article0",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg"
    },
    article1: {
        title: "King Kong attacks Godzilla",
        key: "article1",
        image: "https://new-public-demo.web.app/photos/godzilla_new_york.jpeg"
    }
}

const modTasksThread1 = {
    //Awaitng decision
    c_t1_awaiting_ai: {
        ...commentsThread1.c_t1_awaiting_ai,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 10000000,
        objectTime: demoTimestamp - 10000000,
        appealText: "I think my comment is harmless.",
        violations: "[\"No violent content or calls for harm\"]",
        source: "premoderation",
    },
    c_t1_awaiting_report: {
        ...commentsThread1.c_t1_awaiting_report,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 5000000,
        objectTime: demoTimestamp - 9000000,
        violations: "[\"No violent content or calls for harm\"]",
        source: "report",
    },
    c_t1_awaiting_moderator_note: {
        ...commentsThread1.c_t1_awaiting_moderator_note,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 5000000,
        objectTime: demoTimestamp - 9000000,
        violations: "[\"No violent content or calls for harm\"]",
        source: "report",
    },
    c_t1_awaiting_report_multiple: {
        ...commentsThread1.c_t1_awaiting_report_multiple,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 5000000,
        objectTime: demoTimestamp - 9000000,
        violations: "[\"No violent content or calls for harm\"]",
        source: "report",
    },
    // Approved
    c_t1_approved_human: {
        ...commentsThread1.c_t1_approved_human,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 20000000,
        objectTime: demoTimestamp - 20000000,
        violations: "[\"No violent content or calls for harm\"]",
        source: "premoderation",
        judgement: "approve",
        humanJudgement: true,
        judgeKey: demoAuthors.a.key,
        judgementTime: demoTimestamp - 10000000,
    },
    c_t1_approved_auto: {
        ...commentsThread1.c_t1_approved_auto,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 30000000,
        objectTime: demoTimestamp - 30000000,
        judgement: "approve",
        humanJudgement: false,
    },
    c_t1_rejected: {
        ...commentsThread1.c_t1_rejected,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 40000000,
        objectTime: demoTimestamp - 40000000,
        appealText: "This is just my opinion!",
        judgement: "reject",
        humanJudgement: true,
        judgeKey: demoAuthors.a.key,
        judgementTime: demoTimestamp - 10000000,
        violations: "[\"No violent content or calls for harm\"]",
        reasoning: "[\"No spam or commercial content\"]",
        source: "premoderation",
    },
    c_t1_rejected_blocked_first_warning: {
        ...commentsThread1.c_t1_rejected_blocked_first_warning,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 40000000,
        objectTime: demoTimestamp - 40000000,
        appealText: "Are you going to warn me?",
        judgement: "reject",
        humanJudgement: true,
        judgeKey: demoAuthors.a.key,
        judgementTime: demoTimestamp - 10000000,
        violations: "[\"No violent content or calls for harm\"]",
        reasoning: "[\"No spam or commercial content\"]",
        source: "premoderation",
    },
    c_t1_rejected_blocked_second_warning: {
        ...commentsThread1.c_t1_rejected_blocked_second_warning,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 40000000,
        objectTime: demoTimestamp - 40000000,
        appealText: "Do you want to warn me again?",
        judgement: "reject",
        humanJudgement: true,
        judgeKey: demoAuthors.a.key,
        judgementTime: demoTimestamp - 10000000,
        violations: "[\"No violent content or calls for harm\"]",
        reasoning: "[\"No spam or commercial content\"]",
        source: "premoderation",
    },
    c_t1_rejected_first_warning: {
        ...commentsThread1.c_t1_rejected_first_warning,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 40000000,
        objectTime: demoTimestamp - 40000000,
        appealText: "Are you going to warn me?",
        judgement: "reject",
        humanJudgement: true,
        judgeKey: demoAuthors.a.key,
        judgementTime: demoTimestamp - 10000000,
        violations: "[\"No violent content or calls for harm\"]",
        reasoning: "[\"No spam or commercial content\"]",
        source: "premoderation",
    },
    c_t1_rejected_second_warning: {
        ...commentsThread1.c_t1_rejected_second_warning,
        type: "comment",
        instanceKey: modulePublicQuestion.thread1.key,
        time: demoTimestamp - 40000000,
        objectTime: demoTimestamp - 40000000,
        appealText: "Do you want to warn me again?",
        judgement: "reject",
        humanJudgement: true,
        judgeKey: demoAuthors.a.key,
        judgementTime: demoTimestamp - 10000000,
        violations: "[\"No violent content or calls for harm\"]",
        reasoning: "[\"No spam or commercial content\"]",
        source: "premoderation",
    }
};

const modTasksThread2 = {
    //Awaitng decision
    c_t2_awaiting_ai: {
        ...commentsThread2.c_t2_awaiting_ai,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread2.key,
        time: demoTimestamp - 10000000,
        objectTime: demoTimestamp - 10000000,
        appealText: "I think my comment is harmless.",
        violations: "[\"No violent content or calls for harm\"]",
        source: "premoderation",
    },
    c_t2_approved_human: {
        ...commentsThread2.c_t2_approved_human,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread2.key,
        time: demoTimestamp - 20000000,
        objectTime: demoTimestamp - 20000000,
        violations: "[\"No violent content or calls for harm\"]",
        source: "premoderation",
        judgement: "approve",
        humanJudgement: true,
        judgeKey: demoAuthors.a.key,
        judgementTime: demoTimestamp - 10000000,
    },
    c_t2_approved_auto: {
        ...commentsThread2.c_t2_approved_auto,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread2.key,
        time: demoTimestamp - 30000000,
        objectTime: demoTimestamp - 30000000,
        judgement: "approve",
        humanJudgement: false,
    },
    c_t2_rejected: {
        ...commentsThread2.c_t2_rejected,
        from: null,
        type: "comment",
        instanceKey: modulePublicQuestion.thread2.key,
        time: demoTimestamp - 40000000,
        objectTime: demoTimestamp - 40000000,
        appealText: "This is just my opinion!",
        judgement: "reject",
        humanJudgement: true,
        judgeKey: demoAuthors.a.key,
        judgementTime: demoTimestamp - 10000000,
        violations: "[\"No violent content or calls for harm\"]",
        reasoning: "[\"No spam or commercial content\"]",
        source: "premoderation",
    },
}

const modTasksThread1SubQueues = {
    awaiting_decision: {
        c_t1_awaiting_ai: { 
            key: commentsThread1.c_t1_awaiting_ai.key, 
            time: commentsThread1.c_t1_awaiting_ai.time,      
            type: "comment"
        },
        c_t1_awaiting_report: { 
            key: commentsThread1.c_t1_awaiting_report.key, 
            time: commentsThread1.c_t1_awaiting_report.time,      
            type: "comment"
        }
    },
    human_approved: {
        c_t1_approved_human: { 
            key: commentsThread1.c_t1_approved_human.key, 
            time: commentsThread1.c_t1_approved_human.time,
            type: "comment"
        }
    },
    auto_approved: {
        c_t1_approved_auto: { 
            key: commentsThread1.c_t1_approved_auto.key, 
            time: commentsThread1.c_t1_approved_auto.time,
            type: "comment"
        }
    },
    rejected: {
        c_t1_rejected: { 
            key: commentsThread1.c_t1_rejected.key, 
            time: commentsThread1.c_t1_rejected.time,
            type: "comment"
        }
    }
};

const modTasksThread2SubQueues = {
    awaiting_decision: {
        c_t2_awaiting_ai: { 
            key: commentsThread2.c_t2_awaiting_ai.key, 
            time: commentsThread2.c_t2_awaiting_ai.time,      
            type: "comment"
        }
    },
    human_approved: {
        c_t2_approved_human: { 
            key: commentsThread2.c_t2_approved_human.key, 
            time: commentsThread2.c_t2_approved_human.time,
            type: "comment"
        }
    },
    auto_approved: {
        c_t2_approved_auto: { 
            key: commentsThread2.c_t2_approved_auto.key, 
            time: commentsThread2.c_t2_approved_auto.time,
            type: "comment"
        }
    },
    rejected: {
        c_t2_rejected: { 
            key: commentsThread2.c_t2_rejected.key, 
            time: commentsThread2.c_t2_rejected.time,
            type: "comment"
        }
    }
};

const globalQueueCounts = {
    awaiting_decision: Object.keys(modTasksThread1SubQueues.awaiting_decision).length + Object.keys(modTasksThread2SubQueues.awaiting_decision).length,
    human_approved: Object.keys(modTasksThread1SubQueues.human_approved).length + Object.keys(modTasksThread2SubQueues.human_approved).length,
    auto_approved: Object.keys(modTasksThread1SubQueues.auto_approved).length + Object.keys(modTasksThread2SubQueues.auto_approved).length,
    rejected: Object.keys(modTasksThread1SubQueues.rejected).length + Object.keys(modTasksThread2SubQueues.rejected).length
}

const modulePrivateModeratorNotes = {
    c_t1_awaiting_moderator_note: {
        c_t1_awaiting_moderator_note_n0: {
            from: demoAuthors.c.key,
            key: "c_t1_awaiting_moderator_note_n0",
            modTaskKey: modTasksThread1.c_t1_awaiting_moderator_note.key,
            time: demoTimestamp - 1000,
            text: "This could be spam"
        },
        c_t1_awaiting_moderator_note: {
            from: demoAuthors.d.key,
            key: "c_t1_awaiting_moderator_note_n1",
            modTaskKey: modTasksThread1.c_t1_awaiting_moderator_note.key,
            time: demoTimestamp - 500,
            text: "Yeah maybe, seems likely"
        }
    }
}

const modulePublicModeratorNotes = {
    moderatorNotes: {
        c_t1_rejected: {
            amount: 2
        }
    }
}

const defaultReports = {
    [modTasksThread1.c_t1_awaiting_report.key]: {
        report_c_t1_awaiting_report_1: {
            key: "report_c_t1_awaiting_report_1",
            author: demoModAuthors[modTasksThread1.c_t1_awaiting_report.key],
            reporter: demoAuthors.e,
            time: demoTimestamp,
            violations: ["Violation 1"]
        }
    },
    [modTasksThread1.c_t1_awaiting_report_multiple.key]: {
        report_c_t1_awaiting_report_multiple_1: {
            key: "report_c_t1_awaiting_report_multiple_1",
            author: demoModAuthors[modTasksThread1.c_t1_awaiting_report_multiple.key],
            reporter: demoAuthors.e,
            time: demoTimestamp - 1000000,
            violations: ["Violation 1"]
        },
        report_c_t1_awaiting_report_multiple_2: {
            key: "report_c_t1_awaiting_report_multiple_2",
            author: demoModAuthors[modTasksThread1.c_t1_awaiting_report_multiple.key],
            reporter: demoAuthors.d,
            time: demoTimestamp,
            violations: ["Violation 2"]
        }
    }
}

function mockGetModeratorNotes ({modTaskKey}) {
    return modulePrivateModeratorNotes[modTaskKey]
}

function mockSetModeratorNote ({datastore, modTaskKey, text, from}) {
    return {}
}

const defaultWatchStates = {
    c_t1_approved_auto: {
        modID: demoAuthors.e,
        sessionID: "123456789",
        time: Date.now(),
        ...demoAuthors.e
    }
}

const taskWatchStates = {
    c_t1_awaiting_ai: {
        modID: demoAuthors.a,
        startTime: Date.now()
    },
    c_t1_awaiting_report: {
        modID: demoAuthors.b,
        startTime: Date.now(),
        name: demoAuthors.b.name
    },
}

const modulePublicTaskWatchState = {
    taskWatchState: taskWatchStates
}

const instanceData = {
    thread1: {
        globals: {
            name: modulePublicQuestion.thread1.question
        },
        collections: {
            "comment": Object.values(commentsThread1),
            "queue_filtered_awaiting_decision": Object.values(modTasksThread1SubQueues.awaiting_decision),
            "queue_filtered_human_approved": Object.values(modTasksThread1SubQueues.human_approved),
            "queue_filtered_auto_approved": Object.values(modTasksThread1SubQueues.auto_approved),
            "queue_filtered_rejected": Object.values(modTasksThread1SubQueues.rejected),
            "persona": Object.values(demoAuthors),
            "backlink_article": Object.values(backlinksThread1)
        }
    }
};

function mockGetModItemAuthors() {
    return demoModAuthors;
}

function mockGetRemoteObjectFromDatabase({ collectionType, objectKey, instanceKey }) {
    return instanceData[instanceKey].collections[collectionType].find((c) => c.key === objectKey);
}

function mockGetUserProfileData({key}){
    return demoUserProfileData[key]
}

function mockGetIsUserBlocked({key}) {
    return suspensionInfo[key]?.isBlocked;
}

function mockSetIsUserBlocked({key}) {
    return suspensionInfo[key]?.isBlocked
}

function mockGetReportsForModTask({key}) {
    return defaultReports[key]
}

function mockGetUserWarningCount ({key}) {
    return Object.keys(suspensionInfo[key]?.warnings ?? {}).length
}

function mockWarnUser () {
    return null
}

function mockSetJudgement () {
    return null
}

function mockWatchTask () {
    return null
}

function mockUnwatchTask () {
    return null
}

function mockCalculateHeated () {
    return null
}

function mockGetUserSuspensionInfo ({key}) {
    return suspensionInfo[key]
}

function mockGetUserQueue ({key}) {
    return Object.keys(demoModAuthors).filter((modAuthorKey)=>{
        return demoModAuthors[modAuthorKey].authorKey === key
    }).map(modAuthorKey => {
        return {...modTasksThread1, ...modTasksThread2}[modAuthorKey]
    })
}


const threadQueueCounts = {
    [modulePublicQuestion.thread1.key]: { 
        awaiting_decision_count: Object.values(modTasksThread1SubQueues.awaiting_decision).length 
    }
}

export const modDashboardDemoData = {
    watchStates: defaultWatchStates,
    modulePublicQuestion,
    threadQueueCounts,
    commentsThread1,
    demoAuthors,
    demoModAuthors,
    modulePrivateModeratorNotes,
    modulePublicModeratorNotes,
    modulePublicTaskWatchState,
    instanceData: instanceData,
    globalQueueCounts: globalQueueCounts,
    modTaskQueues: {
        thread1: modTasksThread1,
        thread2: modTasksThread2
    },
    modTaskSubQueues: {
        thread1: modTasksThread1SubQueues,
        thread2: modTasksThread2SubQueues
    },
    serverCalls: {
        moderation: {
            getModItemAuthors: mockGetModItemAuthors,
            setJudgement: mockSetJudgement
        },
        moderationZdf: {
            getModItemAuthorsByIds: mockGetModItemAuthors,
            getRemoteObjectFromDatabase: mockGetRemoteObjectFromDatabase,
            getUserProfileData: mockGetUserProfileData,
            getIsUserBlocked: mockGetIsUserBlocked,
            setIsUserBlocked: mockSetIsUserBlocked,
            getModeratorNotes: mockGetModeratorNotes,
            setModeratorNote: mockSetModeratorNote,
            getReportsForModTask: mockGetReportsForModTask,
            getUserWarningCount: mockGetUserWarningCount,
            warnUser: mockWarnUser,
            calculateHeated: mockCalculateHeated,
            watchTask: mockWatchTask,
            unwatchTask: mockUnwatchTask,
            getUserSuspensionInfo: mockGetUserSuspensionInfo,
            getUserQueue: mockGetUserQueue
        }
    }
}