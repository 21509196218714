import { allTranslations } from "../translations";
import { useDatastore, useMemoizedModulePublicData, useModulePublicData, useSiloKey } from "../util/datastore";
import { Text } from "react-native";
import { formatString } from "../util/util";
import { useFirebaseData } from "../util/firebase";

export const languageEnglish = 'english';
export const languageGerman = 'german';
export const languageFrenchCanadian = 'french_canadian';
export const languageFrenchBelgian = 'french_belgian';
export const languageFrench = 'french';


export function translateLabel({label, language, formatParams={}}) {
    var extra = {};
    if (formatParams?.singular && formatParams?.plural) {
        if (formatParams.count == 1) {
            extra = {
                noun: translateLabel({label: formatParams.singular, language}), 
                isAre: translateLabel({label: 'is', language})
            }
        } else {
            extra = {
                noun: translateLabel({label: formatParams.plural, language}), 
                isAre: translateLabel({label: 'are', language})
            }
        }
    }
    
    const translations = allTranslations[language];
    if (language && language != languageEnglish && !translations) {
        console.error('No translations for ' + language);
    }

    var translatedText = translations ? translations[label] : label;

    if (!translatedText && language != languageEnglish && language) {
        console.error('No translation for ' + label + ' in ' + language);
    }
    if (formatParams) {
        translatedText = formatString(translatedText || label, {...formatParams, ...extra});
    }

    return translatedText || label;
}

export function useLanguage() {
    const datastore = useDatastore();
    return datastore.getLanguage();
}

export function useTranslation(label, formatParams) {
    const language = useLanguage();
    if (label == null) return null;

    // Make it always do these test translations, once that is error-free.
    if (process.env.NODE_ENV === "test" && process.env.TRANSLATION_LOG_PATH) {
        const germanTranslation = translateLabel({ label, language: "german", formatParams });
        const frenchCanadianTranslation = translateLabel({ label, language: "french_canadian", formatParams });
        const frenchBelgianTranslation = translateLabel({ label, language: "french_belgian", formatParams });

        // See psi-product/offline/translation/check-translations.js for more info
        if (process.env.TRANSLATION_LOG_PATH) {
            const logEntry = {label, germanTranslation, frenchCanadianTranslation, frenchBelgianTranslation, testContext: global.testContext || {}};
            const fs = require("fs");
            fs.appendFileSync(process.env.TRANSLATION_LOG_PATH, JSON.stringify(logEntry) + "\n", "utf-8");
        }
    }

    return translateLabel({ label, language, formatParams });
}

export function TranslatableText({numberOfLines=null, ellipsizeMode, text, label, formatParams, style, ...props}) {
    try {
        const translatedLabel = useTranslation(label, formatParams);
        return <Text numberOfLines={numberOfLines} ellipsizeMode={ellipsizeMode} style={style} {...props}>{translatedLabel || label || text}</Text>
    } catch (e) {
        console.error('Error translating ' + label, e);
        return <Text numberOfLines={numberOfLines} ellipsizeMode={ellipsizeMode} style={style} {...props}>{label || text}</Text>
    }
}

