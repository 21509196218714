import { CLICK } from "system/demo.js"
import { ClikableAITag } from "../feature/question/AITagFeature";
import React from "react"; 

export function aiTagStorySets() {
    return [
        {
            label: 'Composer View',
            modulePublic: {'charterUrl': 'https://foo.com'},
            content: <ClikableAITag />,
            stories: [
                {
                    label: 'Click on tag and learn more',
                    actions: [
                        CLICK('ai-moderated-tag-info'),
                        CLICK('learn-more')
                    ]
                }
            ],
        },
    ];
}
